import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiCalendar } from "react-icons/fi";
import "./DateInput.css";
import moment from "moment";

const DateInput = ({ value, onChange, placeholder }) => {
    return (
        <div className="date-input-container">
            <DatePicker
                selected={value ? new Date(value) : null}
                onChange={(date) => onChange(date)}
                placeholderText={placeholder}
                dateFormat="yyyy-MM-dd"
                customInput={
                    <div className="custom-date-input">
                        <input
                            type="text"
                            placeholder={placeholder}
                            value={
                                value ? moment(value).format("D MMM YYYY") : ""
                            }
                            readOnly
                        />
                        <FiCalendar className="calendar-icon" size={20} />
                    </div>
                }
            />
        </div>
    );
};

export default DateInput;

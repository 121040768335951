import React, { Component } from "react";
import "./alert.css";
import { closeAlert } from "../../helpers/dispatcher";

class Alert extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.autoClose) {
            setTimeout(() => {
                this.props.onClick();
                closeAlert();
            }, this.props.autoClose * 1000);
        }
    }
    render() {
        let icon = "";
        let color = "";
        switch (this.props.type) {
            case "success":
                icon = "far fa-check-circle";
                color = "#4CD3B2";
                break;
            case "danger":
                icon = "far fa-times-circle";
                color = "red";
                break;
            case "warning":
                icon = "fas fa-exclamation-triangle";
                color = "#ffc02b";
                break;
            default:
                color = "grey";
                break;
        }

        return (
            <div className="alert-layer">
                <div
                    className="alert-container fadeIn"
                    style={{ backgroundColor: color }}
                >
                    <i className={"icon " + icon}></i>
                    <div>
                        <div>{this.props.title}</div>
                        <div className="description">
                            {this.props.description
                                ? this.props.description
                                : null}
                        </div>
                    </div>
                    <i
                        className="far fa-times-circle close-icon"
                        style={{ color: color }}
                        onClick={() => {
                            this.props.onClick();
                            closeAlert();
                        }}
                    ></i>
                </div>
            </div>
        );
    }
}

export default Alert;

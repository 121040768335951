import { Component } from "react";
import Validator from "validatorjs";
import {
    BsArea,
    BsInput,
    SelectComponent,
} from "../../../components/Form/FormComponents";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
import "./volunteerSetup.css";
import { toast } from "react-toastify";
import LocationInput from "../../../components/Generic/LocationInput";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
const MaterialService = require("../../../services/MaterialService");
class VolunteerSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            name: "",
            email: "",
            mobile: "",
            availability: "",
            locality: {},
            shortBio: "",
            selectStatus: "",
            errors: {},
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchData();
        } else {
            this.setState({ loader: true });
        }
    }

    fetchData = async () => {
        try {
            const res = await MaterialService.fetchData(
                `/v2/manager/volunteer/detail/${this.props.match.params.id}`
            );

            if (res.status) {
                const partnerData =
                    res.data?.miscInfo?.partnerData || res.data?.miscInfo || {};

                this.setState({
                    name: partnerData?.name || "",
                    email: partnerData?.email || "",
                    mobile: partnerData?.mobile_no || "",
                    availability: partnerData?.availability || "",
                    locality: partnerData?.locality?.formatted || "",
                    shortBio: partnerData?.description || "",
                    selectStatus: res.data?.status?.toString() || "",
                    loader: true,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    onInputChange = (event) => {
        const { name, value, type, files } = event.target;
        this.setState({ [name]: type === "file" ? files[0] : value });
    };

    validateForm = () => {
        const rules = {
            name: "required",
            email: "required|email",
            mobile: "required|numeric|digits:10",
            availability: "required",
            locality: "required",
            shortBio: "required",
            selectStatus: "required",
        };
        const messages = {
            "required.name": "Enter Name",
            "required.email": "Enter email",
            "email.email": "Enter correct email",
            "required.mobile": "Enter mobile number",
            "numeric.mobile": "Enter only number",
            "digits.mobile": "Enter valid mobile number",
            "required.availability": "Enter availability",
            "required.locality": "Enter locality",
            "required.shortBio": "Enter short bio",
            "required.selectStatus": "Select status",
        };

        const validator = new Validator(this.state, rules, messages);

        if (validator.fails()) {
            this.setState({
                errors: validator.errors.all(),
            });
            return false;
        }

        this.setState({ errors: {} });
        return true;
    };

    save = async () => {
        if (!this.validateForm()) return;

        const submitData = {
            category: 1,
            mobile_no: this.state.mobile,
            name: this.state.name,
            email: this.state.email,
            locality: this.state.locality,
            availability: this.state.availability,
            description: this.state.shortBio,
            status: this.state.selectStatus,
        };

        try {
            const res = await MaterialService.postData(
                "/v2/get-involved/show-interest",
                submitData
            );

            if (res.status) {
                toast.success("Successfully updated", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                history.push("/material-manager/volunteer-lists");
            } else {
                toast.error(res.message || "Something went wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
    };

    update = async () => {
        if (!this.validateForm()) return;

        const submitData = {
            category: 1,
            mobile_no: this.state.mobile,
            name: this.state.name,
            email: this.state.email,
            locality: this.state.locality,
            availability: this.state.availability,
            description: this.state.shortBio,
            status: this.state.selectStatus,
            id: this.props.match.params.id,
        };

        try {
            const res = await MaterialService.postData(
                `/v2/manager/volunteer/update/${this.props.match.params.id}`,
                submitData
            );

            if (res.status) {
                toast.success("Successfully updated", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                history.push("/material-manager/volunteer-lists");
            } else {
                toast.error(res.message || "Something went wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
        } catch (error) {
            console.error("Error updating data:", error);
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
    };

    render() {
        if (!this.state.loader) {
            return <LoaderSpinner />;
        }

        return (
            <div className="main-container" id="dropOffSetup">
                <HeadingCompnonet mainTitle="Volunteering Details" />
                <div className="main-volunteer-list-container">
                    <div className="mian-drop-off-heading">
                        {this.props.match.params.id
                            ? "Edit Volunteer"
                            : "Setup Volunteer"}
                    </div>
                    <div className="col-md-12 row">
                        <div className="col-md-6">
                            <BsInput
                                required
                                label="Name"
                                placeholder="Enter name"
                                name="name"
                                onChange={this.onInputChange}
                                value={this.state.name}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <BsInput
                                required
                                label="Email"
                                placeholder="Enter email"
                                name="email"
                                onChange={this.onInputChange}
                                value={this.state.email}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <BsInput
                                required
                                label="Mobile"
                                placeholder="Enter mobile number"
                                name="mobile"
                                onChange={this.onInputChange}
                                value={this.state.mobile}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6 availablity">
                            <BsInput
                                required
                                label="Availability"
                                placeholder="Enter availability time"
                                name="availability"
                                onChange={this.onInputChange}
                                value={this.state.availability}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <LocationInput
                                defaultValue={this.state.locality || {}}
                                type={["geocode"]}
                                onLocationSelected={(locality) => {
                                    this.setState({ locality });
                                }}
                                name="locality"
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-12 no-padding margin-top">
                            <BsArea
                                required
                                label="Short Bio"
                                label2="Maximum 150 Characters"
                                value={this.state.shortBio}
                                name="shortBio"
                                onChange={this.onInputChange}
                                errors={this.state.errors}
                                rows="4"
                                maxLength={150}
                            />
                        </div>
                        <div className="col-md-5">
                            <SelectComponent
                                required
                                name="selectStatus"
                                label="Select Status"
                                errors={this.state.errors}
                                onChange={this.onInputChange}
                                value={this.state.selectStatus}
                            >
                                <option value="-1">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </SelectComponent>
                        </div>
                    </div>
                    <div className="banners-buttons">
                        {this.props.match.params.id ? (
                            <div className="save-button" onClick={this.update}>
                                Update
                            </div>
                        ) : (
                            <div className="save-button" onClick={this.save}>
                                Save
                            </div>
                        )}
                        <div
                            className="cancel-button"
                            onClick={() => history.goBack()}
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VolunteerSetup;


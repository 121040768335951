import { React, Component } from "react";
import Validator from "validatorjs";
import {
    BsInput,
    BsArea,
    FileUploader,
    SelectComponent,
} from "../../../components/Form/FormComponents";
import "./createNeedHelp.css";
import { toast } from "react-toastify";
import HeadingCompnonet from "./HeadingComponent";
import history from "../../../history";
import DateTimePicker from "react-datetime-picker";
import { Form } from "react-bootstrap";
import moment from "moment";
const MaterialService = require("../../../services/MaterialService");
class CreateNeedHelp extends Component {
    constructor(props) {
        super(props);
    }
    state = {};
    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
    };
    onDateChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };
    onFileChange = (event) => {
        let name = event.target.name;
        if (event.target.files[0].size > 548576) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [name]: ["File size should be less than 500KB"],
                },
            });

            return;
        } else {
            this.setState({
                [name]: event.target.files[0],
                errors: { ...this.state.errors, [name]: null },
            });
        }
    };
    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (event.target.type === "file") {
            this.setState({ [name]: event.target.files[0] });
        } else {
            this.setState({ [name]: value });
        }
    };
    uploadMediaFile = async (data) => {
        const file = [{ name: data.name, type: data.type, size: data.size }];
        let uploadUrls = await MaterialService.postData("/v2/get-upload-urls", {
            files: file,
        });
        if (uploadUrls.status) {
            let uploadUrl = uploadUrls.data[0];

            let uploadImage = uploadUrl.mediaObject.data;

            await Promise.all([
                new Promise((resolve, reject) => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("PUT", uploadUrl.s3Url.data.postUrl, true);
                    xhr.setRequestHeader("Content-Type", uploadImage.type);
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            return resolve(uploadUrl.s3Url.data.getUrl);
                        } else {
                            reject(null);
                        }
                    };
                    xhr.send(data);
                }),
            ]);
            return uploadImage;
        }
    };
    save = async () => {
        await this.setState({ errors: {} });
        const rules = {
            date: "required",
            altTag: "required",
            title: "required",
            readMore: "required",
            media: "required",
            selectStatus: "required",
        };
        const message = {
            "required.date": "Select date",
            "required.altTag": "Enter atg",
            "required.media": "Select image ",
            "required.selectStatus": "Select status ",
            "required.title": "Enter Title",
            "required.readMore": "Enter Read More",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        let imageId = await this.uploadMediaFile(this.state.media);
        if (validator.passes()) {
            let submit = {
                date: this.state.date,
                altTag: this.state.altTag,
                status: this.state.selectStatus,
                mediaId: imageId.id,
                title: this.state.title,
                readMore: this.state.readMore,
            };
            MaterialService.postData(
                "/v2/get-involved/show-interest",
                submit
            ).then((res) => {
                if (res.status) {
                    toast.success(" Successfully updated", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    history.push("/material-manager/partners-list");
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
        }
        this.setState({ errorMessage: "", errors: null });
    };
    render() {
        return (
            <div className="need-help--main-container" id="needHelp">
                <HeadingCompnonet mainTitle="Need Help" />
                <div className="main-container">
                    <div className="mian-heading">Need help- creation</div>
                    <BsInput
                        required
                        label="Title"
                        placeholder="Enter Title"
                        name="title"
                        type=""
                        onChange={this.onInputChange}
                        value={this.state.title}
                        errors={this.state.errors}
                        className="col-md-12"
                    />
                    <BsArea
                        required
                        label="Read More Text"
                        placeholder="Enter description"
                        name="readMore"
                        type=""
                        onChange={this.onInputChange}
                        value={this.state.readMore}
                        errors={this.state.errors}
                        className="col-md-12"
                    />

                    <div className="col-md-12 row nedd-help-medi-container">
                        <div className="col-md-5">
                            <FileUploader
                                required
                                section_title="Media"
                                validators={{
                                    types: [".jpeg", ".png", ".tiff"],
                                }}
                                value={this.state.media}
                                onChange={this.onFileChange}
                                name="media"
                                title="Add Image/video"
                                desc=".jpeg, .png & .tiff
                            File not more than 500 KB  
                            .MP4
File not more than 1 Mb  "
                            />{" "}
                            {this.getErrorMessage("media")}
                        </div>
                        <BsInput
                            required
                            label="alt Tag"
                            placeholder="Enter alt tag"
                            name="altTag"
                            type=""
                            onChange={this.onInputChange}
                            value={this.state.altTag}
                            errors={this.state.errors}
                            className="col-md-6"
                        />
                    </div>
                    <div className="date-container col-md-5">
                        <div className="form-label">Date of Submission</div>
                        <div
                            className="col-md-4"
                            style={{ paddingLeft: "0px" }}
                        ></div>
                        <Form.Control
                            isInvalid={
                                this.state.errors && this.state.errors["date"]
                                    ? true
                                    : false
                            }
                            value={this.state.date}
                            type="date"
                            min={moment().format("YYYY-MM-DD")}
                            name="date"
                            onChange={this.onInputChange}
                        />
                        {this.getErrorMessage("date")}
                    </div>
                </div>
                <div className="col-md-5 margin-top">
                    <SelectComponent
                        required
                        name="selectStatus"
                        label="Select Status"
                        errors={this.state.errors}
                        onChange={this.onInputChange}
                        defaultValue={this.state.selectStatus}
                        className="col-md-3"
                        value={this.state.selectStatus}
                    >
                        <option value="-1">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                        <option value="2">Delete</option>
                    </SelectComponent>
                </div>
                <div className="banners-buttons">
                    <div
                        className="save-button"
                        onClick={() => {
                            this.save();
                        }}
                    >
                        save
                    </div>

                    <div
                        className="cancel-button"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateNeedHelp;

import ApiService from './Axios';

const BASE_URL = '/talks';

export const fetchData = (url) => {
	return ApiService.request({
		method: 'GET',
		url: BASE_URL + url,
		data: {},
	});
};

export const fetchJson = (url) => {
	return ApiService.request({
		method: 'GET',
		url: url,
		data: {},
	});
};

export const postData = (url, data) => {
	return ApiService.request({
		method: 'POST',
		url: BASE_URL + url,
		data: data,
	});
};

export const postEventRegistration = (data) => {
	return postData('/events/register', data);
};

export const postSubscription = (data) => {
	return postData('/events/subscribe-talks', data);
};

export const subscriptionOtp = (data) => {
	return postData('/events/subscribe-talks-otp-verify', data);
};

export const eventRegistrationOtp = (data) => {
	return postData('/events/register-events-otp-verify', data);
};

export const sendReminder = (data) => {
	return postData('/events/manage/reminder', data);
};

export const sendInvite = (data) => {
	return postData('/events/manage/invite', data);
};

export const sendVideoLink = (data) => {
	return postData('/events/manage/event/videoLink', data);
};

export const sendFeedbackQuestion = (data) => {
	return postData('/events/manage/feedback/questions/add', data);
};

export const editFeedbackQuestion = (data) => {
	return postData('/events/manage/feedback/questions/edit', data);
};

export const uploadUsers = (data) => {
	return postData('/events/manage/upload/users', data);
};

export const createFeedback = (data) => {
	return postData('/events/manage/event/feedback', data);
};

export const createTestimony = (data) => {
	return postData('/events/manage/testimonial/create', data);
};

export const editTestimony = (data) => {
	return postData('/events/manage/testimonial/update', data);
};

export const createFaq = (data) => {
	return postData('/events/manage/FAQs/create', data);
};

export const editFaq = (data) => {
	return postData('/events/manage/FAQs/edit', data);
};

export const createCategory = (data) => {
	return postData('/events/manage/event/FAQCategory', data);
};

export const updateCategory = (data) => {
	return postData('/events/manage/event/FAQCategory/update', data);
};

export const createNotes = (data) => {
	return postData('/events/user/wishwatalks/dashboard/add-note', data);
};

export const createSlot = (data) => {
	return postData('/events/user/slot/request', data);
};

export const createCamp = (data) => {
	return postData('/camps/manage/create', data);
};

export const editCamp = (data) => {
	return postData('/camps/manage/update', data);
};

export const sendCampInvite = (data) => {
	return postData('/camps/manage/invite', data);
};

export const sendCampReminder = (data) => {
	return postData('/camps/manage/reminder', data);
};

export const registerCamp = (data) => {
	return postData('/camps/register', data);
};

export const campRegisterOtp = (data) => {
	return postData('/camps/register-camps-otp-verify', data);
};

export const addParticipant = (data) => {
	return postData('/camps/manage/add', data);
};

export const editParticipant = (data) => {
	return postData('/camps/manage/edit', data);
};

export const addCampMedia = (data) => {
	return postData('/camps/manage/add-media', data);
};

export const unsubscribeTalks = (data) => {
	return postData('/unsubscribe', data);
};

export const createCompetition = (data) => {
	return postData('/competitions/manage/create', data);
};

export const editCompetition = (data) => {
	return postData('/competitions/manage/edit', data);
};

export const registerCompetition = (data) => {
	return postData('/competitions/register', data);
};

export const competitionRegistrationOtp = (data) => {
	return postData('/competitions/register-events-otp-verify', data);
};

export const addMedia = (data) => {
	return postData('/competitions/manage/add-media', data);
};

export const addCompetitionParticipants = (data) => {
	return postData('/competitions/manage/add-participants', data);
};

export const editCompetitionParticipants = (data) => {
	return postData('/competitions/manage/edit-participants', data);
};

export const addResult = (data) => {
	return postData('/competitions/manage/add-results', data);
};

export const addSurvey = (data) => {
	return postData('/events/manage/survey', data);
};

export const postSurvey = (data) => {
	return postData('/events/survey/response', data);
};

export const createBanner = (data) => {
	return postData('/events/manage/banner/create', data);
};

export const editSlot = (data) => {
	return postData(`/events/manage/slot/${data.id}/update`, data);
};

export const postPreference = (data) => {
	return postData('/events/user/preferences', data);
};

export const createJob = (data) => {
	return postData('/jobs/manage/post-job', data);
};

export const editJob = (data) => {
	return postData('/jobs/manage/job/update', data);
};

export const applyJob = (data) => {
	return postData('/jobs/apply', data);
};

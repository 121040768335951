import * as actions from "../actions";

// let authenticated = false;

// if (localStorage.getItem("authenticated") === "true") {
//     //do a token validation api call here
//     authenticated = true;
// }

const initialState = {
    chats: [],
};

const chatReducer = (state = initialState, action) => {
    //action.accessToken
    switch (action.type) {
        case actions.SENDCHATMESSAGE:
            return {
                ...state,
            };
        case actions.RECEIVECHATMESSAGE:
            return {
                ...state,
                new_message: action.data,
            };
        default:
            return {
                ...state,
            };
    }
};

export default chatReducer;

import moment from "moment";
import { Component } from "react";
import { toast } from "react-toastify";
import history from "../../../history";
import HeadingComponent from "./HeadingComponent";
import "./volunteerLists.css";
import axios from "axios";
import XLSX from "xlsx";
const MaterialService = require("../../../services/MaterialService");

class VolunteerLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            volunteerLists: [],
            filteredRequests: [],
            selectedStatus: "all",
            downloadKey: "",
            loaded: false,
            showFilterDropdown: false,
            showStatusDropdown: null,
        };
    }

    componentDidMount = () => {
        this.fetchData();
    };

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/volunteer/list").then((res) => {
            if (res.status) {
                this.setState({
                    volunteerLists: res.data,
                    filteredRequests: res.data,
                    downloadKey: res.meta.downloadKey,
                    loaded: true,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };

    handleStatusFilter = (status) => {
        const filteredRequests =
            status === "all"
                ? this.state.volunteerLists
                : this.state.volunteerLists.filter(
                      (request) => request.status === parseInt(status)
                  );

        this.setState({
            selectedStatus: status,
            filteredRequests,
            // showFilterDropdown: false,
        });
    };

    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/volunteer/status-update", {
            id: id,
            status: status,
        })
            .then((res) => {
                if (res.status) {
                    const updatedRequests = this.state.filteredRequests.map(
                        (request) =>
                            request.id === id
                                ? { ...request, status: parseInt(status) }
                                : request
                    );

                    this.setState({ filteredRequests: updatedRequests });

                    callback(id, status);
                } else {
                    toast.error(res.message || "Failed to update status", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            })
            .catch((error) => {
                toast.error("An error occurred while updating status", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    toggleFilterDropdown = () => {
        this.setState((prevState) => ({
            showFilterDropdown: !prevState.showFilterDropdown,
        }));
    };

    toggleStatusDropdown = (id) => {
        this.setState((prevState) => ({
            showStatusDropdown: !prevState.showStatusDropdown,
        }));
    };

    handleStatusChange = (id, status) => {
        this.updateStatus(id, status);
        this.toggleStatusDropdown(id);
    };

    handleDownload = () => {
        const statusMapping = {
            0: "Inactive",
            1: "Active",
            2: "Requested",
        };

        const transformedData = this.state.volunteerLists.map((item) => ({
            ...item,
            createdDate: moment(item.createdDate).format("DD MMM YYYY"),
            status: statusMapping[item.status] || "Unknown",
        }));

        const ws = XLSX.utils.json_to_sheet(transformedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Volunteers");
        XLSX.writeFile(wb, "VolunteerList.xlsx");
    };

    render() {
        return (
            <div className="main-container" id="volunteerLists">
                <div>
                    <HeadingComponent
                        mainTitle="Volunteering Details"
                        title="ADD Volunteer"
                        path="/material-manager/volunteer-setup"
                    />
                    <div className="banners-buttons">
                        <div
                            className="save-button"
                            style={{ cursor: "pointer" }}
                            onClick={this.handleDownload}
                        >
                            Download
                        </div>
                    </div>
                    <div className="main-volunteer-list-container">
                        <div className="main-volunteer-off-heading">
                            Volunteers listed
                        </div>
                        <div className="col-md-12 row heading-container">
                            <div className="col-md-1 padding-no">Date</div>
                            <div className="col-md-1 padding-no">Full Name</div>
                            <div className="col-md-2 padding-no">Email</div>
                            <div
                                className="col-md-2 padding-no"
                                style={{
                                    textAlign: "start",
                                }}
                            >
                                Mobile
                            </div>
                            <div
                                className="col-md-1 padding-no"
                                style={{ marginLeft: "-40px" }}
                            >
                                Availability
                            </div>
                            <div className="col-md-1 padding-no status-column">
                                <div
                                    className="status-dropdownnn"
                                    style={{ display: "flex" }}
                                    onClick={this.toggleFilterDropdown}
                                >
                                    <div
                                        className="status-label"
                                        style={{ marginLeft: "20px" }}
                                    >
                                        Status
                                    </div>
                                    <span className="dropdown-icon">
                                        <i className="fas fa-chevron-down"></i>
                                    </span>
                                    {this.state.showFilterDropdown ? (
                                        <ul className="dropdown-menu">
                                            <li
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    this.handleStatusFilter(
                                                        "all"
                                                    );
                                                }}
                                            >
                                                All
                                            </li>
                                            <li
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    this.handleStatusFilter(
                                                        "1"
                                                    );
                                                }}
                                            >
                                                Active
                                            </li>
                                            <li
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    this.handleStatusFilter(
                                                        "0"
                                                    );
                                                }}
                                            >
                                                Inactive
                                            </li>
                                            <li
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    this.handleStatusFilter(
                                                        "2"
                                                    );
                                                }}
                                            >
                                                Requested
                                            </li>
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className="col-md-1 padding-no"
                                style={{ marginLeft: "30px" }}
                            >
                                Task Completed
                            </div>
                            <div
                                className="col-md-1 padding-no"
                                style={{ marginLeft: "40px" }}
                            >
                                Edit
                            </div>
                        </div>
                        <div className="drop-off-details-container">
                            {this.state.filteredRequests.length > 0 ? (
                                this.state.filteredRequests.map((lists) => (
                                    <div
                                        key={lists.id}
                                        className="volunteer-details-contents col-md-12 row"
                                    >
                                        <div className="col-md-1 padding-no">
                                            {moment(lists.createdDate).format(
                                                "DD-MMM-YYYY"
                                            )}
                                        </div>
                                        <div className="col-md-1 padding-no">
                                            {lists.fullName}
                                        </div>
                                        <div
                                            className="col-md-2 padding-no"
                                            style={{
                                                overflowWrap: "anywhere",
                                            }}
                                        >
                                            {lists.email}
                                        </div>
                                        <div className="col-md-2 padding-no">
                                            {lists.phone}
                                        </div>
                                        <div
                                            className="col-md-1 padding-no"
                                            style={{ marginLeft: "-40px" }}
                                        >
                                            {lists.availability}
                                        </div>

                                        <div
                                            style={{ marginLeft: "20px" }}
                                            className="col-md-1 status-dropdownn"
                                            onClick={() =>
                                                this.toggleStatusDropdown(
                                                    lists.id
                                                )
                                            }
                                        >
                                            <div className="status-label">
                                                {lists.status === 1
                                                    ? "Active"
                                                    : lists.status === 0
                                                    ? "Inactive"
                                                    : lists.status === 2
                                                    ? "Requested"
                                                    : "Unknown"}
                                            </div>
                                            <span className="dropdown-icon">
                                                <i className="fas fa-chevron-down"></i>
                                            </span>
                                            {this.state.showFilterDropdown ===
                                                lists.id && (
                                                <ul className="dropdown-menu">
                                                    <li
                                                        onClick={() =>
                                                            this.handleStatusChange(
                                                                lists.id,
                                                                "1"
                                                            )
                                                        }
                                                    >
                                                        Active
                                                    </li>
                                                    <li
                                                        onClick={() =>
                                                            this.handleStatusChange(
                                                                lists.id,
                                                                "0"
                                                            )
                                                        }
                                                    >
                                                        Inactive
                                                    </li>
                                                    <li
                                                        onClick={() =>
                                                            this.handleStatusChange(
                                                                lists.id,
                                                                "2"
                                                            )
                                                        }
                                                    >
                                                        Requested
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                        <div
                                            className="col-md-1 padding-no"
                                            style={{ marginLeft: "50px" }}
                                        >
                                            {lists.taskCompleted}
                                        </div>
                                        <div className="col-md-1 padding-no banner-edit-button">
                                            <i
                                                className="fas fa-pencil-alt"
                                                onClick={() => {
                                                    history.push(
                                                        `/material-manager/volunteer-setup/${lists.id}`
                                                    );
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div
                                    className="alert alert-warning"
                                    style={{
                                        width: "max-content",
                                        height: "50px",
                                        textAlign: "center",
                                        margin: "auto",
                                    }}
                                >
                                    No Data Found
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VolunteerLists;

import React, { Component } from "react";
import { connect } from "react-redux";

import "./loaderspinner.css";

class LoaderSpinner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.iconMode) {
            return (
                <div className="iconMode">
                    <img src="/images/wishwa-logo-big.png" alt="logo" />
                </div>
            );
        }
        return (
            <div style={{ minHeight: "100px", backgroundColor: "#FFF" }}>
                <div class="loader">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderSpinner);

import React, { Component } from 'react';
// import { act } from 'react-dom/test-utils';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import axios from 'axios';
import {  toast } from 'react-toastify';
import history from '../../history';
import { CircularProgressbar } from 'react-circular-progressbar';
import './signin.css';

class MobileOTPScreen extends Component {
	constructor() {
		super();
		this.inputRefs = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
		this.state = {
			resendMessage: '',
			resendStatus: false,
			resendActive: false,
			showTimer: 'hidden',
			disableResend: false,
			time: 60,
		};
		this.timer = 60;
		this.onOTPVerify = this.onOTPVerify.bind(this);
		this.emailOTPVerify = this.emailOTPVerify.bind(this);
	}

	emailOTPVerify() {}

	componentDidMount() {
		this.inputRefs[0].focus();
	}

	onOTPVerify(event) {
		let self = this;

		let endpoint = '/user/signin-OTP-verify';

		if (this.props.mode === 'signup') {
			endpoint = '/user/signup-OTP-verify';
		}

		axios({
			method: 'post',
			url: endpoint,
			data: {
				mobile_no: self.props.mobile_no,
				otp:
					this.inputRefs[0].value +
					this.inputRefs[1].value +
					this.inputRefs[2].value +
					this.inputRefs[3].value,
			},
		}).then((data) => {
			let resp = data.data;
			if (data.status === 200) {
				if (!resp.status) {
					self.inputRefs.map((k, idx) => {
						k.value = '';
					});
					self.inputRefs[0].focus();
					this.setState({ errorMessage: resp.message });
					return;
				}

				this.setState({ errorMessage: null });
				toast.success('Logged in', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				this.props.authenicateUser(resp.data.accessToken, resp.data.fullname, resp.data);
				if (resp.data.role === 'manager') {
					if (resp.data.dropmenu) {
						if (resp.data.dropmenu.length > 0) {
							history.push(resp.data.dropmenu[0].target);
						}
					} else {
						history.push('/user');
					}
				}
			} else {
				this.setState({ errorMessage: 'Something went wrong' });
				return;
			}
		});
	}

	_goNextAfterEdit(index) {
		if (index < this.inputRefs.length - 1) {
			this.inputRefs[index + 1].focus();
		}
	}

	_goPrevAfterEdit(index) {
		if (index > 0) {
			this.inputRefs[index - 1].focus();
		}
	}

	onInputchange(event) {
		if (this.state.errorMessage) {
			this.setState({ errorMessage: null });
		}
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	startTimer = () => {
		setInterval(this.countDown, 1000);
	};

	countDown = () => {
		let time = this.state.time - 1;
		this.setState({
			time: time,
		});

		if (time === 0) {
			clearInterval(this.timer);
			this.setState({ showTimer: 'hidden', disableResend: false, resendActive: false });
		}
	};

	resendOTP = async (mobile_no) => {
		/* await this.setState({
			showTimer: 'visible',
			time: 60,
			disableResend: true,
		});
		this.timer = setInterval(this.countDown, 1000); */

		axios({
			method: 'post',
			url: '/user/resend-signin-otp',
			data: {
				mobile_no: mobile_no,
			},
		}).then(async (response) => {
			console.log(response);
			if (response.data.status) {
				this.setState({ resendActive: true, resendStatus: true, resendMessage: response.data.message });
				await this.setState({
					showTimer: 'visible',
					time: 60,
					disableResend: true,
				});
				this.timer = setInterval(this.countDown, 1000);
			} else
				this.setState({
					resendActive: true,
					resendStatus: false,
					resendMessage: response.data.message,
					disableResend: true,
				});
		});
	};

	render() {
		let errMessage = null;
		if (this.state.errorMessage) {
			errMessage = <div className="alert alert-danger">{this.state.errorMessage}</div>;
		}
		let send_to = this.props.mobile_no;
		if (this.props.mode == 'forgot_password') {
			send_to = this.props.email;
		}

		return (
			<div className="popup-form-container" style={{ justifyContent: 'space-evenly' }}>
				<div className="otp-form">
					<div className="otp-title">
						Enter OTP sent to <br /> <strong>{send_to}</strong>
					</div>
					<div className="otp-input">
						{this.inputRefs.map((k, idx) => (
							<input
								onKeyUp={(event) => {
									const key = event.key; // const {key} = event; ES6+
									if (key === 'Backspace') {
										this._goPrevAfterEdit(idx);
									}
								}}
								onChange={(event) => {
									const key = event.key; // const {key} = event; ES6+
									if (key === 'Backspace') {
										this._goPrevAfterEdit(idx);
									}

									this._goNextAfterEdit(idx);
									this.onInputchange(event);
								}}
								ref={(r) => (this.inputRefs[idx] = r)}
								name={'otp_' + idx}
								maxLength="1"
							/>
						))}
					</div>
					{errMessage}
					<div className="action-container">
						<buton className="signinbutton" onClick={this.onOTPVerify}>
							Verify OTP
						</buton>
						<div className="resend-container">
							Didn’t recieve OTP?{' '}
							<div className="resend-action">
								<button
									className="resend-otp"
									disabled={this.state.disableResend}
									onClick={() => {
										this.resendOTP(this.props.mobile_no);
									}}
								>
									RESEND OTP
								</button>
								<div
									className="timer-container"
									style={{ marginLeft: '10px', visibility: this.state.showTimer }}
								>
									<CircularProgressbar
										strokeWidth={25}
										value={this.state.time}
										maxValue={60}
										minValue={0}
									/>
								</div>
							</div>
						</div>
						{(() => {
							console.log(this.state);
							if (this.state.resendActive) {
								if (this.state.resendStatus)
									return (
										<div className="alert alert-success" style={{ marginTop: '20px' }}>
											{this.state.resendMessage}
										</div>
									);
								return (
									<div className="alert alert-danger" style={{ marginTop: '20px' }}>
										{this.state.resendMessage}
									</div>
								);
							}
							return (
								<div
									className="alert alert-success"
									style={{ marginTop: '20px', visibility: 'hidden' }}
								>
									{'[]'}
								</div>
							);
						})()}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authenicateUser: (token, name = '', user) =>
			dispatch({ type: actions.AUTHENTICATED, accessToken: token, username: name, user: user }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileOTPScreen);

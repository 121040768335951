import { Quill } from "react-quill/dist/react-quill";
import LoadingImage from "./blots/image.js";
import VideoBlot from "./blots/video.js";
import "./imageUploader.css";
Quill.register({ "formats/videoBlot": VideoBlot });
class VideoUploader {
    constructor(quill, options) {
        this.quill = quill;
        this.options = options;
        this.range = null;

        if (typeof this.options.upload !== "function")
            console.warn(
                "[Missing config] upload function that returns a promise is required"
            );

        var toolbar = this.quill.getModule("toolbar");
        toolbar.addHandler("video", this.selectLocalImage.bind(this));

        this.readAndUploadFile = this.readAndUploadFile.bind(this);
        this.fileChanged = this.fileChanged.bind(this);
        this.insertBase64Image = this.insertBase64Image.bind(this);
        this.insertToEditor = this.insertToEditor.bind(this);
        this.removeBase64Image = this.removeBase64Image.bind(this);
    }

    selectLocalImage() {
        this.range = this.quill.getSelection();
        this.fileHolder = document.createElement("input");
        this.fileHolder.setAttribute("type", "file");
        this.fileHolder.setAttribute("accept", "video/*");
        this.fileHolder.setAttribute("style", "visibility:hidden");

        this.fileHolder.onchange = this.fileChanged.bind(this);

        document.body.appendChild(this.fileHolder);

        this.fileHolder.click();

        window.requestAnimationFrame(() => {
            document.body.removeChild(this.fileHolder);
        });
    }

    readAndUploadFile(file) {
        let isUploadReject = false;

        const fileReader = new FileReader();

        fileReader.addEventListener(
            "load",
            () => {
                if (!isUploadReject) {
                    let base64ImageSrc = fileReader.result;
                    this.insertBase64Image(base64ImageSrc);
                }
            },
            false
        );

        if (file) {
            fileReader.readAsDataURL(file);
        }

        this.options.upload(file).then(
            (imageUrl) => {
                this.insertToEditor(imageUrl, () => {
                    this.removeBase64Image();
                });
            },
            (error) => {
                isUploadReject = true;
                this.removeBase64Image();
                console.warn(error);
            }
        );
    }

    fileChanged() {
        const file = this.fileHolder.files[0];
        this.readAndUploadFile(file);
    }

    insertBase64Image(url) {
        const range = this.range;
        // this.quill.insertEmbed(
        //     range.index,
        //     LoadingImage.blotName,
        //     `${url}`,
        //     "user"
        // );
    }

    insertToEditor(url, callBack = null) {
        console.log(url);
        const range = this.range;
        console.log("elete the placeholder Imag");
        // Insert the server saved image
        this.quill.insertEmbed(
            range.index,
            VideoBlot.blotName,
            `${url}`,
            "user"
        );
        range.index++;
        this.quill.setSelection(range, "user");
    }

    removeBase64Image() {
        const range = this.range;
        this.quill.deleteText(range.index, 3, "user");
    }
}

window.VideoUploader = VideoUploader;
export default VideoUploader;

import { Component } from "react";
import "./seo.css";
import { BsInput, BsArea } from "../../../components/Form/FormComponents";
import history from "../../../history";
import Validator from "validatorjs";
import md5 from 'md5';
import {postData as UserServicePostData,fetchData as UserServiceFetchData} from '../../../services/UserService';

class SeoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metaTitle: '',
            metaKeywords: '',
            metaDescription: '',
        };
        this.currentURL = window.location.pathname;
         
    }

    componentDidMount = () => {

        UserServiceFetchData(`/cms/seo-meta/${md5(window.location.pathname.toLowerCase())}`).then((response)=>{
            if(response.status){
                this.setState({
                    ...response.data
                })
            }
        })

        
        //Fetch the data and initialize

    };

    onInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };

    getData = () => { 
        const rules = {
            metaTitle: "required",
            metaKeywords: "required",
            metaDescription: "required",
        };
        const message = {
            "required.metaTitle": "Enter the meta title",
            "required.metaKeywords": "Enter the meta keywords",
            "required.metaDescription": "Enter the meta description",
        };
        let validator = new Validator(this.state, rules, message);

        if (validator.fails()) {
            this.setState({
                errors: { ...this.state.errors, ...validator.errors.all() },
            });
            return null;
        }


        return {
            id: md5(window.location.pathname.toLocaleLowerCase()),
            metaTitle: this.state.metaTitle,
            metaKeywords: this.state.metaKeywords,
            metaDescription: this.state.metaDescription,
        }
 

    };

    render() {
        return (
            <div id="seoFormId">
                <div className="seo-title"> 
                <br/> <small>Page Url:{ window.location.pathname}</small>
                </div> 
                <div className="row">
                    <div className="col-md-12">
                        <BsInput
                            required
                            label="Meta Title"
                            type="text"
                            name="metaTitle"
                            value={this.state.metaTitle}
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            className="form-content"
                        />
                        <BsArea
                            required
                            maxLength={300}
                            label="Meta Keywords (10 Max)"
                            name="metaKeywords"
                            value={this.state.metaKeywords}
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            rows="5"
                            className="form-content"
                        />
                    </div>
                    <div className="col-md-12">
                        <BsArea
                            required
                            maxLength={300}
                            label="Meta Description"
                            label2="Maximum 300 Characters"
                            name="metaDescription"
                            value={this.state.metaDescription}
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            rows="5"
                            className="form-content"
                        />
                    </div>
                </div>

                {/* <div className="buttons-section">
                    <div
                        className="btn btn-primary"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        CANCEL
                    </div>
                    <div className="btn btn-primary" onClick={this.update}>
                        UPDATE
                    </div>
                </div> */}
            </div>
        );
    }
}

export default SeoForm;

import Validator from "validatorjs";

export const registerValidators = () => {
    Validator.register(
        "includes_comma",
        function (value) {
            if (value.includes(",")) {
                return false;
            }
            return true;
        },
        "The :attribute shouldn't contain comma"
    );
    Validator.register(
        "upload_db_item",
        function (value) {
            if (!value) return false;
            if (!(typeof value === "object")) return false;

            if (!value["name"]) return false;

            if (
                (!value["email"] || !isInvalidValidEmail(value["email"])) &&
                (!value["mobileNo"] || !isInvalidMobile(value["mobileNo"]))
            )
                return false;

            return true;
        },
        "The :attribute is invalid"
    );
};

function isInvalidMobile(mobileNo) {
    let validator = new Validator(
        { mobileNo: mobileNo },
        { mobileNo: "required|numeric" }
    );
    if (validator.fails()) {
        return false;
    }
    if (mobileNo.toString().length !== 10) {
        return false;
    }
    return true;
}

function isInvalidValidEmail(email) {
    let validator = new Validator(
        { email: email },
        { email: "required|email" }
    );
    if (validator.fails()) {
        return false;
    }
    return true;
}

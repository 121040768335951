import { Component, createRef } from "react";
import Validator from "validatorjs";

import {
    FileUploader,
    SelectComponent,
    BsArea,
    BsInput,
    BsRichText,
} from "../../../components/Form/FormComponents";
import "./createMaterialRequest.css";
import { withRouter } from "react-router-dom";
import SuccessPopUp from "./SuccessPopUp";

import HeadingComponent from "./HeadingComponent";
const WishwaTalkService = require("../../../services/WishwaTalkService");

const MaterialService = require("../../../services/MaterialService");

class CreateMaterialRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: "",
            description: "",
            mobile: "",
            organization: "",
            name: "",
            email: "",
            locality: "",
            quantity: "",
            uploadedImage: null,
            file: null,
            errors: {},
            categories: [],
            selectedCategory: null,
            requestMaterialsImage: null,
            bannerImage: null,
            materialsDescription: "",
            status: "0",
            logoMediaId: "",
            requestMaterialsImageId: "",
            bannerImageId: "",
            cta_text: "",
            showSuccessPopUp: false,
        };
        this.richtextRef = createRef();
    }

    componentDidMount() {
        MaterialService.fetchData("/v2/config/categories").then((res) => {
            if (res.status) {
                this.setState({ categories: res.data });
            }
        });
        const { id } = this.props.match.params;
        if (id) {
            this.fetchMaterialRequestData(id);
        }
        this.fetchMaterialRequestData();
    }
    resetForm = () => {
        this.setState({
            header: "",
            description: "",
            mobile: "",
            organization: "",
            name: "",
            email: "",
            locality: "",
            quantity: "",
            uploadedImage: null,
            file: null,
            errors: {},
            selectedCategory: null,
            requestMaterialsImage: null,
            bannerImage: null,
            materialsDescription: "",
            status: "0",
            logoMediaId: "",
            requestMaterialsImageId: "",
            bannerImageId: "",
            cta_text: "",
        });
    };
    onFileChange = async (event, type) => {
        const file = event.target.files[0];

        if (file) {
            const previewUrl = URL.createObjectURL(file);
            console.log("Selected File:", file);

            if (type === "logo") {
                this.setState({ file, uploadedImage: previewUrl });
            } else if (type === "requestMaterials") {
                this.setState({ requestMaterialsImage: previewUrl });
            } else if (type === "banner") {
                this.setState({ bannerImage: previewUrl });
            }

            try {
                const uploadUrls = await this.uploadMediaFile(file);

                if (uploadUrls) {
                    const s3Url = uploadUrls.s3Url.data.getUrl;
                    console.log("Uploaded S3 Link:", s3Url);
                    if (type === "logo") {
                        this.setState({
                            logoMediaId: uploadUrls.mediaObject.data.id,
                            uploadedImage: s3Url,
                        });
                    } else if (type === "requestMaterials") {
                        this.setState({
                            requestMaterialsImageId:
                                uploadUrls.mediaObject.data.id,
                            requestMaterialsImage: s3Url,
                        });
                    } else if (type === "banner") {
                        this.setState({
                            bannerImageId: uploadUrls.mediaObject.data.id,
                            bannerImage: s3Url,
                        });
                    }
                }
            } catch (error) {
                console.error("Image upload failed", error);
                alert("Failed to upload image. Please try again.");
            }
        }
    };

    fetchMaterialRequestData = (id) => {
        MaterialService.fetchData(`/v2/material-requests/detail/${id}`)
            .then((res) => {
                if (res.status) {
                    const item = res.data;
                    console.log("Fetched Request Data:", item);
                    const material =
                        item.material && item.material.length > 0
                            ? item.material[0]
                            : {};
                    const plainTextDescription = material.description
                        ? material.description.replace(/<\/?[^>]+(>|$)/g, "")
                        : "";

                    this.richtextRef.current.setEditorContents(
                        this.richtextRef.current.getEditor(),
                        material.description
                    );

                    this.setState({
                        header: item.header || "",
                        description: item.description || "",
                        mobile: item.mobile || "",
                        organization: item.org_name || "",
                        name: item.primary_name || "",
                        email: item.email || "",
                        locality: item.locality || "",
                        quantity: material.quantity || "",
                        selectedCategory: material.category || "",
                        materialsDescription: plainTextDescription,
                        bannerImage: item.images?.bannerImage?.storageURL || "",
                        requestMaterialsImage:
                            item.images?.requesting_materail_image
                                ?.storageURL || "",
                        uploadedImage: item.images?.logo.storageURL || "",
                        logoMediaId: item.images?.logo?.id || "",
                        material_request_banner_img:
                            item.images?.bannerImage.id,
                        material_request_img:
                            item.images?.requesting_materail_image.id,
                        cta_text: item.cta_text || "",
                    });
                } else {
                    console.error("No request data found.");
                }
            })
            .catch((error) => {
                console.error(
                    "An error occurred while fetching material request data:",
                    error
                );
            });
    };
    editMaterialRequestData = async (id) => {
        const requestData = {
            header: this.state.header,
            description: this.state.description,
            userId: this.state.id,
            mobile_no: this.state.mobile,
            email: this.state.email,
            primary_name: this.state.name,
            org_name: this.state.organization,
            locality: this.state.locality,
            status: Number(this.state.status),
            cta_text: this.state.cta_text,
            materials: [
                {
                    category: this.state.selectedCategory,
                    quantity: this.state.quantity,
                    description: this.state.materialsDescription,
                },
            ],
            logo_media_id: this.state.logoMediaId,
            material_request_img: this.state.material_request_img,
            material_request_banner_img: this.state.material_request_banner_img,
        };

        try {
            const res = await MaterialService.patchData(
                `/v2/manager/material-request/edit/${id}`,
                requestData
            );

            if (res.status) {
                console.log("Material request updated successfully:", res.data);
                alert("Material request updated successfully.");
                window.location.href = "/material-manager/ngo-requests-list";
            } else {
                console.error("Failed to update the material request.");
                alert(
                    "Failed to update the material request. Please try again."
                );
            }
        } catch (error) {
            console.error("Error while updating the material request:", error);
            alert(
                "An unexpected error occurred while updating. Please try again later."
            );
        }
    };

    submitForm = async () => {
        const { id } = this.props.match.params;
        const requestData = {
            header: this.state.header,
            description: this.state.description,
            mobile_no: this.state.mobile,
            email: this.state.email,
            primary_name: this.state.name,
            org_name: this.state.organization,
            locality: this.state.locality,
            status: Number(this.state.status),
            cta_text: this.state.cta_text,
            materials: [
                {
                    category: this.state.selectedCategory,
                    quantity: this.state.quantity,
                    description: this.state.materialsDescription,
                },
            ],
            logo_media_id: this.state.logoMediaId,
            material_request_img: this.state.requestMaterialsImageId,
            material_request_banner_img: this.state.bannerImageId,
        };

        console.log(requestData, "Form Data");

        try {
            if (id) {
                await this.editMaterialRequestData(id);
            } else {
                const response = await MaterialService.createMaterialRequest(
                    requestData
                );
                if (response.status) {
                    console.log("Form submitted successfully", response.data);
                    alert("Form Submitted Successfully");
                    window.location.href =
                        "/material-manager/ngo-requests-list";
                } else {
                    console.error("Submission failed", response.data);
                    alert("Failed to submit the form. Please try again.");
                }
            }
        } catch (error) {
            console.error("An unexpected error occurred", error);
            alert("An unexpected error occurred. Please try again later.");
        }
    };

    uploadMediaFile = async (file) => {
        const fileData = [
            { name: file.name, type: file.type, size: file.size },
        ];
        let uploadUrls = await WishwaTalkService.postData(
            "/events/manage/get-upload-urls",
            { files: fileData }
        );

        if (uploadUrls.status) {
            const uploadUrl = uploadUrls.data[0];

            try {
                await new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open("PUT", uploadUrl.s3Url.data.postUrl, true);
                    xhr.setRequestHeader("Content-Type", file.type);
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            resolve();
                        } else {
                            reject("Upload failed");
                        }
                    };
                    xhr.send(file);
                });

                return uploadUrl;
            } catch (error) {
                console.error("Error uploading to S3:", error);
                alert("S3 upload failed.");
                return null;
            }
        }
    };

    onInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    getErrorMessage = (field) => {
        if (this.state.errors && this.state.errors[field]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[field]}
                </div>
            );
        }
    };

    handleCategoryChange = (e) => {
        this.setState({ selectedCategory: e.target.value });
    };

    handleRichTextChange = (value) => {
        this.setState({
            materialsDescription: value,
        });
    };

    render() {
        return (
            <div>
                <HeadingComponent />
                {this.state.showSuccessPopUp && <SuccessPopUp />}
                <div className="container form-container mt-4">
                    <div className="row">
                        <div className="col-12">
                            <BsArea
                                label="Header"
                                name="header"
                                required
                                placeholder="Enter header"
                                rows="2"
                                maxLength={100}
                                value={this.state.header}
                                onChange={(e) => {
                                    this.setState({ header: e.target.value });
                                }}
                                errors={this.state.errors}
                            />
                            {this.getErrorMessage("header")}
                        </div>
                    </div>
                    {/* Row 2: Description */}
                    <div style={{ marginTop: "-30px" }} className="row">
                        <div className="col-12">
                            <BsArea
                                label="Description"
                                name="description"
                                required
                                placeholder="Enter description"
                                rows="4"
                                maxLength={250}
                                value={this.state.description}
                                onChange={(e) =>
                                    this.setState({
                                        description: e.target.value,
                                    })
                                }
                            />
                            {this.getErrorMessage("description")}
                        </div>
                    </div>
                    {/* Row 3: Mobile and Name of Organization */}
                    <div style={{ marginTop: "-30px" }} className="row">
                        <div className="col-md-6">
                            <BsInput
                                label="Mobile"
                                name="mobile"
                                required
                                type="tel"
                                value={this.state.mobile}
                                placeholder="Enter mobile number"
                                onChange={(e) =>
                                    this.setState({ mobile: e.target.value })
                                }
                            />
                            {this.getErrorMessage("mobile")}
                        </div>
                        <div className="col-md-6">
                            <BsInput
                                label="Name of Organization"
                                name="organization"
                                required
                                type="text"
                                value={this.state.organization}
                                placeholder="Enter organization name"
                                onChange={(e) =>
                                    this.setState({
                                        organization: e.target.value,
                                    })
                                }
                            />
                            {this.getErrorMessage("organization")}
                        </div>
                    </div>
                    {/* Row 4: Name and Email */}
                    <div className="row">
                        <div className="col-md-6">
                            <BsInput
                                label="Name"
                                name="name"
                                value={this.state.name}
                                type="text"
                                required
                                placeholder="Enter your name"
                                onChange={(e) =>
                                    this.setState({ name: e.target.value })
                                }
                            />
                            {this.getErrorMessage("name")}
                        </div>
                        <div className="col-md-6">
                            <BsInput
                                label="Email"
                                name="email"
                                required
                                value={this.state.email}
                                type="email"
                                placeholder="Enter your email"
                                onChange={(e) =>
                                    this.setState({ email: e.target.value })
                                }
                            />
                            {this.getErrorMessage("email")}
                        </div>
                    </div>
                    {/* Row 5: Locality */}
                    <div className="row">
                        <div className="col-md-6">
                            <BsInput
                                label="Locality"
                                required
                                name="locality"
                                value={this.state.locality}
                                type="text"
                                placeholder="Enter locality"
                                onChange={(e) =>
                                    this.setState({ locality: e.target.value })
                                }
                            />
                            {this.getErrorMessage("locality")}
                        </div>
                    </div>
                    {/* Row 6: Upload Logo */}
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center">
                            <div style={{ flex: 1 }}>
                                <label>Upload Logo</label>
                                <FileUploader
                                    section_title=""
                                    section_title2=""
                                    name="logo"
                                    title="Upload Logo"
                                    desc=""
                                    multiple={true}
                                    onChange={(e) =>
                                        this.onFileChange(e, "logo")
                                    }
                                />
                            </div>

                            {this.state.uploadedImage && (
                                <div className="image-upload-container">
                                    <span
                                        className="close-icon"
                                        onClick={() => {
                                            this.setState({
                                                uploadedImage: null,
                                            });
                                        }}
                                    >
                                        X
                                    </span>

                                    <img
                                        src={this.state.uploadedImage}
                                        alt="Uploaded Logo"
                                        className="uploaded-image"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Row 7: Category Selection */}
                    <div className="row">
                        <div className="col-md-6">
                            <SelectComponent
                                required
                                name="selectedCategory"
                                label="Select Category"
                                value={this.state.selectedCategory}
                                onChange={this.handleCategoryChange}
                            >
                                <option value="">Select category</option>
                                {this.state.categories.map((category) => (
                                    <option
                                        key={category.id}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </option>
                                ))}
                            </SelectComponent>
                            {this.getErrorMessage("selectedCategory")}
                        </div>
                    </div>
                    {/* Row 8: Quantity */}
                    <div className="row">
                        <div className="col-md-6">
                            <BsInput
                                label="Enter Quantity"
                                name="quantity"
                                value={this.state.quantity}
                                type="text"
                                placeholder="Enter quantity"
                                onChange={(e) =>
                                    this.setState({ quantity: e.target.value })
                                }
                            />
                            {this.getErrorMessage("quantity")}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <BsRichText
                                quillRef={this.richtextRef}
                                label="Materials Description"
                                label2="Maximum 5000 Characters"
                                value={this.state.materialsDescription}
                                maxLength={5000}
                                required
                                onChange={this.handleRichTextChange}
                            />
                            {this.getErrorMessage("materialsDescription")}
                        </div>
                    </div>
                    {/* Row 9: Upload Request Materials Picture and Upload Banner */}
                    <div className="row">
                        {/* Request Materials Section */}
                        <div className="col-md-6">
                            <label>Upload Request Materials Picture</label>
                            <div className="row">
                                {/* File Uploader */}
                                <div className="col-md-8">
                                    <FileUploader
                                        section_title=""
                                        section_title2=""
                                        name="requestMaterials"
                                        title="Upload Request Materials Picture"
                                        desc=""
                                        multiple={false}
                                        onChange={(e) =>
                                            this.onFileChange(
                                                e,
                                                "requestMaterials"
                                            )
                                        }
                                    />
                                </div>
                                {/* Uploaded Image */}
                                {this.state.requestMaterialsImage && (
                                    <div
                                        className="col-md-4"
                                        style={{ marginLeft: "-80px" }}
                                    >
                                        <div className="image-upload-container">
                                            <span
                                                className="close-icon"
                                                onClick={() => {
                                                    this.setState({
                                                        requestMaterialsImage:
                                                            null,
                                                    });
                                                }}
                                            >
                                                X
                                            </span>
                                            <img
                                                src={
                                                    this.state
                                                        .requestMaterialsImage
                                                }
                                                alt="Request Materials"
                                                className="uploaded-image"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Banner Section */}
                        <div className="col-md-6">
                            <label>Upload Banner</label>
                            <div className="row">
                                {/* File Uploader */}
                                <div className="col-md-8">
                                    <FileUploader
                                        section_title=""
                                        section_title2=""
                                        name="banner"
                                        title="Upload Banner"
                                        desc=""
                                        multiple={false}
                                        onChange={(e) =>
                                            this.onFileChange(e, "banner")
                                        }
                                    />
                                </div>
                                {/* Uploaded Image */}
                                {this.state.bannerImage && (
                                    <div
                                        className="col-md-4"
                                        style={{ marginLeft: "-130px" }}
                                    >
                                        <div className="image-upload-container">
                                            <span
                                                className="close-icon"
                                                onClick={() => {
                                                    this.setState({
                                                        bannerImage: null,
                                                    });
                                                }}
                                            >
                                                X
                                            </span>
                                            <img
                                                style={{ width: "300px" }}
                                                src={this.state.bannerImage}
                                                alt="Banner"
                                                className="uploaded-image"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* CTA text */}
                    <div className="row">
                        <div className="col-md-6">
                            <BsInput
                                label="Enter CTA Button Text"
                                name="ctaText"
                                type="text"
                                required
                                value={this.state.cta_text}
                                placeholder="Enter CTA button text"
                                onChange={(e) =>
                                    this.setState({ cta_text: e.target.value })
                                }
                            />
                            {this.getErrorMessage("cta_text")}
                        </div>
                    </div>
                    {/* Status */}
                    <div className="row">
                        <div className="col-md-6">
                            <SelectComponent
                                name="status"
                                label="Status"
                                required
                                errors={this.state.errors}
                                onChange={this.onInputChange}
                                value={this.state.status}
                            >
                                <option value="0">All</option>
                                <option value="2">Requested</option>
                                <option value="1">Approved</option>
                                <option value="3">Closed</option>
                            </SelectComponent>
                        </div>
                    </div>
                    {/* Submit Button */}
                    <div className="row ">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.submitForm}
                            >
                                Save & Submit
                            </button>
                            <button
                                onClick={this.resetForm}
                                style={{
                                    color: "#DA4C76",
                                    marginLeft: "10px",
                                    fontWeight: "700",
                                    backgroundColor: "white",
                                    border: "none",
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateMaterialRequest;

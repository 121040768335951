import React, { Component } from "react";
import HeadingComponent from "./HeadingComponent";
import "./successStoriesLists.css";
import CampaignService from "../../../services/CampaignService";
import moment from "moment";
import history from "../../../history";
import { sortable } from "react-sortable";

const MaterialService = require("../../../services/MaterialService");

class SuccessStoriesLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            statusFilter: "all", // Added state for status filter
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        CampaignService.fetchData("/backend/user-stories/list?source=materials")
            .then((response) => {
                if (response.status) {
                    this.setState({
                        data: response.data,
                    });
                }
            })
            .catch((error) => {
                console.error("Failed to fetch data:", error);
            });
    }

    updateStatus = (id, status, callback = () => {}) => {
        CampaignService.postData("/backend/user-stories/update-story-status", {
            id: id,
            status: status,
        })
            .then((res) => {
                this.refreshData();
                callback();
            })
            .catch((error) => {
                console.error("Failed to update status:", error);
            });
    };

    handleSortItems = async (items) => {
        let idsInOrder = items.map((item) => item.id);
        await CampaignService.postData(
            "/backend/user-stories/update-story-order",
            {
                orders: idsInOrder,
            }
        ).catch((error) => {
            console.error("Failed to reorder items:", error);
        });
        console.log(idsInOrder);

        this.setState({ data: items });
    };

    handleFilterChange = (event) => {
        this.setState({ statusFilter: event.target.value });
    };

    render() {
        const { data, statusFilter } = this.state;

        // Filter data based on status
        const filteredData =
            statusFilter === "all"
                ? data
                : data.filter((item) => item.status === Number(statusFilter));

        return (
            <div
                className="success-stories-lists-main-container"
                id="succesStoriesLists"
            >
                <HeadingComponent
                    mainTitle="Success stories"
                    path="/material-manager/add-success-story"
                    title="Add success stories"
                />
                <div className="main-container">
                    <div className="main-success-stories-heading">
                        List of success stories
                    </div>
                    <div className="col-md-12 row no-padding">
                        <div className="col-md-2 no-padding row-heading">
                            Submitted On
                        </div>
                        <div className="col-md-7 no-padding row-heading">
                            Submitter and detail
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            {/* Added select dropdown for filtering */}
                            <select
                                className=""
                                value={statusFilter}
                                onChange={this.handleFilterChange}
                                style={{
                                    border: 0,
                                    background: "white",
                                    fontWeight: 600,
                                    color: "#1E1053",
                                }}
                            >
                                <option value="all">All</option>
                                <option value="1">Visible</option>
                                <option value="0">Hide</option>
                            </select>
                        </div>
                        <div className="col-md-1 no-padding row-heading">
                            Edit
                        </div>
                    </div>
                    <div className="col-md-12 row no-padding">
                        <ul
                            style={{
                                listStyle: "none",
                                padding: "0px",
                                margin: "0px",
                                width: "100%",
                            }}
                        >
                            {filteredData.map((list, index) => (
                                <SortableItem
                                    key={list.id}
                                    sortId={index}
                                    onSortItems={this.handleSortItems}
                                    items={filteredData}
                                    list={list}
                                    updateStatus={this.updateStatus}
                                    refreshData={this.refreshData}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default SuccessStoriesLists;

class SuccessStoryItem extends Component {
    render() {
        const { list, updateStatus, refreshData } = this.props;
        return (
            <div className="col-md-12 row no-padding margin-top need-lists-conainer">
                <div className="col-md-2 no-padding">
                    <i
                        className="fa fa-sort"
                        style={{ padding: "2px", cursor: "pointer" }}
                    ></i>
                    {moment(list.createdAt).format("DD MMM yyyy")}
                </div>
                <div className="col-md-7 no-padding">
                    <div>Title: {list.title}</div>
                    <div className="col-md-12 no-padding image-text">
                        <img
                            src={list.Json.bannerImage}
                            className="col-md-4 no-padding success-stories-list-image"
                        />
                        {list.shortDesc}
                    </div>
                </div>
                <select
                    className="col-md-2 select-dropdown"
                    value={list.status}
                    onChange={(event) => {
                        updateStatus(list.id, event.target.value, refreshData);
                    }}
                >
                    <option value="1">Visible</option>
                    <option value="0">Hide</option>
                </select>
                <div className="col-md-1 banner-edit-button">
                    <i
                        className="fas fa-pencil-alt"
                        aria-hidden="true"
                        onClick={() => {
                            history.push(
                                `/material-manager/update-success-story/${list.id}`
                            );
                        }}
                    ></i>
                </div>
            </div>
        );
    }
}

class Item extends Component {
    render() {
        return (
            <li
                {...this.props}
                style={{ listStyle: "none", padding: "0px", margin: "0px" }}
            >
                <SuccessStoryItem {...this.props} />
            </li>
        );
    }
}

const SortableItem = sortable(Item);

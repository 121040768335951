import React, { Fragment, Suspense } from "react";
import { Route } from "react-router-dom";
import LoaderSpinner from "../components/Generic/LoaderSpinner";
import BasicLayout from "./BasicLayout";

const AppRoute = ({
    component: Component,
    layout: Layout = BasicLayout,
    name = "default",
    hideMenu = false,
    config = {},
    componentProps = {},
    ...rest
}) => {
    window.scrollTo(0, 0);
    return (
        <Route
            {...rest}
            render={(props) => (
                <CustomErrorBoundary>
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    backgroundColor: "#FFF",
                                    minHeight: "100vh",
                                }}
                            >
                                <LoaderSpinner />
                            </div>
                        }
                    >
                        <Layout {...props} hideMenu={hideMenu} config={config}>
                            <Component
                                {...(componentProps || {})}
                                name={name}
                                {...props}
                            />
                        </Layout>
                    </Suspense>
                </CustomErrorBoundary>
            )}
        />
    );
};

export default AppRoute;

class CustomErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        if ((process.env.REACT_APP_ENV || "development") !== "development") {
            var Rollbar = require("rollbar");
            let rollbar = new Rollbar({
                accessToken: "1a35441b5e4149389104e2ac87128b21",
                captureUncaught: true,
                captureUnhandledRejections: true,
                environment: process.env.REACT_APP_ENV || "development",
            });
            rollbar.error(error);
        }

        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Fragment>
                    <div>
                        <div className="content bottom-rounded">
                            <div
                                className="container"
                                style={{ paddingTop: "10%" }}
                            >
                                <h3
                                    style={{
                                        color: "#DA4C76",
                                        textAlign: "center",
                                    }}
                                >
                                    OOPS!
                                </h3>
                                <h5
                                    style={{
                                        textAlign: "center",
                                        lineHeight: "200%",
                                    }}
                                >
                                    Something went wrong. <br />
                                    <a href="/">Click here to Refresh</a>
                                </h5>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
        return this.props.children;
    }
}

import * as actions from "../actions";

const initialState = {
    content: {},
};

const cmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_CMS:
            return {
                ...state,
                content: action.content,
            };

        default:
            return {
                ...state,
            };
    }
};

export default cmsReducer;

import Quill from "quill";

//const InlineBlot = Quill.import("blots/block");
const EmbedBlot = Quill.import("blots/block/embed");

class VideoBlot extends EmbedBlot {
    static create(src) {
        const node = super.create(src);
        console.log(src);
        if (src === true) return node;

        const video = document.createElement("video");
        video.setAttribute("width", 320);
        video.setAttribute("height", 240);
        video.setAttribute("controls", true);
        video.setAttribute("src", src);
        const source = document.createElement("source");
        node.setAttribute("dataUrl", src);
        source.setAttribute("src", src);
        source.setAttribute("type", "video/mp4");
        video.appendChild(source);
        node.appendChild(video);
        return node;
    }
    deleteAt(index, length) {
        super.deleteAt(index, length);
        this.cache = {};
    }
    static value(domNode) {
        const { src, custom } = domNode.dataset;
        // console.log(domNode.attributes)
        try {
            return domNode.attributes.dataUrl.value;
        } catch (ex) {
            return "";
        }
    }
}

VideoBlot.blotName = "videoBlot";
VideoBlot.className = "addFile";
VideoBlot.tagName = "div";
Quill.register({ "formats/videoBlot": VideoBlot });

export default VideoBlot;

import React, { Component } from 'react';
import './popup.css';
import ModalWindow from './Modal.js';
import { closePopup } from '../../../helpers/dispatcher';

export default class Popup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showWindow: this.props.showWindow === undefined ? true : this.props.showWindow,
		};
		//showCloseButton
	}

	componentDidMount = () => {
		if (document.getElementById('popupId')) {
			document.getElementById('popupId').focus({ preventScroll: true });
		}
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			...prevState,
			showWindow: nextProps.showWindow === undefined ? true : nextProps.showWindow,
		};
	}

	keyPress = (e) => {
		if (this.props.buttons && this.props.buttons.length > 0 && e.key === 'Escape') {
			closePopup();
		}
	};

	renderButtons() {
		if (!this.props.buttons) return null;

		return (
			<div>
				{this.props.buttons.map((button, index) => {
					return (
						<button key={index} className={button.class} onClick={button.onClick}>
							{button.text}
						</button>
					);
				})}
			</div>
		);
	}

	render() {
		let showWindow = this.state.showWindow;
		let showCloseButton = this.props.showCloseButton === undefined ? true : this.props.showCloseButton;
		if (!showWindow) return null;
		let closeButton = null;
		if (showCloseButton) {
			closeButton = (
				<i
					className="fa fa-times"
					style={{
						position: 'absolute',
						top: '12px',
						right: '5px',
					}}
					onClick={() => {
						this.setState({ showWindow: false });
					}}
				></i>
			);
		}
		return (
			<div
				tabIndex="0"
				onKeyDown={(e) => {
					this.keyPress(e);
				}}
				id="popupId"
			>
				<ModalWindow>
					<div className="popup-container fadeIn">
						<div className="popup_header">
							{this.props.title}
							{closeButton}
						</div>
						<div className="popup_body">{this.props.children}</div>
						<div
							className={
								'popup_buttons ' +
								(this.props.buttonContainerClass ? this.props.buttonContainerClass : '')
							}
						>
							{this.renderButtons()}
						</div>
					</div>
				</ModalWindow>
			</div>
		);
	}
}

import { Component } from "react";
import history from "../../../history";
import "./headingComponent.css";
class HeadingCompnonet extends Component {
    constructor(props) {
        super(props);
    }
    state = {};
    path = () => {
        if (this.props.path) {
            history.push(this.props.path);
        }
    };
    pathTwo = () => {
        if (this.props.pathTwo) {
            history.push(this.props.pathTwo);
        }
    };
    render() {
        return (
            <div className="heading-main-container">
                <div className="sub-heading-container">
                    
                    <div
                        className="heading-component-back-button"
                        onClick={() => {
                            {
                                this.props.backPath
                                    ? history.push(this.props.backPath)
                                    : history.goBack();
                            }
                        }}
                    >
                        <i class="fas fa-long-arrow-alt-left"></i>&nbsp; Back
                    </div>
                    <div className="heading-one">{this.props.mainTitle}</div>
                </div>
                <div className="heading-two-container">
                    <div
                        className={this.props.title ? "heading-two" : ""}
                        onClick={() => {
                            this.path();
                        }}
                    >
                        {this.props.title ? (
                            <>
                                <i class="fas fa-plus"></i> &nbsp;
                                {this.props.title}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <div
                        className={
                            this.props.titleTwo ? "heading-two left-margin" : ""
                        }
                        onClick={() => {
                            this.pathTwo();
                        }}
                    >
                        {this.props.titleTwo ? (
                            <>
                                <i class="fas fa-plus"></i> &nbsp;
                                {this.props.titleTwo}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default HeadingCompnonet;

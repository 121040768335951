import * as actions from "../actions";

const initialState = {
    showMainMenu: false,
    showCauseMenu: false,
};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SHOW_MAIN_MENU:
            return {
                ...state,
                showMainMenu: true,
            };

        case actions.HIDE_MAIN_MENU:
            return {
                ...state,
                showMainMenu: false,
                showCauseMenu: false,
            };

        case actions.SHOW_CAUSE_MENU:
            return {
                ...state,
                showCauseMenu: true,
            };

        case actions.HIDE_CAUSE_MENU:
            return {
                ...state,
                showCauseMenu: false,
            };

        case actions.SHOW_WISHWATALKS_MENU:
            return {
                ...state,
                showWishwaTalksMenu: true,
            };

        case actions.HIDE_WISHWATALKS_MENU:
            return {
                ...state,
                showWishwaTalksMenu: false,
            };

        case actions.SHOWLOGIN:
            return {
                ...state,
                showMainMenu: false,
            };

        default:
            return {
                ...state,
            };
    }
};

export default menuReducer;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import SignInWindow from "../SignIn/SigninWindow";
import SignupWindow from "../SignIn/SignupWindow";
import history from "../../history";
import { trackEvent } from "../../helpers/analytics";
import "./header.css";
import { Link } from "react-router-dom";
import {
    isMobile,
    isTablet,
    isIOS,
    isAndroid,
    browserName,
} from "react-device-detect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationSelector from "../Generic/Popups/LocationSelector";
import Popup from "../Generic/Popups/Popup";
import Alert from "../Generic/Alert";
import SeoEditor from "../Header/SeoEditor";

import {
    showLocationSelector,
    closePopup,
    closeDonateCause,
    hideWishwaTalksMenu,
} from "../../helpers/dispatcher";
import ModalWindow from "../Generic/Popups/Modal";
import DonateMoneyCause from "../Payments/DonateMoneyCause";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogout: false,
            showMenu: this.props.mainMenuStatus,
            showCausesMenu: this.props.causeMenuStatus,
            showWishwaTalksMenu: this.props.wishwaTalksMenuStatus,
            showCausesDMenu: false,
            showWishwaTalksDMenu: false,
        };
        this.renderWeb = this.renderWeb.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.searchText = this.searchText.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.showDonateCause = this.showDonateCause.bind(this);
    }

    static getDerivedStateFromProps(props) {
        return {
            showMenu: props.mainMenuStatus,
            showCausesMenu: props.causeMenuStatus,
            showWishwaTalksMenu: props.wishwaTalksMenuStatus,
        };
    }

    raiseFunds() {
        history.push("/fundraisers/create");
    }

    render() {
        document.body.className = "";
        if (isIOS) {
            document.body.className += "mobile tablet ios";
            return this.renderMobile();
        }
        if (isMobile) {
            document.body.className += " mobile";
            return this.renderMobile();
        }
        if (isAndroid) {
            document.body.className += " mobile";
            return this.renderMobile();
        }
        if (browserName === "MIUI Browser") {
            document.body.className += " mobile";
            return this.renderMobile();
        }
        if (isTablet) {
            document.body.className += " tablet";
            return this.renderMobile();
        }
        document.body.className = "desktop";
        return this.renderWeb();
    }

    onInputchange(event) {
        this.setState({
            errorMessage: "",
            [event.target.name]: event.target.value,
        });
    }

    toggleMenu() {
        trackEvent("open_menu", "main_menu", isMobile ? "Mobile" : "Desktop");
        this.setState({ showMenu: !this.state.showMenu });
    }

    searchText() {
        const searchText = encodeURIComponent(this.state.search_text);
        this.setState({ search_text: "" }, () => {
            history.push("/fundraisers/search?query=" + searchText);
        });
    }

    showPopup() {
        let content = [];
        if (this.props.showPopup) {
            content.push(
                <Popup
                    showWindow={true}
                    showCloseButton={false}
                    title={this.props.showPopup.title || "Alert"}
                    buttonContainerClass="right"
                    buttons={
                        this.props.showPopup.buttons || [
                            {
                                class: "btn btn-primary-inverse",
                                text: "Close",
                                onClick: (ev) => {
                                    if (this.props.showPopup.onClose) {
                                        this.props.showPopup.onClose();
                                    }
                                    closePopup();
                                },
                            },
                        ]
                    }
                >
                    {this.props.showPopup.contentType === "html" ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.showPopup.content,
                            }}
                        />
                    ) : (
                        this.props.showPopup.content || null
                    )}
                </Popup>
            );
        }

        let modalContent = this.showModal();
        if (modalContent) {
            content.push(modalContent);
        }

        return <Fragment>{content}</Fragment>;
    }

    showModal() {
        if (this.props.showModal) {
            return (
                <ModalWindow>
                    {this.props.showModal.content || null}
                </ModalWindow>
            );
        }
        return null;
    }

    showAlert() {
        if (this.props.showAlert) {
            return (
                <Alert
                    title={this.props.showAlert.title || "Title"}
                    description={this.props.showAlert.description}
                    type={this.props.showAlert.type || ""}
                    autoClose={this.props.showAlert.autoClose}
                    onClick={
                        this.props.showAlert.onClick
                            ? this.props.showAlert.onClick
                            : () => {}
                    }
                />
            );
        }
        return null;
    }

    showDonateCause() {
        if (this.props.showDonateCause) {
            return (
                <DonateMoneyCause
                    {...this.props.showDonateCause}
                    onClose={() => {
                        closeDonateCause();
                    }}
                    campaignId={-1}
                />
            );
        }
        return null;
    }

    renderMobile() {
        let mobileMenu = this.state.showMenu ? this.renderMenu() : null;
        let signInWindow = null;
        if (this.props.showLogin) {
            signInWindow = <SignInWindow />;
        }
        if (this.props.showRegister) {
            signInWindow = <SignupWindow />;
        }

        return (
            <header style={{ position: "relative" }}>
                {this.showPopup()}
                {this.showDonateCause()}
                {this.showAlert()}
                <LocationSelector />
                <TopMessage />
                {mobileMenu}
                {signInWindow}
                <div
                    className="flex-row"
                    style={{ justifyContent: "space-between" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            flex: 3,
                            justifyContent: "center",
                            alignContent: "center",
                        }}
                    >
                        <Link to="/">
                            <img
                                src="/images/wishwa-logo.png"
                                alt=""
                                className="img img-fluid wishwa-logo"
                            />
                        </Link>
                    </div>
                    <div
                        className="flex-column"
                        style={{
                            flexGrow: 1,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="loc-selector"
                            style={{
                                justifyContent: "center",
                                alignItems: "center ",
                            }}
                            onClick={() => {
                                showLocationSelector();
                            }}
                        >
                            <span>
                                {this.props.location && this.props.location.city
                                    ? this.props.location.city
                                    : "Hyderabad"}
                                &nbsp;
                            </span>
                            <i className="fas fa-chevron-down"></i>
                        </div>
                    </div>

                    <div
                        className="flex-column"
                        style={{
                            justifyContent: "center",
                            alignItems: "center ",
                            paddingRight: "20px",
                        }}
                    >
                        <i
                            class="fas fa-bars"
                            onClick={this.props.showMainMenu}
                        ></i>
                    </div>
                </div>
                <div
                    style={{
                        color: "#DA4C76",
                        fontSize: "12px",
                        fontWeight: 600,
                        width: "fit-content",
                        margin: "auto",
                    }}
                    onClick={() => {
                        history.push("/jobs");
                    }}
                >
                    We are hiring!
                </div>
            </header>
        );
    }

    renderMenu() {
        let signInButton = (
            <div
                className="signin-mobile-button"
                onClick={this.props.showLoginWindow}
            >
                Sign in / Sign up
            </div>
        );

        if (this.props.authenticated) {
            signInButton = (
                <div className="signin-mobile-button">
                    <div
                        className="profile-block"
                        style={{ position: "relative" }}
                        onClick={() => {
                            history.push("/user");
                        }}
                    >
                        <img src="/images/profile-ph.png" alt="profile" />
                        {localStorage.getItem("username")}
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>
            );
        }

        return (
            <div className="mobileMenu">
                <i
                    class="fas fa-times closeButton"
                    onClick={this.props.hideMainMenu}
                ></i>
                <Link rel="index,follow" to="/">
                    <img
                        src="/images/wishwa-logo.png"
                        alt=""
                        className="img img-fluid menu-small-logo"
                    />
                </Link>
                {(() => {
                    if (this.state.showWishwaTalksMenu) {
                        return (
                            <WishwaTalksMenu
                                showWishwaTalksMenu={
                                    this.state.showWishwaTalksMenu
                                }
                                onClose={() => {
                                    hideWishwaTalksMenu();
                                }}
                            />
                        );
                    } else if (this.state.showCausesMenu) {
                        return (
                            <CausesMenu
                                showCausesMenu={this.state.showCausesMenu}
                                onClose={() => {
                                    this.props.hideCauseMenu();
                                }}
                            />
                        );
                    } else {
                        return (
                            <Fragment>
                                <div id="topbarbox">
                                    <span id="raise_funds">Raise</span>
                                    <span
                                        id="raise_funds_menu"
                                        onClick={this.raiseFunds}
                                    >
                                        Funds
                                    </span>
                                    {
                                        /* <div className="line"></div>

                                    <span id="raise_funds">Offer</span>*/
                                        <span
                                            id="raise_funds_menu"
                                            onClick={() => {
                                                history.push("/materials");
                                            }}
                                        >
                                            Donate Materials
                                        </span>
                                    }
                                </div>

                                <div>
                                    <nav class="nav">
                                        <Link
                                            rel="index,follow"
                                            to="/fundraisers"
                                            className="nav-link"
                                        >
                                            Fundraisers
                                        </Link>
                                        <Link
                                            rel="index,follow"
                                            className="nav-link"
                                            to="#"
                                            onClick={() => {
                                                this.props.showWishwaTalksMenu();
                                            }}
                                        >
                                            WishwaTalks{" "}
                                            <i class="fas fa-chevron-down"></i>
                                        </Link>
                                        {/* <Link rel="index,follow" className="nav-link" to="/fundraisers/ongoing">
											All Fundraisers
										</Link> */}
                                        <Link
                                            rel="index,follow"
                                            to="/celebrate-with-a-cause"
                                            className="nav-link"
                                        >
                                            Celebrate With A Cause
                                        </Link>
                                        <Link
                                            rel="index,follow"
                                            className="nav-link"
                                            to="/materials"
                                        >
                                            Materials
                                        </Link>
                                        <Link
                                            rel="index,follow"
                                            className="nav-link"
                                            to="#"
                                            onClick={() => {
                                                this.props.showCauseMenu();
                                            }}
                                        >
                                            View Causes{" "}
                                            <i class="fas fa-chevron-down"></i>
                                        </Link>
                                        <Link
                                            rel="index,follow"
                                            className="nav-link"
                                            to="/blogs"
                                        >
                                            Blogs
                                        </Link>

                                        <Link
                                            rel="index,follow"
                                            className="nav-link"
                                            to="/pages/about-us"
                                        >
                                            About Us
                                        </Link>
                                        {/*<Link rel="index,follow"  className="nav-link" to="/coming-soon">Contribute Materials</Link>
<Link rel="index,follow"  className="nav-link" to="/coming-soon">Discuss Issues</Link>
<Link rel="index,follow"  className="nav-link" to="/coming-soon">Vote Issues/Solutions</Link>
<Link rel="index,follow"  className="nav-link" to="/coming-soon">Contribute To Projects</Link> */}
                                    </nav>
                                </div>
                                {signInButton}
                            </Fragment>
                        );
                    }
                })()}
            </div>
        );
    }

    renderWeb() {
        let signInWindow = null;
        if (this.props.showLogin) {
            signInWindow = <SignInWindow />;
        }
        if (this.props.showRegister) {
            signInWindow = <SignupWindow />;
        }
        let dropmenu = localStorage.getItem("dropmenu");
        if (dropmenu) {
            dropmenu = JSON.parse(dropmenu);
        } else {
            dropmenu = [];
        }
        let logoutButton = (
            <div
                className="fadeIn user-dropdown"
                onPointerLeave={() => {
                    this.setState({ showLogout: false });
                }}
            >
                <ul>
                    {dropmenu.map((item, idx) => {
                        return (
                            <li key={`menuItem_${idx}`}>
                                <Link rel="index,follow" to={item.target}>
                                    {item.name}
                                </Link>
                            </li>
                        );
                    })}

                    <li onClick={this.props.doLogout}>
                        <a href="#">Logout</a>
                    </li>
                </ul>
                <div className="border-bot"></div>
            </div>
        );
        let signin_block = (
            <p id="login_anchor" onClick={this.props.showLoginWindow}>
                SIGN IN / UP
            </p>
        );
        if (this.props.authenticated) {
            signin_block = (
                <div
                    className="profile-block"
                    style={{ position: "relative" }}
                    onClick={() => {
                        this.setState({ showLogout: !this.state.showLogout });
                    }}
                >
                    {this.state.showLogout ? logoutButton : null}
                    <img src="/images/profile-ph.png" alt="profile" />
                    <span>{localStorage.getItem("username")}</span>
                    <i className="fas fa-chevron-down"></i>
                </div>
            );
        }

        return (
            <header>
                {this.showPopup()}
                {this.showDonateCause()}
                {this.showAlert()}
                <SeoEditor />
                <LocationSelector />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                <TopMessage />
                {signInWindow}
                <div className="container">
                    <div className="flex-row header-bar">
                        <div>
                            <Link rel="index,follow" to="/">
                                <img
                                    src={`/images/${
                                        process.env.REACT_APP_ENV === "testing"
                                            ? "wishwa-logo-grey.png"
                                            : "wishwa-logo.png"
                                    }`}
                                    alt=""
                                    className="img img-fluid"
                                />
                            </Link>
                        </div>
                        <div>
                            <div
                                className="loc-selector"
                                onClick={() => {
                                    showLocationSelector();
                                }}
                            >
                                <span>
                                    {this.props.location &&
                                    this.props.location.city
                                        ? this.props.location.city
                                        : "Hyderabad"}
                                    &nbsp;
                                </span>
                                <i className="fas fa-chevron-down"></i>
                            </div>
                        </div>
                        {/* <div>
                            <a
                                target="_blank"
                                href="https://wa.me/+917331184478?text=Hello%20Wishwa"
                            >
                                <img
                                    src="/images/whatsapp-chat-with-us.png"
                                    alt=""
                                    style={{
                                        maxHeight: "50px",
                                        maxWidth: "200px",
                                    }}
                                />
                            </a>
                        </div> */}
                        <div
                            style={{
                                maxWidth: "130px",
                                color: "#DA4C76",
                                fontSize: "14px",
                                fontWeight: 600,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                history.push("/jobs");
                            }}
                        >
                            We are hiring!
                        </div>
                        <div>
                            <div className="align-items-center" id="search">
                                <div class="input-group mb-3">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="search_text"
                                        class="form-control"
                                        placeholder="Search Fundraisers"
                                        value={this.state.search_text}
                                        onChange={this.onInputchange}
                                        onKeyUp={(ev) => {
                                            console.log(ev);
                                            if (ev.nativeEvent.keyCode == 13) {
                                                console.log("enter pressed");
                                                this.searchText();
                                            }
                                        }}
                                    />
                                    <div class="input-group-append">
                                        <i
                                            className="fas fa-search"
                                            onClick={this.searchText}
                                            style={{
                                                cursor: "pointer",
                                                padding: "5px",
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div id="topbarbox">
                                <span id="raise_funds">Raise</span>
                                <span
                                    id="raise_funds_menu"
                                    onClick={this.raiseFunds}
                                >
                                    Funds
                                </span>

                                <>
                                    <div className="line"></div>
                                    <span id="raise_funds">Donate</span>
                                    <span
                                        id="raise_funds_menu"
                                        onClick={() => {
                                            history.push("/materials");
                                        }}
                                    >
                                        Materials
                                    </span>
                                </>
                            </div>
                        </div>
                        <div>{signin_block}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" id="main-navigation">
                            <nav className="nav">
                                <Link
                                    rel="index,follow"
                                    to="/fundraisers"
                                    className="nav-link"
                                >
                                    Fundraisers
                                </Link>
                                <Link
                                    rel="index,follow"
                                    className="nav-link"
                                    to="#"
                                    onClick={() => {
                                        this.setState({
                                            showWishwaTalksDMenu:
                                                !this.state
                                                    .showWishwaTalksDMenu,
                                        });
                                    }}
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <WishwaTalksMenu
                                        showWishwaTalksMenu={
                                            this.state.showWishwaTalksDMenu
                                        }
                                        onClose={() => {
                                            this.setState({
                                                showWishwaTalksDMenu: false,
                                            });
                                        }}
                                    />
                                    WishwaTalks{" "}
                                    <i class="fas fa-chevron-down"></i>
                                </Link>
                                <Link
                                    rel="index,follow"
                                    to="/celebrate-with-a-cause"
                                    className="nav-link"
                                >
                                    Celebrate With A Cause
                                </Link>
                                <Link
                                    rel="index,follow"
                                    className="nav-link"
                                    to="/materials"
                                >
                                    Materials
                                </Link>
                                <Link
                                    rel="index,follow"
                                    className="nav-link"
                                    to="#"
                                    onClick={() => {
                                        this.setState({
                                            showCausesDMenu:
                                                !this.state.showCausesDMenu,
                                        });
                                    }}
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <CausesMenu
                                        showCausesMenu={
                                            this.state.showCausesDMenu
                                        }
                                        onClose={() => {
                                            this.setState({
                                                showCausesDMenu: false,
                                            });
                                        }}
                                    />
                                    View Causes{" "}
                                    <i class="fas fa-chevron-down"></i>
                                </Link>
                                <Link
                                    rel="index,follow"
                                    className="nav-link"
                                    to="/blogs"
                                >
                                    Blogs
                                </Link>
                                <Link
                                    rel="index,follow"
                                    className="nav-link"
                                    to="/pages/about-us"
                                >
                                    About Us
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        authenticated: state.root.authenticated,
        showLogin: state.root.showLogin,
        showRegister: state.root.showRegister,
        location: state.root.location,
        showPopup: state.root.showPopup,
        showModal: state.root.showModal,
        showDonateCause: state.root.showDonateCause,
        mainMenuStatus: state.menu.showMainMenu,
        causeMenuStatus: state.menu.showCauseMenu,
        wishwaTalksMenuStatus: state.menu.showWishwaTalksMenu,
        showAlert: state.root.showAlert,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showMainMenu: () => dispatch({ type: actions.SHOW_MAIN_MENU }),
        hideMainMenu: () => dispatch({ type: actions.HIDE_MAIN_MENU }),
        showCauseMenu: () => dispatch({ type: actions.SHOW_CAUSE_MENU }),
        hideCauseMenu: () => dispatch({ type: actions.HIDE_CAUSE_MENU }),
        showWishwaTalksMenu: () =>
            dispatch({ type: actions.SHOW_WISHWATALKS_MENU }),
        hideWishwaTalksMenu: () =>
            dispatch({ type: actions.HIDE_WISHWATALKS_MENU }),
        doAuthenticate: () => dispatch({ type: actions.AUTHENTICATED }),
        doLogout: () => {
            dispatch({ type: actions.LOGOUT });
            history.push("/");
        },
        showLoginWindow: () => dispatch({ type: actions.SHOWLOGIN }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);

class TopMessage extends Component {
    render() {
        let topBarMessage = localStorage.getItem("topBarMessage");
        if (!topBarMessage) return null;

        localStorage.removeItem("topBarMessage");

        return (
            <div className="orgMessage">
                <i className="fas fa-check-circle"></i> {topBarMessage}
            </div>
        );
    }
}

class CausesMenu extends Component {
    render() {
        if (this.props.showCausesMenu) {
            return (
                <div
                    className="causesMenu"
                    onMouseLeave={() => {
                        setTimeout(this.props.onClose, 300);
                    }}
                >
                    <ul>
                        {(() => {
                            if (isMobile) {
                                return (
                                    <li
                                        onClick={this.props.onClose}
                                        style={{
                                            color: "#DA4C76",
                                            padding: "5px",
                                        }}
                                    >
                                        <i className="fa fa-chevron-left "></i>{" "}
                                        BACK TO MAIN MENU
                                    </li>
                                );
                            }
                        })()}
                        <li>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/cause/health-family-welfare"
                            >
                                <img src="/images/Healthcare.png" /> Health &
                                Family Welfare
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/cause/art-culture"
                            >
                                <img src="/images/Union.png" /> Art & Culture
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/cause/ecology-environment"
                            >
                                <img src="/images/ecology.png" /> Ecology &
                                Environment
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/cause/community-development"
                            >
                                <img src="/images/Community Development.png" />{" "}
                                Community Development
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/cause/human-rights-social-welfare"
                            >
                                <img src="/images/Human Rights.png" /> Human
                                Rights & Social Welfare
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/cause/education"
                            >
                                <img src="/images/edu.png" /> Education
                            </Link>
                        </li>
                        {/* <li>
							<Link rel="index,follow"  className="nav-link" to="/cause/employment-entrepreneurship">
								<img src="/images/e-e.png" /> Employment & Entrepreneurship
							</Link>
						</li>
						<li>
							<Link rel="index,follow"  className="nav-link" to="/cause/disaster-management">
								<img src="/images/Disaster Management.png" /> Disaster Management
							</Link>
						</li>

						<li>
							<Link rel="index,follow"  className="nav-link" to="/cause/science-technology">
								<img src="/images/science.png" /> Science & Technology
							</Link>
						</li>
						<li>
							<Link rel="index,follow"  className="nav-link" to="/cause/policy-law-advocacy">
								<img src="/images/Compound Path.png" /> Policy, Law & Advocacy
							</Link>
						</li>
						<li>
							<Link rel="index,follow"  className="nav-link" to="/cause/sports-physical-wellbeing">
								<img src="/images/Sports.png" /> Sports & Physical Wellbeing
							</Link>
						</li>
						<li>
							<Link rel="index,follow"  className="nav-link" to="/cause/tourism-heritage">
								<img src="/images/heritage.png" /> Tourism & Heritage
							</Link>
						</li> */}
                    </ul>
                </div>
            );
        } else return null;
    }
}

class WishwaTalksMenu extends Component {
    render() {
        if (this.props.showWishwaTalksMenu) {
            return (
                <div
                    className="causesMenu"
                    onMouseLeave={() => {
                        setTimeout(this.props.onClose, 300);
                    }}
                >
                    <ul>
                        {(() => {
                            if (isMobile) {
                                return (
                                    <li
                                        onClick={this.props.onClose}
                                        style={{
                                            color: "#DA4C76",
                                            padding: "5px",
                                        }}
                                    >
                                        <i className="fa fa-chevron-left "></i>{" "}
                                        BACK TO MAIN MENU
                                    </li>
                                );
                            }
                        })()}
                        <li>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/WishwaTalks"
                            >
                                WishwaTalks Home
                            </Link>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/WishwaTalks/all"
                            >
                                All WishwaTalks
                            </Link>
                            {/* <Link
                                rel="index,follow"
                                className="nav-link disabled"
                                to="#"
                                onClick={(e) => e.preventDefault()}
                            >
                                Competitions
                            </Link>
                            <Link
                                rel="index,follow"
                                className="nav-link disabled"
                                to="#"
                                onClick={(e) => e.preventDefault()}
                            >
                                Camps
                            </Link> */}
                            {/* <Link rel="index,follow" className="nav-link" to="/WishwaTalks#surveyId">
								Surveys
							</Link> */}
                            <a
                                href="/WishwaTalks#surveyId"
                                className="nav-link"
                                onClick={() => {
                                    window.location.href =
                                        "/WishwaTalks#surveyId";
                                }}
                            >
                                Surveys
                            </a>
                            {/* <Link rel="index,follow" className="nav-link" to="/cause/health-family-welfare">
								Testimonials
							</Link> */}
                            <a
                                href="/WishwaTalks#testimonyId"
                                className="nav-link"
                                onClick={() => {
                                    window.location.href =
                                        "/WishwaTalks#testimonyId";
                                }}
                            >
                                Testimonials
                            </a>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/WishwaTalks/subscribe"
                            >
                                Subscribe
                            </Link>
                            <Link
                                rel="index,follow"
                                className="nav-link"
                                to="/WishwaTalks/all?type=past"
                            >
                                Past Events
                            </Link>
                        </li>
                    </ul>
                </div>
            );
        } else return null;
    }
}

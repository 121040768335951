import moment from "moment";
import { React, Component } from "react";
import { toast } from "react-toastify";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
import "./needHelpLists.css";
const MaterialService = require("../../../services/MaterialService");
class CreateBanner extends Component {
    constructor(props) {
        super(props);
    }
    state = { CreateBanner: [] };
    componentDidMount = () => {
        this.fetchData();
    };
    fetchData = () => {
        MaterialService.fetchData("/v2/manager/banner/list").then((res) => {
            if (res.status) {
                this.setState({
                    CreateBanner: res.data,
                    status: res.data.status,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    onInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (value !== this.state.status) {
            this.setState({ [name]: value }, () => {
               // this.updateStatus();
            });
        }
    };
    updateStatus(id,status){
        console.log(id,status)
        this.setState({CreateBanner:[]})

        MaterialService.postData("/v2/manager/banner/update-status",{
            id:id,
            status:status
        }).then((res) => {
            if (res.status) {
                this.fetchData();
            } else {
                
            }
        });

       
    }
    render() {
        return (
            <div id="needHelplists" className="need-help-list-main-container">
                <HeadingCompnonet
                    mainTitle="Materials Banner"
                    title="ADD Materials banner"
                    path="/material-manager/add-material-banners"
                />
                <div className="main-container">
                    <div className="main-need-help-heading">Banners list</div>
                    <div className="col-md-12 row no-padding">
                        <div className="col-md-2 no-padding row-heading"></div>
                        <div className="col-md-2 no-padding row-heading">
                            All Banners
                        </div>
                        <div className="col-md-5 no-padding row-heading">
                            Alt Tag{" "}
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Status<i class="fas fa-chevron-down"></i>
                        </div>
                        <div className="col-md-1 no-padding row-heading">
                            Edit
                        </div>
                    </div>
                    <div className="col-md-12 row no-padding">
                        {(() => {
                            if (this.state.CreateBanner.length > 0) {
                                console.log(this.state.CreateBanner);
                                return (
                                    <>
                                        {" "}
                                        {this.state.CreateBanner.map(
                                            (lists) => {
                                                return (
                                                    <div className="col-md-12 row no-padding margin-top need-help-details-contents">
                                                        <div className="col-md-2 no-padding">
                                                            <i class="fas fa-bars"></i>
                                                        </div>
                                                        <div className="col-md-2 no-padding ">
                                                            <img
                                                                // src="/images/Rectangle.png"
                                                                src={`https://${lists.bannerInfo.image.content.storageURL}`}
                                                                className="col-md-12 no-padding need-list-image"
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            {
                                                                lists.bannerInfo
                                                                    .image.alt
                                                            }
                                                        </div>
                                                        <select
                                                            className="col-md-2 select-dropdown  "
                                                            name="status"
                                                            value={lists.status}
                                                            onChange={
                                                                (event)=>{
                                                                    this.updateStatus(lists.id,event.target.value)
                                                                }
                                                            }
                                                        >
                                                            <option value="1">
                                                                Visible
                                                            </option>
                                                            <option value="0">
                                                                Hidden
                                                            </option>
                                                            <option value="2">
                                                                Delete
                                                            </option>
                                                        </select>
                                                        <div className="col-md-1 banner-edit-button">
                                                            <i
                                                                class="fas fa-pencil-alt"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    history.push(
                                                                        `/material-manager/edit-banner/${lists.id}`
                                                                    );
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </>
                                );
                            } else {
                                return (
                                    <div
                                        className="alert alert-warning"
                                        style={{
                                            width: "max-content",
                                            height: "50px",
                                            textAlign: "center",
                                            margin: "auto",
                                        }}
                                    >
                                        No Data Found
                                    </div>
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateBanner;

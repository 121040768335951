import "./App.css";

import React, { Component } from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AppRoute from "./routes/AppRoute";
import MainLayoutRoute from "./routes/MainLayout";
import UserLayout from "./routes/UserLayout";

import history from "./history";

import UserService from "./services/UserService";
import { updateCMS } from "./helpers/dispatcher";
import MaterialManager from "./containers/Backend/MaterialManager/MaterialManager";

const HomePage = React.lazy(() => import("./containers/HomePage/HomePage"));

const OrgSignup = React.lazy(() => import("./containers/SignUp/OrgSignup"));
const VerifyToken = React.lazy(() =>
    import("./containers/HomePage/VerifyToken")
);

const CauseHome = React.lazy(() => import("./containers/Causes/HomePage"));

const CampaignRoutes = React.lazy(() =>
    import("./containers/Campaign/CampaignRoutes")
);

const Reviewer = React.lazy(() =>
    import("./containers/Backend/Reviewer/Reviewer")
);
const Approver = React.lazy(() =>
    import("./containers/Backend/Approver/Reviewer")
);
const CampaignManager = React.lazy(() =>
    import("./containers/Backend/CampaignManager/CampaignManager")
);
const EightyG = React.lazy(() => import("./containers/Campaign/80GRequest"));

const Dashboard = React.lazy(() => import("./containers/User/Dashboard"));

const SuperAdminDashboard = React.lazy(() =>
    import("./containers/Backend/SuperAdmin/Dashboard")
);

const ITAdminDashboard = React.lazy(() =>
    import("./containers/Backend/ITAdmin/Dashboard")
);

const FinanceDashboard = React.lazy(() =>
    import("./containers/Backend/Finance/Dashboard")
);

const OperationsDashboard = React.lazy(() =>
    import("./containers/Backend/OperationsManager/Dashboard")
);

const MarketingDashboard = React.lazy(() =>
    import("./containers/Backend/MarketingManager/Dashboard")
);

const GeneralManageScreens = React.lazy(() =>
    import("./containers/Backend/Manage/Dashboard")
);

const EventManager = React.lazy(() =>
    import("./containers/Backend/EventManager/EventManager")
);

const Pages = React.lazy(() => import("./containers/Pages/Pages"));

const ComingSoon = React.lazy(() => import("./containers/HomePage/ComingSoon"));
const SocialLogin = React.lazy(() => import("./containers/SignIn/SocialLogin"));
const Materials = React.lazy(() => import("./containers/Materials/Materials"));
const DonateMaterial = React.lazy(() =>
    import("./containers/DonateMaterial/DonateMaterial")
);
const Volunteer = React.lazy(() => import("./containers/Volunteer/Volunteer"));
const MaterialRequester = React.lazy(() =>
    import("./containers/MaterialRequester/MaterialRequester")
);

const Celebrate = React.lazy(() => import("./containers/Celebrate/Celebrate"));
const WishwaTalks = React.lazy(() =>
    import("./containers/WishwaTalks/WishwaTalks")
);
const Hr = React.lazy(() => import("./containers/Backend/Hr/Hr"));
const JobRoutes = React.lazy(() => import("./containers/Jobs/JobRoutes"));
const BlogRoutes = React.lazy(() => import("./containers/Blogs/BlogRoutes"));
const Donation = React.lazy(() => import("./components/Payments/Donation"));

const CelebrationsManagerDashboard = React.lazy(() =>
    import("./containers/Backend/CelebrationsManager/DashboardRouter")
);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        UserService.fetchData("/cms/get-content").then((response) => {
            updateCMS(response.data);
        });
    }

    render() {
        return (
            <div>
                <Router history={history}>
                    <Switch>
                        <AppRoute
                            exact
                            path="/"
                            component={HomePage}
                            layout={MainLayoutRoute}
                        />
                        <AppRoute
                            exact
                            path="/campaigns/donations/80-g-request/:donationId/:hash"
                            component={EightyG}
                            layout={MainLayoutRoute}
                        />
                        <AppRoute
                            path="/social-login"
                            component={SocialLogin}
                            layout={MainLayoutRoute}
                        />
                        {/*List of Signin / Register Routes */}
                        <AppRoute
                            path="/register-organisation"
                            component={OrgSignup}
                            layout={MainLayoutRoute}
                        />
                        <AppRoute
                            path="/verify/:type/:token"
                            component={VerifyToken}
                            layout={MainLayoutRoute}
                        />
                        {/* /*End*/}
                        <AppRoute
                            path="/cause/:id"
                            component={CauseHome}
                            layout={MainLayoutRoute}
                        />
                        {/* /*List of Campaignoutes */}
                        <AppRoute
                            path="/fundraisers"
                            component={CampaignRoutes}
                            layout={MainLayoutRoute}
                        />
                        {/*End*/}
                        {/* /*List of ReviewerRoutes */}
                        <AppRoute
                            path="/reviewer"
                            hideMenu={true}
                            component={Reviewer}
                            layout={UserLayout}
                        />
                        {/* /*End*/
                        /*List of ApproverRoutes */}
                        <AppRoute
                            path="/approver"
                            hideMenu={true}
                            component={Approver}
                            layout={UserLayout}
                        />
                        {/* /*End*/}
                        {/*List of CampaignManageRoutes */}
                        <AppRoute
                            path="/manager"
                            hideMenu={true}
                            component={CampaignManager}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of UserRoutes */}
                        <AppRoute
                            path="/user"
                            component={Dashboard}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of SuperAdminRoutes */}
                        <AppRoute
                            path="/super-admin"
                            hideMenu={true}
                            component={SuperAdminDashboard}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of ITAdminRoutes */}
                        <AppRoute
                            path="/it-admin"
                            hideMenu={true}
                            component={ITAdminDashboard}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of Operations Routes */}
                        <AppRoute
                            path="/operations"
                            hideMenu={true}
                            component={OperationsDashboard}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of Marketing Routes */}
                        <AppRoute
                            path="/marketing"
                            hideMenu={true}
                            component={MarketingDashboard}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of Celebrations manager Routes */}
                        <AppRoute
                            path="/celebrations-manager"
                            hideMenu={true}
                            component={CelebrationsManagerDashboard}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of Backend Manage Routes */}
                        <AppRoute
                            path="/manage"
                            hideMenu={true}
                            component={GeneralManageScreens}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*List of EventManageRoutes */}
                        <AppRoute
                            path="/event-manager"
                            hideMenu={true}
                            component={EventManager}
                            layout={UserLayout}
                        />
                        <AppRoute
                            path="/material-manager"
                            hideMenu={true}
                            component={MaterialManager}
                            layout={UserLayout}
                        />
                        {/* <AppRoute
                            path="/donate-material"
                            hideMenu={true}
                            component={DonateMaterial}
                            layout={UserLayout}
                        /> */}
                        {/* <AppRoute
                            path="/material-requester"
                            hideMenu={true}
                            component={MaterialRequester}
                            layout={UserLayout}
                        /> */}

                        {/*End*/}
                        {/* /*List of FinanceRoutes */}
                        <AppRoute
                            path="/finance"
                            hideMenu={true}
                            component={FinanceDashboard}
                            layout={UserLayout}
                        />
                        {/* /*End*/
                        /*List of Pages */}
                        <AppRoute
                            path="/pages"
                            hideMenu={false}
                            component={Pages}
                            layout={UserLayout}
                        />
                        {/* /*End*/}
                        <AppRoute
                            path="/coming-soon"
                            component={ComingSoon}
                            layout={MainLayoutRoute}
                        />
                        <AppRoute
                            path="/materials"
                            hideMenu={false}
                            component={Materials}
                            layout={UserLayout}
                        />
                        <AppRoute
                            path="/materials"
                            hideMenu={false}
                            component={Materials}
                            layout={UserLayout}
                        />
                        <AppRoute
                            path="/celebrate-with-a-cause"
                            hideMenu={false}
                            component={Celebrate}
                            layout={UserLayout}
                        />
                        <AppRoute
                            path="/WishwaTalks"
                            hideMenu={false}
                            component={WishwaTalks}
                            layout={UserLayout}
                        />
                        {/*HR Route */}
                        <AppRoute
                            path="/hr"
                            hideMenu={true}
                            component={Hr}
                            layout={UserLayout}
                        />
                        {/*End*/}
                        {/*Jobs Route */}
                        <AppRoute
                            path="/jobs"
                            hideMenu={true}
                            component={JobRoutes}
                            layout={MainLayoutRoute}
                        />
                        {/*End*/}
                        {/*Blogs Route */}
                        <AppRoute
                            path="/blogs"
                            hideMenu={true}
                            component={BlogRoutes}
                            layout={MainLayoutRoute}
                        />
                        {/*End*/}
                        {/*Donation Route */}
                        <AppRoute
                            path="/donate/:slug/payment"
                            hideMenu={true}
                            component={Donation}
                            layout={MainLayoutRoute}
                        />
                        <AppRoute
                            path="/donate-cause"
                            hideMenu={true}
                            component={Donation}
                            componentProps={{ type: "cause" }}
                            layout={MainLayoutRoute}
                        />
                        {/*End*/}
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { Component } from "react";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
// import "./materialRequestsLists.css";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import "./donorRequest.css";

const MaterialService = require("../../../services/MaterialService");
const mockData = {
    status: true,
    message: "retrived all the materials donations for ngo",
    data: [
        {
            id: 1,
            donatedOn: "2024-09-19T04:56:04.214Z",
            miscInfo: {
                name: "Rohan Sharma",
                email: "rohan.sharma@example.com",
                mobile: "+91 98765 43210",
                organizationName: "nbjbjbjbJBJB",
            },
            materials: [
                { category: "Books", quantity: "10" },
                { category: "Clothes", quantity: "5" },
            ],
            status: 0,
        },
    ],
};
class DonorRequestLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            materialRequestsLists: [],
            filteredRequests: [],
            selectedStatus: "all",
            showStatusOptions: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/ngo-material-donation").then(
            (res) => {
                if (res.status) {
                    this.setState({
                        materialRequestsLists: res.data,
                        filteredRequests: res.data,
                        downloadKey: res.meta.downloadKey,
                        loaded: true,
                    });
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        );
    };

    // fetchData = () => {
    //     // Simulate API call
    //     setTimeout(() => {
    //         const res = mockData;
    //         if (res.status) {
    //             this.setState({
    //                 materialRequestsLists: res.data,
    //                 filteredRequests: res.data,
    //                 loaded: true,
    //             });
    //         } else {
    //             toast.error(res.message || "Something Went Wrong", {
    //                 position: "top-right",
    //                 autoClose: 2000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //             });
    //         }
    //     }, 1000);
    // };

    handleStatusFilter = (status) => {
        const filteredRequests =
            status === "all"
                ? this.state.materialRequestsLists
                : this.state.materialRequestsLists.filter(
                      (request) => request.status === parseInt(status)
                  );

        this.setState({
            selectedStatus: status,
            filteredRequests,
            showStatusOptions: false,
        });
    };

    toggleStatusOptions = () => {
        this.setState((prevState) => ({
            showStatusOptions: !prevState.showStatusOptions,
        }));
    };

    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/material-requests/status/" + id, {
            id: id,
            status: status,
        }).then((res) => {
            callback();
        });
    };

    handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(
            this.state.filteredRequests.map((request) => ({
                RequestedOn: moment(request.createdAt).format("DD MMM YYYY"),
                FullName: request.miscInfo.primary_name || "",
                Email: request.miscInfo.email || "",
                Mobile: request.miscInfo.mobile_no || "",
                OrgName: request.miscInfo.org_name || "",
                TypeOfDonation: request.materials
                    .map((m) => m.category)
                    .join(", "),
                Quantity: request.materials.map((m) => m.quantity).join(", "),
                Status: this.getStatusText(request.status),
            }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Material Requests");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "material_requests.xlsx"
        );
    };

    getStatusText = (status) => {
        switch (status) {
            case 0:
                return "Requested";
            case 1:
                return "Approved";
            case 2:
                return "Requested Canceled";
            default:
                return "";
        }
    };

    render() {
        return (
            <div className="main-container" id="donationRequestsLists">
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <HeadingCompnonet
                            style={{ marginTop: "30px", padding: "10px" }}
                            mainTitle="Material Requests- Donor details"
                        />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Link to="/material-manager/ngo-requests-list">
                                <button
                                    style={{
                                        width: "230px",
                                        padding: "4px 10px",
                                        height: "30px",
                                        borderRadius: "17px",
                                        cursor: "pointer",
                                    }}
                                >
                                    View Requester List
                                </button>
                            </Link>
                            <Link to="/material-manager/ngo-requests-list/create-requests">
                                <button
                                    style={{
                                        width: "280px",
                                        padding: "4px 10px",
                                        height: "30px",
                                        borderRadius: "17px",
                                        cursor: "pointer",
                                    }}
                                >
                                    + Create Materials Request
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className="row-heading">
                        <div className="scrollable-container">
                            <table className="donation-table">
                                <thead>
                                    <tr>
                                        <th>Requested on</th>
                                        <th>Full Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>ORG Name</th>
                                        <th>Type of Donation</th>
                                        <th>Quantity</th>
                                        <th>Volunteer</th>
                                        <th>
                                            <div className="status-container col-md-2 padding-no">
                                                <div className="status-label">
                                                    Status
                                                </div>
                                                <div
                                                    className="status-dropdown"
                                                    onClick={
                                                        this.toggleStatusOptions
                                                    }
                                                >
                                                    <i
                                                        className={`fas fa-chevron-down dropdown-icon ${
                                                            this.state
                                                                .showStatusOptions
                                                                ? "rotate"
                                                                : ""
                                                        }`}
                                                    ></i>
                                                </div>
                                                {this.state
                                                    .showStatusOptions && (
                                                    <ul className="status-options">
                                                        <li
                                                            onClick={() => {
                                                                this.handleStatusFilter(
                                                                    "all"
                                                                );
                                                                this.toggleStatusOptions();
                                                            }}
                                                        >
                                                            All
                                                        </li>
                                                        <li
                                                            onClick={() => {
                                                                this.handleStatusFilter(
                                                                    "0"
                                                                );
                                                                this.toggleStatusOptions();
                                                            }}
                                                        >
                                                            Requested
                                                        </li>
                                                        <li
                                                            onClick={() => {
                                                                this.handleStatusFilter(
                                                                    "1"
                                                                );
                                                                this.toggleStatusOptions();
                                                            }}
                                                        >
                                                            Approved
                                                        </li>
                                                        <li
                                                            onClick={() => {
                                                                this.handleStatusFilter(
                                                                    "2"
                                                                );
                                                                this.toggleStatusOptions();
                                                            }}
                                                        >
                                                            Requested Canceled
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        </th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loaded ? (
                                        this.state.filteredRequests.map(
                                            (details) => (
                                                <tr
                                                    key={`materialre${details.id}`}
                                                >
                                                    <td>
                                                        {moment(
                                                            details.donatedOn
                                                        ).format("DD MMM YYYY")}
                                                    </td>
                                                    <td>
                                                        {details.name || ""}
                                                    </td>
                                                    <td>
                                                        <a
                                                            href={`mailto:${
                                                                details.email ||
                                                                ""
                                                            }`}
                                                        >
                                                            {details.email ||
                                                                ""}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a
                                                            href={`tel:${
                                                                details.mobile ||
                                                                ""
                                                            }`}
                                                        >
                                                            {details.mobile ||
                                                                ""}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {details.organizationName ||
                                                            ""}
                                                    </td>
                                                    <td>
                                                        {
                                                            details
                                                                .typeOfDonation
                                                                .category
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            details
                                                                .typeOfDonation
                                                                .quantity
                                                        }
                                                    </td>
                                                    <td>
                                                        {details.volunteerData
                                                            ? details
                                                                  .volunteerData
                                                                  .name
                                                            : "Assign volunteer"}
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="select-dropdown"
                                                            onChange={(ev) =>
                                                                this.updateStatus(
                                                                    details.id,
                                                                    ev.target
                                                                        .value,
                                                                    this
                                                                        .fetchData
                                                                )
                                                            }
                                                            value={
                                                                details.status ||
                                                                0
                                                            }
                                                        >
                                                            <option value={0}>
                                                                Requested
                                                            </option>
                                                            <option value={1}>
                                                                Approved
                                                            </option>
                                                            <option value={2}>
                                                                Requested
                                                                Canceled
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <i
                                                            className="fa fa-pencil edit-icon"
                                                            onClick={() => {
                                                                history.push(
                                                                    "/material-manager/material-requests-details/" +
                                                                        details.ngo_req_id
                                                                );
                                                            }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan="9">
                                                <LoaderSpinner />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="banners-buttons">
                        <div
                            className="save-button"
                            // onClick={this.handleDownload}
                        >
                            Cancel
                        </div>
                        <div
                            className="save-button"
                            onClick={this.handleDownload}
                        >
                            Download
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DonorRequestLists;

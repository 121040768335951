import * as actions from "../actions";

let authenticated = false;
if (localStorage.getItem("authenticated") === "true") {
    //do a token validation api call here
    authenticated = true;
}

let location = localStorage.getItem("location") || '{"city":"Hyderabad"}';

const initialState = {
    authenticated: authenticated,
    showLogin: false,
    showLocationSelector: false,
    noSocialUser: false,
    username: localStorage.getItem("username"),
    showPopup: null,
    location: location ? JSON.parse(location) : null,
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_USER:
            return {
                ...state,
                user: {
                    ...(state.user || {}),
                    ...action.user,
                },
            };
        case actions.LOGOUT:
            localStorage.setItem("authenticated", false);
            localStorage.setItem("accessToken", "");
            localStorage.setItem("username", "");
            localStorage.removeItem("usermenu");
            localStorage.removeItem("dropmenu");
            localStorage.clear();
            return {
                ...state,
                authenticated: false,
                accessToken: null,
                user: null,
            };
        case actions.UPDATE_LOGGED_IN_USER:
            localStorage.setItem("authenticated", true);
            localStorage.setItem("accessToken", action.user.accessToken);
            localStorage.setItem("username", action.user.fullname);
            localStorage.setItem("user_id", action.user.id);
            localStorage.setItem(
                "dropmenu",
                JSON.stringify(action.user.dropmenu)
            );

            return {
                ...state,
                authenticated: true,
                noSocialUser: false,
                accessToken: action.user.accessToken,
                showLogin: false,
                showRegister: false,
            };

        case actions.AUTHENTICATED:
            localStorage.setItem("authenticated", true);
            localStorage.setItem("accessToken", action.accessToken);
            localStorage.setItem("username", action.username);
            localStorage.setItem("user_id", action.user.id);
            localStorage.setItem(
                "dropmenu",
                JSON.stringify(action.user.dropmenu)
            );

            return {
                ...state,
                authenticated: true,
                noSocialUser: false,
                accessToken: action.accessToken,
                showLogin: false,
                showRegister: false,
            };

        case actions.HIDESIGNUP:
            return {
                ...state,
                showRegister: false,
            };

        case actions.SHOWLOGIN:
            return {
                ...state,
                showLogin: true,
                noSocialUser: false,
                showRegister: false,
            };

        case actions.HIDELOGIN:
            return {
                ...state,
                showLogin: false,
                noSocialUser: false,
                showRegister: false,
            };

        case actions.SHOWREGISTER:
            return {
                ...state,
                showLogin: false,
                noSocialUser: false,
                showRegister: true,
            };

        case actions.SHOWLOCATIONSELECTOR:
            return {
                ...state,
                showLocationSelector: true,
            };
        case actions.SETLOCATION:
            localStorage.setItem("location", JSON.stringify(action.location));
            return {
                ...state,
                showLocationSelector: false,
                location: action.location,
            };
        case actions.HIDELOCATIONSELECTOR:
            return {
                ...state,
                showLocationSelector: false,
            };
        case actions.NO_SOCIAL_USER:
            return {
                ...state,
                showLogin: true,
                noSocialUser: true,
            };
        case actions.SHOWPOPUP:
            return {
                ...state,
                showPopup: action.popUp,
            };

        case actions.CLOSEPOPUP:
            return {
                ...state,
                showPopup: null,
            };

        case actions.SHOW_MODAL:
            return {
                ...state,
                showModal: action.modal,
            };

        case actions.CLOSE_MODAL:
            return {
                ...state,
                showModal: null,
            };

        case actions.SHOW_DONATE_CAUSE:
            return {
                ...state,
                showDonateCause: action.cause,
            };

        case actions.CLOSE_DONATE_CAUSE:
            return {
                ...state,
                showDonateCause: null,
            };

        case actions.SHOW_ALERT:
            return {
                ...state,
                showAlert: action.alert,
            };

        case actions.CLOSE_ALERT:
            return {
                ...state,
                showAlert: null,
            };

        default:
            return {
                ...state,
            };
    }
};

export default rootReducer;

import { Component, React, lazy } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import "./location.css";
const LocationInput = lazy(() => import("../LocationInput"));

class LocationSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: props.location
                ? props.location.formatted
                    ? props.location.formatted
                    : props.location.city
                : null,
        };
    }

    componentDidMount() {}

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (
            prevProps.showLocationSelector !== this.props.showLocationSelector
        ) {
            if (this.props.showLocationSelector) {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
            } else {
                document.body.style.position = "";
                document.body.style.width = "";
            }
        }
    }

    getErrorMessage(target) {
        if (this.state.errors && this.state.errors[target]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[target]}
                </div>
            );
        }
        return null;
    }

    render() {
        if (!this.props.showLocationSelector) return null;
        return (
            <div
                className="overlay"
                onKeyUp={(e) => {
                    if (e.key === "Escape") {
                        this.props.hideLocation();
                    }
                }}
            >
                <div className="location-select fadeIn">
                    <h3 style={{ textAlign: "center" }}>Select your city</h3>
                    <LocationInput
                        type={["(cities)"]}
                        defaultValue={
                            this.state.location || {
                                formatted: "Hyderabad, Telangana, India",
                            }
                        }
                        onLocationSelected={(location) => {
                            if (!location.city) {
                                let errors = this.state.errors || [];
                                errors["location"] = [
                                    "Please be more specific , enter your city",
                                ];
                                return this.setState({
                                    errors: errors,
                                });
                            }

                            this.setState({
                                location: location,
                                errors: {
                                    ...this.state.errors,
                                    location: null,
                                },
                            });
                        }}
                    />
                    {this.getErrorMessage("location")}
                    <div>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                if (
                                    this.state.location &&
                                    typeof this.state.location == "object"
                                ) {
                                    this.setState({
                                        errors: null,
                                    });
                                    this.props.setLocation(this.state.location);
                                } else {
                                    this.setState({
                                        errors: {
                                            location: ["Select a location"],
                                        },
                                    });
                                }
                            }}
                        >
                            Save
                        </button>
                        <button
                            className="btn"
                            onClick={this.props.hideLocation}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showLocationSelector: state.root.showLocationSelector || false,
        location: state.root.location || {
            city: "Hyderabad",
            district: "Hyderabad",
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLocation: (location) =>
            dispatch({
                type: actions.SETLOCATION,
                location: location || {
                    city: "Hyderabad",
                    district: "Hyderabad",
                },
            }),
        hideLocation: () => dispatch({ type: actions.HIDELOCATIONSELECTOR }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelector);

import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../store/actions";
import "./signin.css";
import axios from "axios";
import MobileOTPScreen from "./MobileOTPScreen";
import history from "../../history";
// import { ToastContainer, toast } from "react-toastify";

class SignupWindow extends Component {
    constructor() {
        super();
        this.state = {
            form_state: "signup",
            select_type: true,
        };

        this.showIndividualSignup = this.showIndividualSignup.bind(this);
        this.showOrgSignup = this.showOrgSignup.bind(this);
        this.registerUser = this.registerUser.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.renderSignup = this.renderSignup.bind(this);
    }

    showIndividualSignup() {
        this.setState({ select_type: false });
    }

    showOrgSignup() {
        history.push("/register-organisation");
        this.props.hideSignupWindow();
    }

    registerUser() {
        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!re.test(this.state.password)) {
            this.setState({
                errorMessage:
                    "Password should be 8 letters, should include symbol, uppercase letters and a number",
            });
            return;
        }

        let self = this;
        axios({
            method: "post",
            url: "/user/signup-ind",
            data: {
                mobile_no: self.state.mobile_no,
                email: self.state.email,
                password: self.state.password,
                fullname: self.state.fullname,
            },
        }).then((data) => {
            let resp = data.data;
            if (data.status === 200) {
                if (!resp.status) {
                    this.setState({ errorMessage: resp.message });
                    return;
                }

                this.setState({ form_state: "mobile_otp", errorMessage: "" });
            } else {
                this.setState({ errorMessage: "Invalid Mobile number" });
                return;
            }
        });
    }

    renderSelector() {
        return (
            <div className="overlay" onClick={this.props.hideSignupWindow}>
                <div
                    className="popUpBox fadeIn"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    id="signUpSelector"
                    style={{ backgroundImage: "url('/images/wave1.png')" }}
                >
                    <div className="selectContainer">
                        <div
                            className="typeSelect"
                            onClick={this.showIndividualSignup}
                        >
                            <img src="/images/ind.png" alt="individual" />
                            <h4>Individual</h4>
                            <p>
                                Select if you’re not creating an account for an
                                organization
                            </p>
                        </div>
                        <div
                            className="typeSelect"
                            onClick={this.showOrgSignup}
                        >
                            <img src="/images/org.png" alt="individual" />
                            <h4>Organization</h4>
                            <p>
                                Select if you’re an NGO, Charity/Trust, Edu
                                Institutions, Private or Public company
                            </p>
                        </div>
                    </div>
                    <div className="signinfooter">
                        By continuing you agree to our{" "}
                        <a href="/pages/terms-and-conditions" target="_blank">
                            Terms of Service
                        </a>{" "}
                        &amp;{" "}
                        <a href="/pages/privacy-policy" target="_blank">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    onInputchange(event) {
        this.setState({
            errorMessage: "",
            [event.target.name]: event.target.value,
        });
    }

    renderSignup() {
        let errMessage = null;
        if (this.state.errorMessage) {
            errMessage = (
                <div className="alert alert-danger">
                    {this.state.errorMessage}
                </div>
            );
        }

        return (
            <div className="popup-form-container">
                <div classname="title">
                    <h5>Sign Up</h5>
                </div>
                <div className="sign-in-form">
                    <div class="form-group">
                        <label for="exampleInputfullname">Fullname</label>
                        <input
                            autoFocus
                            onChange={this.onInputchange}
                            name="fullname"
                            type="text"
                            class="form-control"
                            id="exampleInputfullname"
                        />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPhone">Mobile Number</label>
                        <input
                            onChange={this.onInputchange}
                            name="mobile_no"
                            type="tel"
                            class="form-control"
                            id="exampleInputPhone"
                        />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input
                            onChange={this.onInputchange}
                            name="email"
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                        />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Password</label>
                        <input
                            onChange={this.onInputchange}
                            name="password"
                            type="password"
                            class="form-control"
                            id="exampleInputEmail1"
                        />
                    </div>
                    {errMessage}
                    <div className="cta_buttons">
                        <button
                            className="form-control signinbutton"
                            onClick={this.registerUser}
                        >
                            GET OTP
                        </button>
                    </div>
                </div>
                <div className="signup-hint">
                    Already a member?{" "}
                    <span onClick={this.props.showLoginWindow}>SIGN IN</span>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.select_type) {
            return this.renderSelector();
        }

        let popup_content = this.renderSignup();

        switch (this.state.form_state) {
            case "mobile_otp":
                popup_content = (
                    <MobileOTPScreen
                        mode="signup"
                        mobile_no={this.state.mobile_no}
                    />
                );
                break;
        }

        return (
            <div
                className="overlay"
                onClick={this.props.hideSignupWindow}
                tabIndex="0"
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        this.props.hideSignupWindow();
                    }
                }}
            >
                <div
                    className="popUpBox fadeIn"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className="left">
                        <img src="/images/logo_v.png" alt="wishwa logo" />
                        <div className="circle1"></div>
                        <div className="circle2"></div>
                        <div className="circle3"></div>
                    </div>
                    <div className="right">
                        {popup_content}
                        <div className="signinfooter">
                            By continuing you agree to our{" "}
                            <a
                                href="/pages/terms-and-conditions"
                                target="_blank"
                            >
                                Terms of Service
                            </a>{" "}
                            &amp;{" "}
                            <a href="/pages/privacy-policy" target="_blank">
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hideLogin: state.hideLogin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginWindow: () => dispatch({ type: actions.SHOWLOGIN }),
        hideSignupWindow: () => dispatch({ type: actions.HIDESIGNUP }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupWindow);

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
// import { closeMenu } from './helpers/dispatcher';
var history = createBrowserHistory();

history.listen((location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
});

export default history;
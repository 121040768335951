import axios from "axios";
import store from "../store/store";
import history from "../history";
import * as actions from "../store/actions";
import {
    updateUserMeta,
    showPopup,
    updateLoggedInUser,
} from "../helpers/dispatcher";

const requestWithoutHandler = (config) => {
    return new Promise((resolve, reject) => {
        axios(config)
            .then((success) => {
                resolve(success.data);
            })
            .catch((error) => {
                //handleError(error)
                reject(error);
            });
    });
};

const request = (config) => {
    return new Promise((resolve, reject) => {
        axios(config)
            .then((success) => {
                if (success.data.meta) {
                    if (success.data.meta.user) {
                        updateUserMeta(success.data.meta.user);
                        if (success.data.meta.user.accessToken) {
                            updateLoggedInUser(success.data.meta.user);
                        }
                    }

                    if (success.data.meta.popUpMessage) {
                        showPopup({
                            title: success.data.meta.popUpMessage.title || "",
                            content:
                                success.data.meta.popUpMessage.message || "",
                            contentType:
                                success.data.meta.popUpMessage.contentType ||
                                null,
                        });
                    }
                }

                resolve(success.data);
            })
            .catch((error) => {
                handleError(error);
                reject(error);
            });
    });
};

const handleError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        if (error.response.status === 404) {
        }
        if (error.response.status === 401) {
            console.log("identified an unauthorised request , taking to login");
            history.push("/");
            store.dispatch({ type: actions.LOGOUT });
            store.dispatch({ type: actions.SHOWLOGIN });
        }
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log("Error", error.message);
    }
};

const ApiService = {
    request: request,
    requestWithoutHandler: requestWithoutHandler,
};
export default ApiService;

import { Component, createRef } from "react";
import "./Location.css";

export default class LocationInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            searchText:
                this.props.defaultValue && this.props.defaultValue.formatted
                    ? this.props.defaultValue.formatted
                    : "",
        };
        this.searchRef = createRef();
        this.repeatSearch = null;
        this.onChange = this.onChange.bind(this);
        this.parsePlace = this.parsePlace.bind(this);
        this.token = null;
        this.placeId = props.placeId || null;
    }

    componentDidMount() {
        this.token = new Date().toString();
        this.initMap();
    }

    // static getDerivedStateFromProps(props) {
    //     if (props.defaultValue && props.defaultValue.formatted) {
    //         return {
    //             searchText:
    //                 props.defaultValue && props.defaultValue.formatted
    //                     ? props.defaultValue.formatted
    //                     : "",
    //         };
    //     } else {
    //         return {};
    //     }
    // }

    onChange(event) {
        this.setState({ searchText: event.target.value });
    }

    initMap() {
        // const componentForm = [
        //     "location",
        //     "locality",
        //     "administrative_area_level_1",
        //     "country",
        //     "postal_code",
        // ];

        const autocompleteInput = this.searchRef.current;
        const autocomplete = new window.google.maps.places.Autocomplete(
            autocompleteInput,
            {
                //fields: ['address_components', 'geometry', 'name', 'formatted_address','place_id'],
                types: this.props.type || "address",
                token: this.token,
                componentRestrictions: { country: "in" },
            }
        );
        var self = this;
        autocomplete.addListener("place_changed", function () {
            const place = autocomplete.getPlace();
            self.setState({ searchText: place.formatted_address });
            let parsedPlace = self.parsePlace(place);
            self.props.onLocationSelected(parsedPlace);
        });
    }

    parsePlace(place) {
        let formattedPlace = {
            pinCode: null,
            locality: null,
            locality2: null,
            locality3: null,
            street: null,
            city: null,
            district: null,
            state: null,
            place_id: place.place_id || null,
            latlong: place.geometry || null,
            formatted: place.formatted_address || null,
        };
        place.address_components.reverse().map((item) => {
            if (item.types.includes("postal_code")) {
                formattedPlace.pinCode = item.long_name;
            }
            if (item.types.includes("administrative_area_level_1")) {
                formattedPlace.state = item.long_name;
            }
            if (item.types.includes("route")) {
                formattedPlace.locality3 = item.long_name;
            }
            if (item.types.includes("sublocality_level_2")) {
                formattedPlace.locality2 = item.long_name;
            }
            if (item.types.includes("sublocality_level_3")) {
                formattedPlace.locality2 = item.long_name;
            }
            if (item.types.includes("sublocality")) {
                formattedPlace.locality = item.long_name;
            }
            if (item.types.includes("locality")) {
                formattedPlace.city = item.long_name;
            }
            if (item.types.includes("administrative_area_level_2")) {
                formattedPlace.city = item.long_name;
            }
            if (item.types.includes("administrative_area_level_2")) {
                formattedPlace.district = item.long_name;
            }
        });
        return formattedPlace;
    }

    render() {
        return (
            <div className="location-suggester">
                <form
                    autocomplete="off"
                    onSubmit={(ev) => {
                        ev.preventDefault();
                    }}
                >
                    <input
                        ref={this.searchRef}
                        className={
                            "input form-control " +
                            ((this.props.errors && this.props.errors.locality)? "is-invalid" : "")
                        }
                        placeholder={this.props.placeHolder || "Enter your area, locality"}
                        value={this.state.searchText}
                        onChange={this.onChange}
                        autoComplete="off"
                        autoFocus={false}
                    />{" "}
                </form>
            </div>
        );
    }
}

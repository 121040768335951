import React from "react";
import { Link } from "react-router-dom";

const SuccessPopUp = () => {
    return (
        <div
            style={{ backgroundColor: "white" }}
            className="position-fixed top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center  bg-opacity-50 z-index-1050"
        >
            <div className="bg-white p-4 rounded shadow text-center">
                <h2 className="h4 font-weight-bold mb-3">
                    Registration Successful
                </h2>
                <Link
                    to="/material-manager/ngo-requests-list"
                    className="btn btn-primary"
                >
                    Close
                </Link>
            </div>
        </div>
    );
};

export default SuccessPopUp;

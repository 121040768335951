import store from '../store/store';
import * as actions from '../store/actions';

export const updateLoggedInUser = (user = {}) => {
	store.dispatch({
		type: actions.UPDATE_LOGGED_IN_USER,
		user: user,
	});
};

export const updateUserMeta = (user = {}) => {
	store.dispatch({
		type: actions.UPDATE_USER,
		user: user,
	});
};

export const updateCMS = (content) => {
	store.dispatch({
		type: actions.UPDATE_CMS,
		content: content,
	});
};

export const showLocationSelector = () => {
	store.dispatch({
		type: actions.SHOWLOCATIONSELECTOR,
	});
};

export const noSocialUser = () => {
	store.dispatch({
		type: actions.NO_SOCIAL_USER,
	});
};

export const showPopup = (popUp) => {
	store.dispatch({
		type: actions.SHOWPOPUP,
		popUp: popUp,
	});
};

export const closePopup = () => {
	store.dispatch({
		type: actions.CLOSEPOPUP,
	});
};

export const showModel = (modal) => {
	store.dispatch({
		type: actions.SHOW_MODAL,
		modal: modal,
	});
};

export const closeModal = () => {
	store.dispatch({
		type: actions.CLOSE_MODAL,
	});
};

export const showDonateCause = (cause) => {
	store.dispatch({
		type: actions.SHOW_DONATE_CAUSE,
		cause: cause,
	});
};

export const closeDonateCause = () => {
	store.dispatch({
		type: actions.CLOSE_DONATE_CAUSE,
	});
};

export const showMenu = () => {
	store.dispatch({
		type: actions.SHOW_MAIN_MENU,
	});
};

export const closeMenu = () => {
	store.dispatch({
		type: actions.HIDE_MAIN_MENU,
	});
};
export const logout = () => {
	store.dispatch({
		type: actions.LOGOUT,
	});
};

export const showAlert = (alert) => {
	store.dispatch({
		type: actions.SHOW_ALERT,
		alert: alert,
	});
};

export const closeAlert = () => {
	store.dispatch({
		type: actions.CLOSE_ALERT,
	});
};

export const hideWishwaTalksMenu = () => {
    store.dispatch({
        type: actions.HIDE_WISHWATALKS_MENU,
    });
};

import React, { Component } from "react";
import "./banners.css";

export default class LinkInfo extends Component {
    render() {
        return (
            <ol className="info-list">
                <li>
                    To redirect the link to a new page enter only the path.{" "}
                    <span style={{ fontWeight: "bold" }}>
                        Ex. /fundraisers, /wishwaTalks/all
                    </span>
                </li>
                <li>
                    To open the link in a new tab.{" "}
                    <span style={{ fontWeight: "bold" }}>
                        Ex. https://www.google.com/
                    </span>
                </li>
                <li>
                    To show a video, enter the youtube link of the video{" "}
                    <span style={{ fontWeight: "bold" }}>
                        Ex. https://www.youtube.com/watch?v=IumWi-Kkgx0
                    </span>
                </li>
                {this.props.message ? this.props.message : null}
            </ol>
        );
    }
}

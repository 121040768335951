import React, { Component } from "react";
import Form from "react-bootstrap/Form";

class FontDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fontList: [
                { name: "Small", value: "small" },
                { name: "Medium", value: "medium" },
                { name: "Large", value: "large" },
                { name: "Xlarge", value: "xlarge" },
            ],
        };
    }
    render() {
        return (
            <div className={this.props.className ? this.props.className : ""}>
                <div
                    style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginBottom: "10px",
                    }}
                >
                    Font size <span className="required">*</span>
                </div>
                <Form.Control
                    isInvalid={
                        this.props.errors && this.props.errors[this.props.name]
                            ? true
                            : false
                    }
                    as="select"
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    errors={this.props.errors}
                >
                    <option value="">Select Font Size</option>
                    {this.state.fontList.map((fontItem) => {
                        return (
                            <option value={fontItem.value}>
                                {fontItem.name}
                            </option>
                        );
                    })}
                </Form.Control>
                {this.props.getErrorMessage(this.props.name)}
            </div>
        );
    }
}

export default FontDropdown;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import UserService from "../../services/UserService";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import "../../containers/User/dashboard.css";
import { logout } from "../../helpers/dispatcher";
import history from "../../history";
class DashboardLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }
    render() {
        return (
            <div
                className="content wave_top_page dashboard"
                style={{ backgroundImage: "url(/images/wave1.png)" }}
            >
                <Helmet>
                    <title>
                        {this.props.title
                            ? this.props.title
                            : "My Dashboard - Wishwa Seva"}
                    </title>
                </Helmet>

                {(() => {
                    if (this.props.config.fullscreen) {
                        return (
                            <div className="container">
                                {this.props.children}
                            </div>
                        );
                    } else {
                        return (
                            <div className="container">
                                <div className="row dashboard-container">
                                    <div
                                        className={
                                            "dashboard-menu" +
                                            (isMobile ? " mobile-dash" : "") +
                                            (this.state.expanded
                                                ? " expanded"
                                                : "")
                                        }
                                    >
                                        {isMobile ? (
                                            <MobileDashboardMenu
                                                toggleExpand={() => {
                                                    this.setState({
                                                        expanded:
                                                            !this.state
                                                                .expanded,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <DashboardMenu />
                                        )}
                                    </div>
                                    <div
                                        className={
                                            "dashboard-content" +
                                            (isMobile ? " mobile-dash" : "")
                                        }
                                    >
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })()}
            </div>
        );
    }
}

export default DashboardLayout;

class DashboardMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        let usermenu = localStorage.getItem("usermenu");
        if (usermenu) {
            this.setState({
                items: JSON.parse(usermenu),
            });
        } else {
            UserService.fetchData("/profile/user-menu").then((data) => {
                localStorage.setItem("usermenu", JSON.stringify(data.data));
                this.setState({ items: data.data });
            });
        }
    }

    render() {
        return (
            <div>
                <div className="dash-menu-header">
                    <div className="dash-profile">
                        <div className="img">
                            <img src="/images/profile-ph.png" alt="user" />
                        </div>
                        <div className="name">
                            {localStorage.getItem("username")
                                ? localStorage.getItem("username")
                                : null}
                        </div>
                    </div>
                </div>
                <div className="dash-menu-container">
                    <ul>
                        {this.state.items.map((menuitem, idx) => {
                            let sub_menu = null;
                            let sub_menu_items = [];
                            if (menuitem.items) {
                                menuitem.items.map(
                                    (submenuitem, submenuidx) => {
                                        let activeClass = null;
                                        if (
                                            window.location.pathname ===
                                            submenuitem.url
                                        ) {
                                            activeClass = "active";
                                        }

                                        // if(window.location.pathname.includes(submenuitem.url)){

                                        //     activeClass = 'active'
                                        // }

                                        sub_menu_items.push(
                                            <li
                                                className={activeClass}
                                                key={
                                                    "menu-item-" +
                                                    idx +
                                                    "-" +
                                                    submenuidx
                                                }
                                            >
                                                <Link to={submenuitem.url}>
                                                    {submenuitem.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                );
                                sub_menu = <ul>{sub_menu_items}</ul>;

                                return (
                                    <li className="" key={"menu-item-" + idx}>
                                        {menuitem.title}
                                        {sub_menu}
                                    </li>
                                );
                            } else {
                                let activeClass = null;

                                if (window.location.pathname === menuitem.url) {
                                    activeClass = "active";
                                }
                                // else if (menuitem.url != '/user' && window.location.pathname.includes(menuitem.url)) {
                                // 	activeClass = 'active';
                                // }

                                return (
                                    <li
                                        className={activeClass}
                                        key={"menu-item-" + idx}
                                    >
                                        <Link to={menuitem.url}>
                                            {menuitem.title}
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

class MobileDashboardMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        let usermenu = localStorage.getItem("usermenu");
        if (usermenu) {
            this.setState({
                items: JSON.parse(usermenu),
            });
        } else {
            UserService.fetchData("/profile/user-menu").then((data) => {
                localStorage.setItem("usermenu", JSON.stringify(data.data));
                this.setState({ items: data.data });
            });
        }
    }

    render() {
        return (
            <div>
                <div
                    className="dash-menu-header"
                    title="Click to show menu"
                    onClick={this.props.toggleExpand}
                >
                    <div className="dash-profile">
                        <div className="img">
                            <img
                                src="/images/profile-ph.png"
                                alt="userprofile"
                            />
                        </div>
                        <div className="name">
                            <span>Welcome !</span>
                            <br />
                            {localStorage.getItem("username")
                                ? localStorage.getItem("username")
                                : null}
                            <i
                                class="fas fa-chevron-down"
                                aria-hidden="true"
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="dash-menu-container mobile-dropdown">
                    <ul>
                        {this.state.items.map((menuitem, idx) => {
                            let sub_menu = null;
                            let sub_menu_items = [];
                            if (menuitem.items) {
                                menuitem.items.map(
                                    (submenuitem, submenuidx) => {
                                        let activeClass = null;
                                        if (
                                            window.location.pathname ===
                                            submenuitem.url
                                        ) {
                                            activeClass = "active";
                                        }

                                        // if(window.location.pathname.includes(submenuitem.url)){

                                        //     activeClass = 'active'
                                        // }

                                        sub_menu_items.push(
                                            <li
                                                className={activeClass}
                                                key={
                                                    "menu-item-" +
                                                    idx +
                                                    "-" +
                                                    submenuidx
                                                }
                                            >
                                                <Link
                                                    to={submenuitem.url}
                                                    onClick={
                                                        this.props.toggleExpand
                                                    }
                                                >
                                                    {submenuitem.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                );
                                sub_menu = <ul>{sub_menu_items}</ul>;

                                return (
                                    <li className="" key={"menu-item-" + idx}>
                                        {menuitem.title}
                                        {sub_menu}
                                    </li>
                                );
                            } else {
                                let activeClass = null;

                                if (window.location.pathname === menuitem.url) {
                                    activeClass = "active";
                                }
                                // else if (menuitem.url != '/user' && window.location.pathname.includes(menuitem.url)) {
                                // 	activeClass = 'active';
                                // }

                                return (
                                    <li
                                        className={activeClass}
                                        key={"menu-item-" + idx}
                                    >
                                        <Link
                                            to={menuitem.url}
                                            onClick={this.props.toggleExpand}
                                        >
                                            {menuitem.title}
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                        <li key="menu-item-logout">
                            <Link
                                onClick={() => {
                                    history.push("/");
                                    logout();
                                }}
                            >
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

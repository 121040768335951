
import rootReducer from './reducers/rootReducer';
import loginReducer from './reducers/loginReducer';
import chatReducer from './reducers/chatReducer';
import menuReducer from './reducers/menuReducer';
import { createStore,combineReducers } from 'redux';
import cmsReducer from './reducers/cmsReducer';

const myReducer = combineReducers({
    'root':rootReducer,
    'login':loginReducer,
    'chat':chatReducer,
    'menu': menuReducer,
    'cms': cmsReducer
})

const store = createStore(myReducer); 

export default store
import {Component} from 'react'
import './modal.css'

export default class ModalWindow extends Component {

    constructor(props){
        super(props)
    }

    render() {
        return (
            <div className="overlay">
                {this.props.children}
            </div>
        );
    }
}

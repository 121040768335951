import React, { Component } from "react";
import { connect } from "react-redux";
import { showPopup, closePopup } from "../../helpers/dispatcher";
import SeoForm from "../../containers/Backend/MarketingManager/SeoForm";
// import md5 from "md5";
import {
    postData as UserServicePostData,
    // fetchData as UserServiceFetchData,
} from "../../services/UserService";
const matchURL = function (allowedUrls, url) {
    let disable = false;

    allowedUrls.map((allowedUrl) => {
        if (allowedUrl instanceof RegExp && allowedUrl.test(url)) {
            disable = true;
        }
    });
    console.log("disable", disable);
    return disable;
};

class SeoEditor extends Component {
    constructor(props) {
        super(props);
        this.seoFormRef = React.createRef();
        this.allowedUrls = [
            "/", // Home page
            "/wishwatalks", // WT home page
            "/fundraisers", // Fundraiser home page
            "/pages/about-us", // About us page
            "/jobs", // Jobs home page
            "/pages/faq", // FAQ page
            "/fundraisers/ongoing", // Ongoing fundraisers
            "/fundraisers/create", // Create fundraiser page
            /^\/wishwatalks\/events\/.+/, // WT Detail
            /^\/pages\/success-stories\/.+/, // Story Detail
            /^\/materials\/.+/, // All Material Pages
            "/materials", // All Material Pages
            "/celebrate-with-a-cause", // All Celebrations Pages
        ];
    }
    state = {};

    render() {
        return (
            <>
                {this.state.text}
                {this.props.user &&
                this.props.user.roles.includes("Marketing Manager") ? (
                    <div
                        style={{
                            position: "fixed",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (
                                !this.allowedUrls.includes(
                                    window.location.pathname.toLowerCase()
                                )
                            ) {
                                if (
                                    matchURL(
                                        this.allowedUrls,
                                        window.location.pathname.toLowerCase()
                                    )
                                ) {
                                } else {
                                    window.alert(
                                        `Dynamic SEO Edit module is not enabled for this page. Please contact dev`
                                    );
                                    return;
                                }
                            }

                            showPopup({
                                title: "Seo Editor",
                                content: <SeoForm ref={this.seoFormRef} />,
                                buttons: [
                                    {
                                        class: "btn btn-primary",
                                        text: "Save",
                                        onClick: (ev) => {
                                            const seoFormData =
                                                this.seoFormRef.current.getData();
                                            if (seoFormData) {
                                                UserServicePostData(
                                                    "/cms/save-seo-meta",
                                                    seoFormData
                                                ).then((data) => {
                                                    closePopup();
                                                });
                                            }
                                        },
                                    },
                                    {
                                        class: "btn btn-primary-inverse",
                                        text: "Close",
                                        onClick: (ev) => {
                                            closePopup();
                                        },
                                    },
                                ],
                            });
                        }}
                        className="btn btn-warning"
                    >
                        EDIT SEO{" "}
                        <i className="fa fa-edit" onClick={(ev) => {}}></i>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.cms.content,
        user: state.root.user || null,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SeoEditor);

import React, { Component } from "react";
import history from "../../history";

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3" id="branding">
                            <div>
                                <img
                                    src="/images/wishwa-logo.png"
                                    alt=""
                                    className="img img-fluid"
                                />
                            </div>
                            <div>
                                <ul id="social-icons">
                                    <li>
                                        <a
                                            href="https://www.facebook.com/WishwaMain/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i
                                                class="fab fa-facebook-square"
                                                style={{ color: "#FFF" }}
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/wishwaindia/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i
                                                class="fab fa-instagram-square"
                                                style={{ color: "#FFF" }}
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://twitter.com/wishwaindia"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i
                                                class="fab fa-twitter-square"
                                                style={{ color: "#FFF" }}
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/company/wishwaindia/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i
                                                class="fab fa-linkedin"
                                                style={{ color: "#FFF" }}
                                            ></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            href="https://www.youtube.com/channel/UCov1oQyqa46RDXJcdMFKY6Q"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i
                                                class="fab fa-youtube"
                                                style={{ color: "#FFF" }}
                                            ></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact-details-section">
                                <strong className="contact-number">
                                    <i
                                        class="fas fa-phone-alt"
                                        style={{
                                            paddingRight: "5px",
                                            fontSize: "80%",
                                            color: "#FFFFFF!important",
                                        }}
                                    ></i>{" "}
                                    +91 73311 84477 / 78
                                </strong>
                                <br />
                                <strong>
                                    <i
                                        class="fas fa-envelope"
                                        style={{
                                            paddingRight: "5px",
                                            fontSize: "80%",
                                            color: "#FFFFFF!important",
                                        }}
                                    ></i>
                                    <a
                                        href="mailto:support@wishwa.org"
                                        style={{ color: "#FFF" }}
                                    >
                                        {" "}
                                        support@wishwa.org
                                    </a>
                                </strong>
                            </div>
                        </div>
                        <div className="col-md">
                            <div id="footer-fund-start">
                                <div id="fund-start-title">
                                    <p>
                                        Looking to start a Fundraiser? <br />
                                        <a
                                            href="https://www.wishwa.org/pages/about-us"
                                            target="_blank"
                                            style={{ color: "white" }}
                                            rel="noreferrer"
                                        >
                                            Wishwa
                                        </a>{" "}
                                        can help you.
                                    </p>
                                </div>
                                <div
                                    id="fund-start-cta"
                                    onClick={() => {
                                        history.push("/fundraisers/create");
                                    }}
                                    style={{ width: "250px!important" }}
                                >
                                    START A FUNDRAISER
                                </div>
                            </div>
                            <hr id="footer-divider" />
                            <div className="row">
                                <div className="col-md-3 footer-link-block">
                                    <h4>Help Others</h4>
                                    <ul>
                                        <li>
                                            <a
                                                rel="index,follow"
                                                href="/fundraisers"
                                            >
                                                Donate Money{" "}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="index,follow"
                                                href="/materials/give"
                                            >
                                                Giveaway Materials
                                            </a>
                                        </li>
                                        {/* <li><a rel="index,follow" href="/coming-soon">Volunteer Time</a></li>
                                         */}
                                    </ul>
                                </div>
                                <div className="col-md-3 footer-link-block">
                                    <h4>Get Helped</h4>
                                    <ul>
                                        <li>
                                            <a
                                                rel="index,follow"
                                                href="/fundraisers/create"
                                            >
                                                Get Funds
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="index,follow"
                                                href="/materials/get"
                                            >
                                                Get Materials
                                            </a>
                                        </li>
                                        {/* <li><a rel="index,follow" href="/coming-soon">Get Volunteers</a></li>
                                         */}
                                    </ul>
                                </div>
                                <div className="col-md-3 footer-link-block">
                                    {/* <h4>Your Opinion Matters</h4> */}
                                    <h4>Have a question?</h4>
                                    <ul>
                                        <li>
                                            <a
                                                rel="index,follow"
                                                href="/pages/faq"
                                            >
                                                FAQs
                                            </a>
                                        </li>
                                        {/* <li><a rel="index,follow" href="/coming-soon">Discuss Issues</a></li>
                                    <li><a rel="index,follow" href="/coming-soon">Vote on Issues / Solutions</a></li>
                                    <li><a rel="index,follow" href="/coming-soon">View Projects</a></li> */}
                                    </ul>
                                </div>
                                <div className="col-md-3 footer-link-block">
                                    <h4>What We Do</h4>
                                    <ul>
                                        <li>
                                            <a
                                                rel="index,follow"
                                                href="/pages/about-us"
                                            >
                                                About Wishwa
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                rel="index,follow"
                                                href="/wishwaTalks"
                                            >
                                                WishwaTalks
                                            </a>
                                        </li>
                                        <li>
                                            <a rel="index,follow" href="/blogs">
                                                Blogs
                                            </a>
                                        </li>

                                        {/* <li>
											<a rel="index,follow" href="/blogs">
												Blogs
											</a>
										</li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="row" id="footer-credits">
                                <div className="col-md-4">
                                    <p>
                                        <a
                                            rel="index,follow"
                                            href="/pages/privacy-policy"
                                        >
                                            Privacy Policy
                                        </a>{" "}
                                        |{" "}
                                        <a
                                            rel="index,follow"
                                            href="/pages/terms-and-conditions"
                                        >
                                            Terms of Service
                                        </a>
                                    </p>
                                </div>
                                <div
                                    className="col-md-4"
                                    style={{ textAlign: "center" }}
                                >
                                    <img
                                        src="/images/icons_payment.png"
                                        className="img img-responsive"
                                        alt="payment"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <p
                                        className="credit-text"
                                        style={{ textAlign: "right" }}
                                    >
                                        Copyright &copy; Wishwa Seva Pvt Ltd.
                                        All Rights Reserved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export { Footer };

//

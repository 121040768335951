import * as actions from "../actions";

const initilaState = {
    authenticated: false,
    showLogin: false,
    showRegister: false,
};

const loginReducer = (state = initilaState, action) => {
    switch (action.type) {
        case actions.SHOWLOGIN:
            return {
                ...state,
                showLogin: true,
                showRegister: false,
            };

        case actions.HIDELOGIN:
            return {
                ...state,
                showLogin: false,
            };

        case actions.SHOWREGISTER:
            return {
                ...state,
                showLogin: false,
                showRegister: true,
            };

        default:
            return {
                ...state,
            };
    }
};

export default loginReducer;

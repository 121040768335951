import React, { Component, Fragment } from "react";
// import { act } from "react-dom/test-utils";
import { connect } from "react-redux";
import "./donate-money.css";
import { BsInput, SelectComponent } from "../Form/FormComponents";
import {getLoggedinProfile} from "../../services/UserService";
import {postCreateSchedule,getCauses} from "../../services/CampaignService";
// import { ToastContainer, toast } from "react-toastify";
import * as Validator from "validatorjs";
import { Form } from "react-bootstrap";
// import dateFormat from "dateformat";
import Popup from "../Generic/Popups/Popup";
import LoaderSpinner from "../Generic/LoaderSpinner";
import * as actions from "../../store/actions";
import history from "../../history";
import moment from "moment";

class DonateMoneyCause extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.screen
                ? "Donate to Cause"
                : "Choose your Donation Type",
            screen: props.screen ? props.screen : "donate-type",
            cause: null,
            loggedIn: false,
            selected_amount: null,
            show_proceeding: false,
            data: {
                donation_frequency: 2,
                schedule: 2,
                selectedCauses: ["-1"],
                startDate: moment().add(3, "days").format("YYYY-MM-DD"),
                option: "onetime",
            },
        };
        this.selectScreen = this.selectScreen.bind(this);
        this.setData = this.setData.bind(this);
        this.submitDonation = this.submitDonation.bind(this);
    }

    componentDidMount() {
        getLoggedinProfile()
            .then((response) => {
                console.log(response);
                if (response) {
                    this.setState({
                        data: {
                            ...this.state.data,
                            donor_name: response.fullname,
                            donor_email: response.email,
                            donor_phone: response.mobile_no,
                            indian_citizen: 1,
                        },
                        loggedIn: true,
                    });
                } else {
                    // non logged in user
                }
            })
            .catch((response) => {});
    }

    setData(data, cb = null) {
        this.setState(
            {
                data: {
                    ...this.state.data,
                    ...data,
                },
            },
            cb
        );
    }

    async submitDonation(data) {
        // this.setData(data, async () => {console.log(this.state.data);return;});
        // return;
        this.setState({
            show_proceeding: true,
        });
        this.setData(data, async () => {
            console.log("data parent", this.state.data);

            postCreateSchedule({
                ...this.state.data,
                current_url: window.location.href,
            })
                .then((response) => {
                    console.log(response);
                    if (this.state.option == "schedule") {
                        if (response.status && response.data.schedule) {
                            this.props.onClose();
                            window.location.href =
                                response.data.schedule.subscriptionCreationInfo.authLink;
                        }
                    } else {
                        var form = document.createElement("form");
                        form.setAttribute("method", "post");
                        form.setAttribute("action", response.data.action);

                        Object.keys(response.data.data)
                            .sort()
                            .reduce((obj, key) => {
                                var hiddenField =
                                    document.createElement("input");
                                hiddenField.setAttribute("type", "hidden");
                                hiddenField.setAttribute("name", key);
                                hiddenField.setAttribute(
                                    "value",
                                    response.data.data[key]
                                );
                                form.appendChild(hiddenField);
                            }, {});

                        console.log(form);

                        document.body.appendChild(form);

                        // form.setAttribute("target", "view");
                        // window.open('', 'view');
                        form.submit();
                    }
                })
                .catch((error) => {});
        });

        //Close the popup
    }

    selectScreen(screen = "donate-type", option = null) {
        this.setState({
            screen: screen,
            option: option || this.state.data.option || "onetime",
            title: this.props.title || "Donate to Cause",
            data: {
                ...this.state.data,
                option: option,
            },
        });
    }

    render() {
        let content = null;
        if (this.state.screen === "donate-type") {
            content = (
                <DonationType
                    setData={this.setData}
                    data={this.state.data}
                    selectScreen={this.selectScreen}
                    loggedIn={this.state.loggedIn}
                    authenticated={this.props.authenticated}
                />
            );
        }
        if (this.state.screen === "donation-tab") {
            content = (
                <DonationTab
                    type={this.props.type || null}
                    setData={this.setData}
                    data={this.state.data}
                    screen={this.state.option}
                    submitDonation={this.submitDonation}
                    selectScreen={this.selectScreen}
                />
            );
        }
        if (this.state.screen === "donation-donor-info") {
            content = (
                <DonorInfo
                    setData={this.setData}
                    data={this.state.data}
                    screen={this.state.option}
                    selectScreen={this.selectScreen}
                />
            );
        }

        if (this.state.show_proceeding) {
            return (
                <Popup
                    showWindow={this.state.show_proceeding}
                    showCloseButton={false}
                    title="Redirecting to payment gateway"
                    buttonContainerClass="right"
                    buttons={[]}
                >
                    <div style={{ minWidth: "300px" }}>
                        <LoaderSpinner />
                    </div>
                </Popup>
            );
        }

        return (
            <DonateWrapper
                selectScreen={this.selectScreen}
                title={this.state.title}
                onClose={this.props.onClose}
            >
                {content}
            </DonateWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.root.authenticated,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        doAuthenticate: () => dispatch({ type: actions.AUTHENTICATED }),
        doLogout: () => {
            dispatch({ type: actions.LOGOUT });
            history.push("/");
        },
        showLoginWindow: () => dispatch({ type: actions.SHOWLOGIN }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DonateMoneyCause);

class DonateWrapper extends Component {
    render() {
        return (
            <div className="donation-overlay">
                <div className="donation-container">
                    <div className="dm-header">
                        <div className="left-nav">
                            {this.props.screen == "donate-type" ? null : (
                                <i
                                    class="fa fa-chevron-left"
                                    onClick={() => {
                                        this.props.selectScreen("donate-type");
                                    }}
                                ></i>
                            )}
                        </div>
                        <div className="title">{this.props.title}</div>
                        <div className="close" onClick={this.props.onClose}>
                            <i class="fa fa-close"></i>
                        </div>
                    </div>
                    <div className="dm-body">{this.props.children}</div>
                    <div className="dm-footer"></div>
                </div>
            </div>
        );
    }
}

class DonationType extends Component {
    render() {
        return (
            <div className="donation-type">
                <div
                    className="donation-type-item"
                    onClick={() => {
                        this.props.selectScreen(
                            this.props.loggedIn
                                ? "donation-tab"
                                : "donation-donor-info",
                            "onetime"
                        );
                    }}
                >
                    <div>
                        <i class="fas fa-money-bill-alt"></i>
                    </div>
                    <div>One Time</div>
                    <div></div>
                </div>
                <div
                    className="donation-type-item"
                    onClick={() => {
                        this.props.selectScreen(
                            this.props.loggedIn
                                ? "donation-tab"
                                : "donation-donor-info",
                            "schedule"
                        );
                    }}
                >
                    <div>
                        <i class="fas fa-calendar-alt"></i>
                    </div>
                    <div>Recurring</div>
                    <div></div>
                </div>
            </div>
        );
    }
}

class DonationTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: props.screen,
        };
        props.setData({ option: props.screen });
    }

    render() {
        return (
            <div className="donation-tab">
                <div className="tab-selection">
                    <div
                        onClick={() => {
                            this.props.setData({ option: "onetime" });
                            this.setState({ activeScreen: "onetime" });
                        }}
                        className={
                            this.state.activeScreen == "onetime"
                                ? "active"
                                : null
                        }
                    >
                        One Time
                    </div>
                    <div
                        onClick={() => {
                            this.props.setData({ option: "schedule" });
                            this.setState({ activeScreen: "schedule" });
                        }}
                        className={
                            this.state.activeScreen == "schedule"
                                ? "active"
                                : null
                        }
                    >
                        Recurring
                    </div>
                </div>
                <div className="tab-content">
                    {this.state.activeScreen == "onetime" ? (
                        <OneTimeDonation
                            type={this.props.type || null}
                            submitDonation={this.props.submitDonation}
                            setData={this.props.setData}
                            data={this.props.data}
                            setData={this.props.setData}
                        />
                    ) : (
                        <ScheduleDonation
                            type={this.props.type || null}
                            submitDonation={this.props.submitDonation}
                            setData={this.setData}
                            data={this.props.data}
                            setData={this.props.setData}
                        />
                    )}
                </div>
            </div>
        );
    }
}

class ChooseAmountAndCause extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            causes: [],
            anyCause: false,
            selected_amount: props.data.selected_amount,
            selectedCauses: [],
            selected_cause: null,
        };
        this.popular = [2500, 5000, 9999];
        this.selectAmount = this.selectAmount.bind(this);
        this.renderPopular = this.renderPopular.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.proceedToPay = this.proceedToPay.bind(this);
    }
    componentDidMount() {
        getLoggedinProfile()
            .then((response) => {
                console.log(response);
                if (response) {
                    this.setState({
                        donor_name: response.fullname,
                        donor_email: response.email,
                        donor_phone: response.mobile_no,
                    });
                } else {
                    // non logged in user
                }
            })
            .catch((response) => {});
        getCauses().then((response) => {
            this.setState({ causes: response.data });
        });
    }

    selectAmount(amount) {
        this.setState({ selected_amount: amount });
    }
    handleAmountChange(event) {
        const value = event.target.value.replace(/\D/g, "");
        this.selectAmount(value);
    }

    renderPopular() {
        return (
            <div style={{ paddingTop: "10px" }}>
                {this.popular.map((item, idx) => {
                    let selected = null;
                    if (item == this.state.selected_amount) {
                        selected = "selected";
                    }
                    return (
                        <div
                            className={"badge badge-secondary " + selected}
                            onClick={() => {
                                this.selectAmount(item);
                            }}
                            key={"key " + idx}
                        >
                            {item} <i class="fas fa-check-circle"></i>
                        </div>
                    );
                })}
            </div>
        );
    }

    onInputchange(event) {
        this.setState({
            errorMessage: "",
            [event.target.name]: event.target.value,
        });
    }

    formVerify() {
        let errors = {};
        let err = false;
        if (!this.state.selected_amount) {
            errors.selected_amount = "Enter an amount";
            err = true;
        }
        if (!this.state.selected_cause && !this.props.donationType) {
            errors.selected_cause = "Select A Cause";
            err = true;
        }

        if (err) {
            this.setState({
                errors: errors,
            });
            return false;
        }

        this.setState({
            errors: [],
        });
        return true;
    }

    proceedToPay() {
        if (this.formVerify()) {
            let selectedCauses = [];
            selectedCauses.push(this.state.selected_cause);
            this.props.submitDonation({
                donationType: this.props.donationType,
                show_proceeding: true,
                selectedCauses: selectedCauses,
                selected_amount: this.state.selected_amount,
                anyCause: this.state.selected_cause == -1 ? 1 : null,
                donation_tenure: this.state.donation_tenure
                    ? this.state.donation_tenure
                    : 2,
                anonymous: this.state.anonymous ? 1 : 0,
            });
        }
    }

    render() {
        let amountError = null;
        if (this.state.errors) {
            if (this.state.errors.selected_amount) {
                amountError = (
                    <div
                        className="alert alert-danger"
                        style={{
                            padding: "5px",
                            fontSize: "12px",
                            marginTop: "5px",
                        }}
                    >
                        {this.state.errors.selected_amount}
                    </div>
                );
            }
        }

        return (
            <div className="choose-container">
                <div id="amount-selector">
                    <div className="row">
                        <div className="col-md-6 hide-in-mobile">
                            <div>Popular Donation Amounts</div>
                            {this.renderPopular()}
                        </div>
                        <div
                            className="col-md-1 hide-in-mobile"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            OR
                        </div>
                        <div className="col-md-5">
                            <div
                                className="hide-in-mobile"
                                style={{
                                    textAlign: "center",
                                    fontSize: "11px",
                                    paddingBottom: "10px",
                                }}
                            >
                                Enter amount greater than Rs.100
                            </div>
                            <div className="custom-amount">
                                <input
                                    onChange={this.handleAmountChange}
                                    type="text"
                                    value={this.state.selected_amount}
                                    placeholder="Enter amount"
                                />
                                {amountError}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row" style={{ paddingLeft: "10px" }}>
                        <div className="col-md-12">
                            {(() => {
                                if (this.props.donationType) {
                                    return null;
                                } else {
                                    return (
                                        <SelectComponent
                                            name="selected_cause"
                                            label="Select a cause"
                                            errors={this.state.errors}
                                            onChange={(ev) => {
                                                this.onInputchange(ev);
                                            }}
                                            defaultValue={
                                                this.state.selected_cause
                                            }
                                            value={this.state.selected_cause}
                                        >
                                            <option value={null}>Select</option>
                                            <option value="-1">
                                                Any Cause
                                            </option>
                                            {this.state.causes.map((item) => {
                                                return (
                                                    <option value={item.id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </SelectComponent>
                                    );
                                }
                            })()}
                        </div>
                        <div className="col-md-12">
                            {(() => {
                                if (this.props.type == "onetime") {
                                    return (
                                        <div className="row">
                                            <div
                                                className="col-md-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <br />
                                                <label>
                                                    <input
                                                        onChange={
                                                            this.onInputchange
                                                        }
                                                        type="checkbox"
                                                        name="anonymous"
                                                        value="1"
                                                    />
                                                    &nbsp; Make my donation
                                                    anonymous
                                                </label>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <SelectComponent
                                                    name="donation_tenure"
                                                    label="Tenure (how long do you want to keep making a difference?"
                                                    errors={this.state.errors}
                                                    onChange={
                                                        this.onInputchange
                                                    }
                                                    defaultValue={
                                                        this.state
                                                            .donation_tenure
                                                    }
                                                    className="col-md-4"
                                                    value={
                                                        this.state
                                                            .donation_tenure
                                                    }
                                                >
                                                    <option value="2">
                                                        2 Years - Champion of
                                                        Wishwa
                                                    </option>
                                                    <option value="1">
                                                        1 Year
                                                    </option>
                                                    <option value="0.5">
                                                        6 Months
                                                    </option>
                                                    <option value="0.25">
                                                        3 Months
                                                    </option>
                                                </SelectComponent>
                                            </div>
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                    }}
                >
                    <button
                        className="active-button"
                        onClick={this.proceedToPay}
                    >
                        PROCEED TO PAY
                    </button>
                </div>
                <form id=""></form>
                <div
                    id="terms"
                    style={{
                        display: "none",
                        justifySelf: "flex-end",
                        fontSize: "12px",
                        textAlign: "center",
                    }}
                >
                    By Proceeding you agree to our &amp;{" "}
                    <a href="#">Terms of Service</a> &amp;{" "}
                    <a href="#">Privacy Policy</a>{" "}
                </div>
            </div>
        );
    }
}

class OneTimeDonation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="donation-pref onetime">
                <div>
                    <p>
                        One Time donations can make an impact, but Scheduled
                        Donations make a <span>Larger Impact!</span>
                    </p>
                </div>
                <ChooseAmountAndCause
                    type="onetime"
                    donationType={this.props.type || null}
                    submitDonation={this.props.submitDonation}
                    setData={(data = {}) => {
                        this.props.setData({ ...data, option: "onetime" });
                    }}
                    data={this.props.data}
                />
            </div>
        );
    }
}

class ScheduleDonation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            donation_frequency: 2,
            showCustom: false,
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.updateState = this.updateState.bind(this);
    }
    onInputchange(event) {
        let newState = {
            [event.target.name]: event.target.value,
            option: "schedule",
        };
        if (event.target.name == "donation_frequency") {
            newState.scheduleConfig = null;
        }
        this.setState(newState);
    }
    updateState(es, cb = () => {}) {
        console.log(es);
        this.props.setData({
            scheduleConfig: {
                ...es,
                donation_frequency: this.state.donation_frequency,
            },
        });
        this.setState(
            {
                scheduleConfig: es,
            },
            cb()
        );
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-3 donation-freq-cont">
                        <SelectComponent
                            name="donation_frequency"
                            label="Donation Type"
                            errors={this.state.errors}
                            onChange={(ev) => {
                                this.props.setData({
                                    donation_frequency: ev.target.value,
                                });
                                this.onInputchange(ev);
                            }}
                            defaultValue={this.state.donation_frequency}
                            value={this.state.donation_frequency}
                        >
                            <option value="1">Weekly</option>
                            <option value="2">Monthly</option>
                            <option value="3">Yearly</option>
                        </SelectComponent>
                    </div>
                    <div
                        className="col-md-3 hide-in-mobile"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <label>&nbsp;</label>
                        <label style={{ verticalAlign: "middle" }}>
                            {(() => {
                                let label = [
                                    "Every Week",
                                    "Every Month",
                                    "Every Year",
                                ];
                                return this.state.scheduleConfig
                                    ? this.state.scheduleConfig.message
                                    : label[
                                          parseInt(
                                              this.state.donation_frequency
                                          ) - 1
                                      ];
                            })()}
                        </label>
                    </div>
                    <div
                        className="col-md-3"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            justifyContent: "flex-start",
                            cursor: "pointer",
                            alignItems: "center",
                        }}
                        onClick={(ev) => {
                            this.setState({
                                showCustom: !this.state.showCustom,
                            });
                        }}
                    >
                        <i
                            class="fas fa-sliders-h"
                            style={{ paddingRight: "10px" }}
                        ></i>{" "}
                        Customize
                        {this.state.showCustom ? (
                            <Customizer
                                type={this.state.donation_frequency}
                                onSubmit={this.updateState}
                                onClose={() => {
                                    this.setState({ showCustom: false });
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="col-md-3">
                        <Form.Group controlId="dob">
                            <Form.Label>Start Date</Form.Label>
                            {(() => {
                                let dateProps = {
                                    defaultValue: moment()
                                        .add(3, "days")
                                        .format("YYYY-MM-DD"),
                                    value: this.state.startDate,
                                    type: "date",
                                    min: moment()
                                        .add(3, "days")
                                        .format("YYYY-MM-DD"),
                                    name: "startDate",
                                    onChange: (ev) => {
                                        this.props.setData({
                                            startDate: ev.target.value,
                                        });
                                        this.onInputchange(ev);
                                    },
                                };

                                let errMessage = null;

                                if (this.state.errors["dateOfBirth"]) {
                                    dateProps.isInvalid = true;
                                    errMessage = (
                                        <Form.Control.Feedback type="invalid">
                                            {this.state.errors["dateOfBirth"]}
                                        </Form.Control.Feedback>
                                    );
                                }

                                return (
                                    <div>
                                        <Form.Control {...dateProps} />
                                        {errMessage}
                                    </div>
                                );
                            })()}
                        </Form.Group>
                    </div>
                </div>
                <ChooseAmountAndCause
                    donationType={this.props.type || null}
                    type="schedule"
                    submitDonation={this.props.submitDonation}
                    setData={(data = {}) => {
                        this.props.setData({ ...data, option: "schedule" });
                    }}
                    data={this.props.data}
                />
            </div>
        );
    }
}

class DonorInfo extends Component {
    constructor(props) {
        super(props);

        let newState = {
            errors: [],
            donor_name: this.props.data.donor_name,
            donor_email: this.props.data.donor_email,
            donor_phone: this.props.data.donor_phone,
            indian_citizen: this.props.data.indian_citizen,
        };

        this.state = newState;
        this.onInputchange = this.onInputchange.bind(this);
        this.continuePayment = this.continuePayment.bind(this);
        this.formVerify = this.formVerify.bind(this);
        this.formData = this.formData.bind(this);
    }

    formData() {
        return {
            donor_name: this.state.donor_name,
            donor_email: this.state.donor_email,
            donor_phone: this.state.donor_phone,
            indian_citizen: this.state.indian_citizen,
        };
    }

    formVerify() {
        let data = this.formData();

        let rules = {
            donor_name: "required",
            donor_email: "required|email",
            donor_phone: "required|digits:10",
            indian_citizen: "required:numeric",
        };

        let messages = {
            "email.donor_email": "Email is invalid",
            "required.donor_email": "Email is required",
            "required.donor_name": "Name is required",
            "required.donor_phone": "Mobile number is required",
            "required.indian_citizen": "Indian citizen is required",
        };

        let validation = new Validator(data, rules, messages);

        if (validation.fails()) {
            this.setState({ errors: validation.errors.all() });
            return false;
        } else {
            this.setState({ errors: [] });
            return true;
        }
    }

    continuePayment() {
        if (!this.formVerify()) {
            return;
        }
        this.props.setData(this.formData(), () => {
            this.props.selectScreen("donation-tab");
        });
    }

    onInputchange(event) {
        this.setState({
            errorMessage: "",
            [event.target.name]: event.target.value,
        });
    }

    render() {
        let props = {};
        if (this.props.data.indian_citizen || this.state.indian_citizen) {
            props.checked = true;
        }
        return (
            <div className="choose-container">
                <div>
                    <div className="row">
                        <BsInput
                            label="Name"
                            placeholder="Eg. John Doe"
                            value={this.state.donor_name}
                            className="col-md-12"
                            name="donor_name"
                            onChange={this.onInputchange}
                            errors={this.state.errors}
                        />
                        <BsInput
                            label="Email Address"
                            placeholder="Eg. Johndoe@email.com"
                            value={this.state.donor_email}
                            className="col-md-6"
                            name="donor_email"
                            onChange={this.onInputchange}
                            errors={this.state.errors}
                        />
                        <BsInput
                            label="Mobile Number"
                            placeholder="Eg: 9999988888"
                            value={this.state.donor_phone}
                            className="col-md-6"
                            name="donor_phone"
                            onChange={this.onInputchange}
                            errors={this.state.errors}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                <input
                                    {...props}
                                    onChange={this.onInputchange}
                                    type="checkbox"
                                    name="indian_citizen"
                                    value="1"
                                />
                                &nbsp; I am an Indian Citizen
                            </label>
                            {(() => {
                                if (this.state.errors["indian_citizen"]) {
                                    return (
                                        <div className="alert alert-danger">
                                            You must be Indian citizen to
                                            donate.
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                    }}
                >
                    <button
                        className="active-button"
                        onClick={this.continuePayment}
                    >
                        Continue
                    </button>
                </div>
                <form id=""></form>
                <div
                    id="terms"
                    style={{
                        justifySelf: "flex-end",
                        fontSize: "12px",
                        textAlign: "center",
                    }}
                >
                    By Proceeding you agree to our{" "}
                    <a href="/pages/terms-and-conditions" target="_blank">
                        Terms of Service
                    </a>{" "}
                    &amp;{" "}
                    <a href="/pages/privacy-policy" target="_blank">
                        Privacy Policy
                    </a>{" "}
                </div>
            </div>
        );
    }
}

let customizerStyle = {
    weekly: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "10px 0px",
        alignItems: "center",
    },
    weekSelector: {
        padding: "5px 10px",
        border: "1px solid #333",
        fontSize: "12px",
    },
    daySelector: {
        padding: "5px",
        border: "1px solid #333",
        fontSize: "12px",
        alignSelf: "left",
        marginBottom: "2px",
        display: "flex",
        justifyContent: "space-around!important",
    },
};

class Customizer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errMessage: null,
            mode: "each",
            month: "January",
            days: [],
            weekDays: [
                { name: "S", selected: false, fullname: "Sunday" },
                { name: "M", selected: false, fullname: "Monday" },
                { name: "T", selected: false, fullname: "Tuesday" },
                { name: "W", selected: false, fullname: "Wednesday" },
                { name: "T", selected: false, fullname: "Thursday" },
                { name: "F", selected: false, fullname: "Friday" },
                { name: "S", selected: false, fullname: "Saturday" },
            ],
            selectedDaysOfMonth: {},
            on_day: "First",
            selected_day: "Sunday",
        };
        this.getWeekly = this.getWeekly.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.prepareData = this.prepareData.bind(this);
    }

    onInputchange(event) {
        let newState = {
            errMessage: null,
            [event.target.name]: event.target.value,
        };

        if (event.target.name == "mode") {
            newState.days = [];
            newState.on_day = "First";
            newState.selected_day = "Sunday";
        }

        this.setState(newState);
    }

    toggleDay(i, clear = false) {
        let days = this.state.days;
        if (clear) {
            days = [];
        }
        if (days.indexOf(i) >= 0) {
            days.splice(days.indexOf(i), 1);
        } else {
            days.push(i);
        }
        this.setState({ days: days });
    }

    getWeekly() {
        return (
            <Fragment>
                <div style={customizerStyle.weekly}>
                    <div>Every</div>
                    <div>
                        <select
                            value={
                                this.state.interval ? this.state.interval : 1
                            }
                            name="interval"
                            onChange={this.onInputchange}
                        >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
                    <div>week(s) on</div>
                </div>
                <div style={customizerStyle.weekly}>
                    {this.state.weekDays.map((item, idx) => {
                        let activeClass = this.state.weekDays[idx].selected
                            ? "week-active"
                            : "";
                        return (
                            <div
                                className={activeClass}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    let weekDays = this.state.weekDays;
                                    weekDays[idx] = {
                                        ...weekDays[idx],
                                        selected: !weekDays[idx].selected,
                                    };
                                    this.setState({
                                        weekDays: weekDays,
                                    });
                                }}
                                style={{ ...customizerStyle.weekSelector }}
                            >
                                {item.name}
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    }

    getMonthly() {
        const styleCheck = {
            justifyContent: "flex-start!important",
            alignItems: "left",
            alignContent: "center",
            alignItems: "center",
            padding: "5px 0px",
        };
        return (
            <Fragment>
                <div style={customizerStyle.weekly}>
                    <div>Every</div>
                    <div>
                        <select
                            name="interval"
                            value={
                                this.state.interval ? this.state.interval : 1
                            }
                            onChange={this.onInputchange}
                        >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                        </select>
                    </div>
                    <div>months(s) on</div>
                </div>

                <div style={{ ...customizerStyle.weekly, ...styleCheck }}>
                    <label
                        style={{ cursor: "pointer", margin: "0px!important" }}
                    >
                        {" "}
                        <input
                            onChange={this.onInputchange}
                            type="radio"
                            name="mode"
                            checked={this.state.mode == "each" ? true : false}
                            value="each"
                            style={{ marginRight: "10px" }}
                        />{" "}
                        Each
                    </label>
                </div>

                <div style={customizerStyle.weekly} className="calendar">
                    {(() => {
                        let i = 0;
                        let elements = [];
                        for (i = 1; i <= 31; i++) {
                            let activeClass = "";
                            let idx = i;
                            if (this.state.days.indexOf(i) >= 0) {
                                activeClass = "week-active";
                            }
                            elements.push(
                                <div
                                    className={"item " + activeClass}
                                    onClick={(ev) => {
                                        this.toggleDay(idx);
                                        ev.stopPropagation();
                                    }}
                                    style={{ ...customizerStyle.daySelector }}
                                >
                                    {i}
                                </div>
                            );
                        }
                        return elements;
                    })()}
                </div>

                <div style={{ ...customizerStyle.weekly, ...styleCheck }}>
                    <label
                        style={{ cursor: "pointer", margin: "0px!important" }}
                    >
                        {" "}
                        <input
                            onChange={this.onInputchange}
                            type="radio"
                            name="mode"
                            value="on_day"
                            style={{ marginRight: "10px" }}
                        />{" "}
                        On the{" "}
                    </label>
                </div>
                <div style={{ ...customizerStyle.weekly }}>
                    <select
                        value={this.state.on_day}
                        name="on_day"
                        onChange={this.onInputchange}
                    >
                        <option>First</option>
                        <option>Second</option>
                        <option>Third</option>
                        <option>Fourth</option>
                        <option>Last</option>
                    </select>
                    <select
                        value={this.state.selected_day}
                        name="selected_day"
                        onChange={this.onInputchange}
                    >
                        <option>Sunday</option>
                        <option>Monday</option>
                        <option>Tuesday</option>
                        <option>Wednesday</option>
                        <option>Thursday</option>
                        <option>Friday</option>
                        <option>Saturday</option>
                    </select>
                </div>
            </Fragment>
        );
    }

    getYearly() {
        const styleCheck = {
            justifyContent: "flex-start!important",
            alignItems: "left",
            alignContent: "center",
            alignItems: "center",
            padding: "5px 0px",
        };
        return (
            <Fragment>
                <div style={customizerStyle.weekly}>
                    <div>Every</div>
                    <div>
                        <select
                            name="month"
                            value={this.state.month}
                            onChange={this.onInputchange}
                        >
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                        </select>
                    </div>
                    <div>on</div>
                </div>

                <div style={{ ...customizerStyle.weekly, ...styleCheck }}>
                    <label
                        style={{ cursor: "pointer", margin: "0px!important" }}
                    >
                        {" "}
                        <input
                            onChange={this.onInputchange}
                            type="radio"
                            name="mode"
                            checked={this.state.mode == "each" ? true : false}
                            value="each"
                            style={{ marginRight: "10px" }}
                        />{" "}
                        Each
                    </label>
                </div>

                <div style={customizerStyle.weekly} className="calendar">
                    {(() => {
                        let i = 0;
                        let elements = [];
                        for (i = 1; i <= 31; i++) {
                            let idx = i;
                            let activeClass = "";
                            if (this.state.days.indexOf(i) >= 0) {
                                activeClass = "week-active";
                            }
                            elements.push(
                                <div
                                    className={"item " + activeClass}
                                    onClick={(ev) => {
                                        this.toggleDay(idx, true);
                                        ev.stopPropagation();
                                    }}
                                    style={{ ...customizerStyle.daySelector }}
                                >
                                    {i}
                                </div>
                            );
                        }
                        return elements;
                    })()}
                </div>
            </Fragment>
        );
    }

    prepareData() {
        let data = {};
        let selectedDays = [];
        let message = "";
        switch (this.props.type ? parseInt(this.props.type) : 1) {
            case 1:
                const interval = this.state.interval ? this.state.interval : 1;
                this.state.weekDays.map((item, idx) => {
                    if (item.selected) {
                        selectedDays.push(item.fullname);
                    }
                });
                if (selectedDays.length <= 0) {
                    this.setState({
                        errMessage: "Select at least one day",
                    });
                    break;
                }
                message = `Every ${interval} week${
                    interval > 1 ? "s" : ""
                } on ${selectedDays.join(", ")}`;
                data = {
                    interval: interval,
                    days: this.state.weekDays,
                    message: message,
                };
                break;
            case 2:
                const monthly_interval = this.state.interval
                    ? this.state.interval
                    : 1;
                if (this.state.mode == "each") {
                    let dayFormatted = [];
                    selectedDays = this.state.days.sort();
                    selectedDays.map((item, idx) => {
                        let txt = "";
                        switch (item % 10) {
                            case 1:
                                txt = "st";
                                break;
                            case 2:
                                txt = "nd";
                                break;
                            case 3:
                                txt = "rd";
                                break;
                            default:
                                txt = "th";
                                break;
                        }
                        if (item >= 11 && item < 14) {
                            txt = "th";
                        }
                        dayFormatted.push(`${item}${txt}`);
                    });
                    if (this.state.days.length <= 0) {
                        this.setState({
                            errMessage: "Select at least one day",
                        });
                        return;
                    }
                    message = `Every ${monthly_interval} month${
                        monthly_interval > 1 ? "s" : ""
                    } on ${dayFormatted.join(", ")}`;
                    data = {
                        interval: monthly_interval,
                        days: this.state.days,
                        message: message,
                        mode: this.state.mode,
                    };
                } else {
                    data = {
                        interval: monthly_interval,
                        mode: this.state.mode,
                        on_day: this.state.on_day,
                        selected_day: this.state.selected_day,
                        message: `Every ${monthly_interval} month${
                            monthly_interval > 1 ? "s" : ""
                        } on ${this.state.on_day} ${this.state.selected_day} `,
                    };
                }
                break;
            case 3:
                data = {};
                let dayFormatted = [];
                selectedDays = this.state.days.sort();
                selectedDays.map((item, idx) => {
                    let txt = "";
                    switch (item % 10) {
                        case 1:
                            txt = "st";
                            break;
                        case 2:
                            txt = "nd";
                            break;
                        case 3:
                            txt = "rd";
                            break;
                        default:
                            txt = "th";
                            break;
                    }
                    if (item >= 11 && item < 14) {
                        txt = "th";
                    }
                    dayFormatted.push(`${item}${txt}`);
                });
                if (this.state.days.length <= 0) {
                    this.setState({
                        errMessage: "Select at least one day",
                    });
                    return;
                }
                message = `Every ${this.state.month} on ${dayFormatted.join(
                    ", "
                )}`;
                data = {
                    month: this.state.month,
                    days: this.state.days,
                    message: message,
                };
                break;
        }
        this.props.onSubmit(data, this.props.onClose);
    }

    render() {
        let content = this.getWeekly();
        switch (this.props.type ? parseInt(this.props.type) : 1) {
            case 2:
                content = this.getMonthly();
                break;
            case 3:
                content = this.getYearly();
                break;
        }
        return (
            <div
                id="customizer"
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <div className="popup-contain">
                    <div className="closeButton" onClick={this.props.onClose}>
                        <i class="fas fa-times"></i>
                    </div>
                    <div className="customizer-content">
                        <div
                            style={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "18px",
                                textAlign: "center",
                            }}
                        >
                            Donate
                        </div>
                        {content}
                        <div style={{ color: "#F00", fontSize: "12px" }}>
                            {this.state.errMessage}
                        </div>
                        <div className="buttons">
                            <button
                                className="btn btn-small"
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-small btn-primary"
                                onClick={this.prepareData}
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

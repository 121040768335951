import React, { Component } from "react";
import "./materialsBannersList.css";
import {
    BsInput,
    BsArea,
    FileUploader,
} from "../../../components/Form/FormComponents";
import Validator from "validatorjs";
import HrBlock from "../../../containers/Backend/Hr/HrBlock";
import { showPopup, closePopup } from "../../../helpers/dispatcher";
import { toast } from "react-toastify";
import history from "../../../history";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
import FontDropdown from "../../../components/Generic/Banner/FontDropdown";
import LinkInfo from "../../../components/Generic/Banner/LinkInfo";
const WishwaTalkService = require("../../../services/WishwaTalkService");
const MaterialService = require("../../../services/MaterialService");
class CreateBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerType: props.type == "cause" ? true : false,
            includeCta1: true,
            includeCta2: true,
        };
    }

    componentDidMount = () => {};

    submitBanner = async () => {
        await this.setState({ errors: {} });
        const rules = {
            header: "required",
            description: "required",
            readMore: "required",
            backgroundImage: "required",
            backgroundImageAltTag: "required",
        };

        if (!this.state.bannerType) {
            rules["topHeader"] = "required";
        }

        if (this.state.includeCta1) {
            rules["cta1Heading"] = "required";

            rules["cta1Link"] = "required";
        }

        if (this.state.includeCta2) {
            rules["cta2Heading"] = "required";

            rules["cta2Link"] = "required";
        }

        const message = {
            "required.header": "Enter the header",

            "required.description": "Enter the description",

            "required.readMore": "Enter the content",
            "required.cta1Heading": "Enter the CTA heading",
            "required.cta1Link": "Enter the link",
            "required.cta2Heading": "Enter the CTA heading",
            "required.cta2Link": "Enter the link",
            "required.backgroundImage": "Upload the background image",
            "required.backgroundImageAltTag": "Enter the alt tag",
            "required.topHeader": "Enter the top header",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: { ...this.state.errors, ...validator.errors.all() },
            });
            return;
        }
        console.log("serc");
        let backgroundImage = await this.uploadMediaFile(
            this.state.backgroundImage
        );

        let submitData = {
            bannerInfo: {
                header: {
                    content: this.state.header,
                },
                topHeader: {
                    content: this.state.topHeader,
                },
                description: {
                    content: this.state.description,
                },
                readMore: {
                    content: this.state.readMore,
                },
                cta: {
                    cta1: this.state.includeCta1
                        ? {
                              content: this.state.cta1Heading,
                              link: this.state.cta1Link,
                          }
                        : null,
                    cta2: this.state.includeCta2
                        ? {
                              content: this.state.cta2Heading,
                              link: this.state.cta2Link,
                          }
                        : null,
                },

                image: {
                    content: backgroundImage,
                    alt: this.state.backgroundImageAltTag,
                },
                topHeader: this.state.bannerType ? null : this.state.topHeader,
            },
            type: this.state.bannerType
                ? "cause-config"
                : "wishwa-talks-config",
        };

        MaterialService.postData("/v2/manager/banner/create", submitData)
            .then((response) => {
                if (response.status) {
                    toast.success("Banner Uploaded Successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    if (this.state.bannerType) {
                        history.push("/material-manager/banners-list");
                    } else {
                        // history.push("/marketing/banners/list/WishwaTalks");
                        history.push("/material-manager/banners-list");
                    }
                } else {
                    toast.error(response.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            })
            .catch((error) => {});
    };

    uploadMediaFile = async (data) => {
        const file = [{ name: data.name, type: data.type, size: data.size }];
        let uploadUrls = await WishwaTalkService.postData(
            "/events/manage/get-upload-urls",
            { files: file }
        );
        if (uploadUrls.status) {
            showPopup({
                title: "Uploading File",
                content: <LoaderSpinner />,
                buttons: [],
            });

            let uploadUrl = uploadUrls.data[0];

            let uploadImage = uploadUrl.mediaObject.data;

            showPopup({
                title: `Uploading File`,
                content: <LoaderSpinner />,
                buttons: [],
            });
            await Promise.all([
                new Promise((resolve, reject) => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("PUT", uploadUrl.s3Url.data.postUrl, true);
                    xhr.setRequestHeader("Content-Type", uploadImage.type);
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            return resolve(uploadUrl.s3Url.data.getUrl);
                        } else {
                            reject(null);
                        }
                    };
                    xhr.send(data);
                }),
            ]);

            closePopup();
            return uploadImage;
        }
    };

    onInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type == "checkbox") {
            this.setState({ [name]: event.target.checked });
            return;
        }
        this.setState({ [name]: value });
    };

    onFileChange = (event) => {
        let name = event.target.name;
        if (event.target.files[0].size > 1048576) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [name]: ["File size should be less than 1 MB"],
                },
            });

            return;
        } else {
            this.setState({
                [name]: event.target.files[0],
                errors: { ...this.state.errors, [name]: null },
            });
        }
    };

    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
    };

    showMessage = () => {
        showPopup({ title: "Info", content: <LinkInfo /> });
    };

    render() {
        return (
            <HrBlock title="Materials Banners ">
                <div className="banner-upload">
                    <h1 className="heading">Banner Creation</h1>
                    <div className="row">
                        <BsInput
                            showLimit
                            required
                            maxLength={100}
                            label="Header"
                            label2="Two Lines - Maximum 100 Characters"
                            type="text"
                            name="header"
                            value={this.state.header}
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            className="col-md-12 form-content"
                        />
                    </div>

                    <div className="row">
                        <BsArea
                            required
                            maxLength={150}
                            label="Description"
                            label2="2 lines - Maximum 150 Characters"
                            name="description"
                            placeholder="Enter description"
                            value={this.state.description}
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            rows="5"
                            className="col-md-12 form-content"
                        />
                    </div>

                    <div className="row">
                        <BsArea
                            required
                            maxLength={2000}
                            label="Read more content"
                            label2="Maximum 2000 Characters"
                            name="readMore"
                            value={this.state.readMore}
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            rows="5"
                            className="col-md-12 form-content"
                        />
                    </div>

                    <div className="row">
                        <div style={{ padding: "0px", marginLeft: "15px" }}>
                            <input
                                checked={this.state.includeCta1 ? true : false}
                                name="includeCta1"
                                onChange={this.onInputChange}
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <div
                            className={
                                "col-md-12 row cta-section" +
                                (this.state.includeCta1 ? "" : " disable")
                            }
                        >
                            <BsInput
                                showLimit
                                required
                                maxLength={20}
                                label="CTA 1 Heading"
                                label2="Maximum 20 Characters"
                                type="text"
                                name="cta1Heading"
                                value={this.state.cta1Heading}
                                onChange={this.onInputChange}
                                errors={this.state.errors}
                                className="col-md-5 form-content"
                            />

                            <div className="cta-link-input">
                                <BsInput
                                    required
                                    label="CTA 1 - Link"
                                    placeholder="CTA link"
                                    type="text"
                                    name="cta1Link"
                                    value={this.state.cta1Link}
                                    onChange={this.onInputChange}
                                    errors={this.state.errors}
                                    className="form-content"
                                />
                                <i
                                    className="fas fa-info-circle"
                                    onClick={this.showMessage}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div style={{ padding: "0px", marginLeft: "15px" }}>
                            <input
                                checked={this.state.includeCta2 ? true : false}
                                name="includeCta2"
                                onChange={this.onInputChange}
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <div
                            className={
                                "col-md-12 row cta-section" +
                                (this.state.includeCta2 ? "" : " disable")
                            }
                        >
                            <BsInput
                                showLimit
                                required
                                maxLength={20}
                                label="CTA 2 Heading"
                                label2="Maximum 20 Characters"
                                type="text"
                                name="cta2Heading"
                                value={this.state.cta2Heading}
                                onChange={this.onInputChange}
                                errors={this.state.errors}
                                className="col-md-5 form-content"
                            />

                            <div className="cta-link-input">
                                <BsInput
                                    required
                                    label="CTA 2 - Link"
                                    placeholder="Youtube link"
                                    type="text"
                                    name="cta2Link"
                                    value={this.state.cta2Link}
                                    onChange={this.onInputChange}
                                    errors={this.state.errors}
                                    className="form-content"
                                />
                                <i
                                    className="fas fa-info-circle"
                                    onClick={this.showMessage}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginLeft: "15px",
                            marginBottom: "5px",
                        }}
                    >
                        {this.state.bannerType
                            ? "Background Image(Full image right align)"
                            : "Background Image(500X400 right side)"}
                        <span className="required"> *</span>
                    </div>
                    <div className="row" style={{ display: "flex" }}>
                        <div
                            className="col-md-4"
                            style={{ paddingLeft: "0px" }}
                        >
                            <FileUploader
                                section_title=""
                                validators={{
                                    types: [".jpeg", ".png", ".tiff"],
                                }}
                                value={this.state.backgroundImage}
                                onChange={this.onFileChange}
                                name="backgroundImage"
                                title="Add Image"
                                desc=".jpeg, .png & .tiff File not more than 1 Mb"
                            />
                            <div
                                style={{
                                    marginLeft: "15px",
                                    marginBottom: "15px",
                                }}
                            >
                                {this.getErrorMessage("backgroundImage")}
                            </div>
                        </div>
                        <div
                            className="col-md-6"
                            style={{ alignSelf: "center" }}
                        >
                            <BsInput
                                required
                                label="Alt Tag"
                                type="text"
                                name="backgroundImageAltTag"
                                value={this.state.backgroundImageAltTag}
                                onChange={this.onInputChange}
                                errors={this.state.errors}
                                className="form-content"
                            />
                        </div>
                    </div>

                    {!this.state.bannerType ? (
                        <BsInput
                            required
                            showLimit
                            maxLength={25}
                            label="Top Header"
                            label2="Maximum 25 Characters"
                            type="text"
                            name="topHeader"
                            value={this.state.topHeader}
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            className="col-md-6 form-content"
                        />
                    ) : null}

                    <div className="buttons-block">
                        <div
                            className="btn btn-primary-inverse"
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            CANCEL
                        </div>
                        <div
                            className="btn btn-primary"
                            onClick={() => {
                                this.submitBanner();
                            }}
                        >
                            SAVE & UPLOAD
                        </div>
                    </div>
                </div>
            </HrBlock>
        );
    }
}

export default CreateBanner;

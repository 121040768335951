import React from "react";
import "./Pagination.css";

const Pagination = ({
    currentPage,
    totalPages,
    itemsPerPage,
    onPageChange,
    onItemsPerPageChange,
}) => {
    const handlePageChange = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const handleItemsPerPageChange = (event) => {
        onItemsPerPageChange(parseInt(event.target.value, 10));
    };

    return (
        <div className="pagination-container">
            <button
                className="pagination-btn prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                &lt;
            </button>
            {[...Array(totalPages)].map((_, index) => (
                <button
                    key={index + 1}
                    className={`pagination-btn ${
                        index + 1 === currentPage ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </button>
            ))}
            <button
                className="pagination-btn next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                &gt;
            </button>
            <select
                className="items-per-page-select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
            >
                <option value={10}>10 </option>
                <option value={20}>20 </option>
                <option value={30}>30 </option>
                <option value={50}>50 </option>
            </select>
        </div>
    );
};

export default Pagination;

import ApiService from "./Axios";
import { data } from "jquery";

const BASE_URL = "/campaign";

const postData = (url, data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + url,
        data: data,
    });
};

const updateCauseTalksSection = (causeId, data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + `/wishwa-talks/update-section-detail/${causeId}`,
        data: data,
    });
};

const updateCauseVideoDetail = (videoId, data) => {
    console.log("videoId", videoId);
    console.log("data", data);
    return ApiService.request({
        method: "POST",
        url: BASE_URL + `/wishwa-talks/update-video-detail/${videoId}`,
        data: data,
    });
};

const deleteWishwaTalkVideo = (videoId) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + `/wishwa-talks/delete-video-detail/${videoId}`,
        data: data,
    });
};

const getContributorsList = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/donation/get-contributors-list",
        data: data,
    });
};

// Chat APIS
const sendChatMessage = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/chat/send-message",
        data: data,
    });
};
const getChatMessages = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/chat/get-messages",
        data: data,
    });
};
//Chat Apis

const getAllWithdrawalRequests = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/withdrawal/get-all-requests",
        data: data,
    });
};

const getCauses = () => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/cause/list",
        data: {},
    });
};

const deleteMedia = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/registration/delete-media",
        data: data,
    });
};

const reportFundraiser = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/generic/report-the-campaign",
        data: data,
    });
};

const getManagerStats = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/get-manager-stats",
        data: data,
    });
};

const getDraftCampaign = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/draft/detail/" + id,
        data: {},
    });
};

const fetchData = (url) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + url,
        data: {},
    });
};

const getFundraiserSummary = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/draft/summary/" + id,
        data: {},
    });
};
const getBackendCampaign = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/backend/campaign/" + id,
        data: {},
    });
};
const getBackendReviewStatus = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/backend/reviewer/get-review-status/" + id,
        data: {},
    });
};
const getCampReviewStatus = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/backend/approver/get-reviewer-info/" + id,
        data: {},
    });
};

const getBackendApprovalStatus = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/backend/approver/get-review-status/" + id,
        data: {},
    });
};
const getBackendApprovalHistory = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/backend/approver/get-review-history/" + id,
        data: {},
    });
};

const getActiveFundraisers = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/list/active-fundraisers",
        data: data,
    });
};

const getOngoingFundraisers = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/list/ongoing-fundraisers",
        data: data,
    });
};

const postUpdatePreference = (id, slug, status) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/preferences/" + slug,
        data: {
            campaignId: id,
            status: status,
        },
    });
};
const postCreateOrder = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/donation/create-order",
        data: data,
    });
};

const postCreateFundraiserOrder = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/donation/create-fundraiser-order",
        data: data,
    });
};

const postCreateSchedule = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/donation/create-schedule",
        data: data,
    });
};

const pauseSchedule = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/dashboard/donate-money/pause-scheduled-donation",
        data: data,
    });
};
const stopSchedule = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/dashboard/donate-money/stop-scheduled-donation",
        data: data,
    });
};
const resumeSchedule = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/dashboard/donate-money/resume-scheduled-donation",
        data: data,
    });
};

const UploadOrgMediaFile = (file, orgid, isPrivate = 0, id = null) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("id", orgid);

    return ApiService.request({
        method: "post",
        url: BASE_URL + "/campaign/upload-review-file",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
const UploadWithdrawalFile = (file, campaignId, isPrivate = 0, id = null) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("id", campaignId);

    return ApiService.request({
        method: "post",
        url: BASE_URL + "/campaign/upload-withdrawal-document",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const postUpdate = (
    id,
    message,
    title = "title",
    mediaids = [],
    isEdit = null
) => {
    if (isEdit) {
        return ApiService.request({
            method: "POST",
            url: BASE_URL + "/updates/edit-update",
            data: {
                title: title,
                message: message,
                mediaIds: mediaids,
                campaignId: id,
                updateId: isEdit,
            },
        });
    } else {
        return ApiService.request({
            method: "POST",
            url: BASE_URL + "/updates/add-update",
            data: {
                title: title,
                message: message,
                mediaIds: mediaids,
                campaignId: id,
            },
        });
    }
};

const postComment = (campaignId, comment = "") => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/post-comment",
        data: {
            campaignId: campaignId,
            comment: comment,
        },
    });
};

const reportComment = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/report-comment",
        data: data,
    });
};

const likeComment = (commentId) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/like-comment",
        data: {
            id: commentId,
        },
    });
};

const dislikeComment = (commentId) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/dislike-comment",
        data: {
            id: commentId,
        },
    });
};

const SaveApproveCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/saveReview",
        data: data,
    });
};
const SaveReviewCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/reviewer/saveReview",
        data: data,
    });
};
const rejectReviewCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/reviewer/rejectCampaign",
        data: data,
    });
};
const approveReviewCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/reviewer/approveCampaign",
        data: data,
    });
};
const dataRequestCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/reviewer/dataRequestCampaign",
        data: data,
    });
};

const rejectApproveCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/rejectCampaign",
        data: data,
    });
};
const approveApproveCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/approveCampaign",
        data: data,
    });
};
const dataRequestCampaignApprover = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/dataRequestCampaign",
        data: data,
    });
};

const saveCampaignDetails = (data, id) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/registration/campaign-details",
        data: data,
    });
};

const saveBeneficiary = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/registration/beneficiary-details",
        data: data,
    });
};

const saveComplianceDetails = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/registration/compliance-details",
        data: data,
    });
};

const saveAndSubmitForReview = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/registration/submit-review",
        data: data,
    });
};

const saveFundraiserStory = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/registration/details-documents",
        data: data,
    });
};
const saveFundraiserVideo = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/campaign/registration/saveVideo",
        data: data,
    });
};

const getFundraiserStory = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/" + id + "/details-documents",
    });
};

const getComplianceDetails = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/" + id + "/get-compliance-details",
        data: {},
    });
};

const getBeneficiaries = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/" + id + "/beneficiaries",
        data: {},
    });
};

const getWithdrawalOptions = (id) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/withdrawal/drop-down-list",
        data: {
            campaignId: id,
        },
    });
};

const postWithdrawalRequest = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/withdrawal/request",
        data: data,
    });
};

const approveUpdate = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/manager/approve-update",
        data: data,
    });
};

const rejectUpdate = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/manager/reject-update",
        data: data,
    });
};

const approveWithdrawal = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/manager/approve-withdrawal-request",
        data: data,
    });
};

const rejectWithdrawal = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/manager/reject-withdrawal-request",
        data: data,
    });
};

const approveChangedTarget = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/approve-target-change",
        data: data,
    });
};

const rejectChangedTarget = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/reject-target-change",
        data: data,
    });
};

const approveStopCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/approve-stop-campaign",
        data: data,
    });
};

const rejectStopCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/approver/reject-stop-campaign",
        data: data,
    });
};

const getCommentThread = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/comment-thread/get-thread-comment",
        data: data,
    });
};

const postCommentThread = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/comment-thread/post-to-thread",
        data: data,
    });
};

const generateReceipt = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/donation/generate-receipt",
        data: data,
    });
};

const verifyWithdrawalOTP = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/withdrawal/verify-withdrawal-confirmation-otp",
        data: data,
    });
};
const changeTargetRequest = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/dashboard/management/change-target-request",
        data: data,
    });
};

const superAdminTargetChange = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/super-admin/change-target-end-date",
        data: data,
    });
};

const managerTargetChange = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/manager/change-target-end-date",
        data: data,
    });
};

const changeCampaignManager = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/super-admin/change-campaign-manager",
        data: data,
    });
};

const deleteCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/super-admin/delete-campaign",
        data: data,
    });
};

const uploadBigFile = (campaignId, fileObj, percentageSetter = null) => {
    return new Promise((resolve, reject) => {
        return ApiService.request({
            method: "POST",
            url: BASE_URL + `/upload-video-url`,
            data: {
                campaignId: campaignId,
                fileName: fileObj.name,
            },
        }).then((response) => {
            if (response.status) {
                let mediaResponse = response.data.data;
                var xhr = new XMLHttpRequest();
                xhr.open("PUT", mediaResponse.postUrl, true);
                xhr.setRequestHeader("Content-Type", fileObj.type);
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        ApiService.request({
                            method: "POST",
                            url: BASE_URL + `/media-upload`,
                            data: {
                                filePath: mediaResponse.filePath,
                                fileSize: fileObj.size,
                                campaignId: campaignId,
                            },
                        })
                            .then((finalResponse) => {
                                console.log(finalResponse);

                                resolve(finalResponse);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        reject({
                            status: false,
                            message: xhr.responseText,
                            data: null,
                        });
                    }
                };
                xhr.onerror = () => {
                    reject({
                        status: false,
                        message: "",
                        data: null,
                    });
                };

                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        var percent = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        console.log("percent:", percent);
                        if (percentageSetter) percentageSetter(percent);
                    }
                };
                xhr.send(fileObj);
            } else {
                reject({
                    status: false,
                    message: "",
                    data: null,
                });
            }
        });
    });
};

const superAdminStopCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/super-admin/stop-campaign",
        data: data,
    });
};

const stopCampaignRequest = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/dashboard/management/stop-the-campaign",
        data: data,
    });
};

const takeDownCampaign = (data) => {
    return ApiService.request({
        method: "POST",
        url:
            BASE_URL +
            "/backend/manager/approve-reported-campaign-inactivation",
        data: data,
    });
};

const rejectReports = (data) => {
    return ApiService.request({
        method: "POST",
        url:
            BASE_URL + "/backend/manager/reject-reported-campaign-inactivation",
        data: data,
    });
};

const uploadVideoThumbnail = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/generic/upload-video-thumbnail",
        data: data,
    });
};

const fetchComments = (id, pageNo = 1) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/campaign/list-comments/" + id + "?pageNo=" + pageNo,
        data: {},
    });
};

const getS3Url = (data, api) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + api,
        data: data,
    });
};

const addSuccessStory = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/user-stories/add-story",
        data: data,
    });
};

const updateSuccessStory = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/user-stories/update-story",
        data: data,
    });
};
const addCelebrateSuccessStory = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/celebration-stories/add-story",
        data: data,
    });
};
const updateCelebrateSuccessStory = (data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/celebration-stories/update-story ",
        data: data,
    });
};

const storyPushToTop = (id) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/user-stories/push-story",
        data: { id: id },
    });
};

const getMaterialStories = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/user-stories/list?source=materials",
        data: {},
    });
};

const getAcknowledgeCertificate = (name) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/donation/donation-acknowledge",
        data: {
            name: name,
        },
        headers: {
            "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
    });
};

const getLatestCelbrations = () => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + "/celebration-stories/list",
        headers: {
            "Content-Type": "application/json",
        },
    });
};

const updateStatus = (id, newStatus) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/backend/celebration-stories/update",
        data: {
            id,
            newStatus,
        },
    });
};

const getCelebrationStory = (id) => {
    return ApiService.request({
        method: "GET",
        url: BASE_URL + `/backend/celebration-stories/${id}`,
    });
};

const CampaignService = {
    getMaterialStories: getMaterialStories,
    postWithdrawalRequest: postWithdrawalRequest,
    getWithdrawalOptions: getWithdrawalOptions,
    getCauses: getCauses,
    getDraftCampaign: getDraftCampaign,
    saveCampaignDetails: saveCampaignDetails,
    saveBeneficiary: saveBeneficiary,
    getBeneficiaries: getBeneficiaries,
    saveComplianceDetails: saveComplianceDetails,
    saveFundraiserStory: saveFundraiserStory,
    getComplianceDetails: getComplianceDetails,
    getFundraiserStory: getFundraiserStory,
    getFundraiserSummary: getFundraiserSummary,
    saveAndSubmitForReview: saveAndSubmitForReview,
    rejectReviewCampaign: rejectReviewCampaign,
    approveReviewCampaign: approveReviewCampaign,
    dataRequestCampaign: dataRequestCampaign,
    SaveReviewCampaign: SaveReviewCampaign,
    getBackendReviewStatus: getBackendReviewStatus,
    getBackendCampaign: getBackendCampaign,
    getBackendApprovalStatus: getBackendApprovalStatus,
    getBackendApprovalHistory: getBackendApprovalHistory,
    fetchData: fetchData,
    postComment: postComment,
    likeComment: likeComment,
    postUpdate: postUpdate,
    postUpdatePreference: postUpdatePreference,
    postCreateOrder: postCreateOrder,
    getActiveFundraisers: getActiveFundraisers,
    getOngoingFundraisers: getOngoingFundraisers,
    UploadOrgMediaFile: UploadOrgMediaFile,
    rejectApproveCampaign: rejectApproveCampaign,
    approveApproveCampaign: approveApproveCampaign,
    dataRequestCampaignApprover: dataRequestCampaignApprover,
    getContributorsList: getContributorsList,
    getAllWithdrawalRequests: getAllWithdrawalRequests,
    approveUpdate: approveUpdate,
    rejectUpdate: rejectUpdate,
    approveWithdrawal: approveWithdrawal,
    rejectWithdrawal: rejectWithdrawal,
    UploadWithdrawalFile: UploadWithdrawalFile,
    approveChangedTarget: approveChangedTarget,
    rejectChangedTarget: rejectChangedTarget,
    approveStopCampaign: approveStopCampaign,
    rejectStopCampaign: rejectStopCampaign,
    getCommentThread: getCommentThread,
    postCommentThread: postCommentThread,
    generateReceipt: generateReceipt,
    verifyWithdrawalOTP: verifyWithdrawalOTP,
    getManagerStats: getManagerStats,
    getCampReviewStatus: getCampReviewStatus,
    SaveApproveCampaign: SaveApproveCampaign,
    changeTargetRequest: changeTargetRequest,
    changeCampaignManager: changeCampaignManager,
    deleteCampaign: deleteCampaign,
    postCreateSchedule: postCreateSchedule,
    pauseSchedule: pauseSchedule,
    stopSchedule: stopSchedule,
    resumeSchedule: resumeSchedule,
    sendChatMessage: sendChatMessage,
    getChatMessages: getChatMessages,
    uploadBigFile: uploadBigFile,
    reportFundraiser: reportFundraiser,
    superAdminTargetChange: superAdminTargetChange,
    superAdminStopCampaign: superAdminStopCampaign,
    stopCampaignRequest: stopCampaignRequest,
    managerTargetChange: managerTargetChange,
    takeDownCampaign: takeDownCampaign,
    rejectReports: rejectReports,
    uploadVideoThumbnail: uploadVideoThumbnail,
    deleteMedia: deleteMedia,
    saveFundraiserVideo,
    fetchComments,
    updateCauseTalksSection,
    updateCauseVideoDetail,
    deleteWishwaTalkVideo,
    getS3Url,
    addSuccessStory,
    updateSuccessStory,
    addCelebrateSuccessStory,
    updateCelebrateSuccessStory,
    postData,
    storyPushToTop,
    getAcknowledgeCertificate,
    getLatestCelbrations,
    postCreateFundraiserOrder,
    updateStatus,
    getCelebrationStory,
    reportComment,
    dislikeComment,
};
export { getCauses, postCreateSchedule, fetchData };
export default CampaignService;

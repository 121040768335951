import React, { Component } from "react";
import "./addSuccessStory.css";
import CampaignService from "../../../services/CampaignService";
import history from "../../../history";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
import Validator from "validatorjs";
import {
    FileUploader,
    BsInput,
    BsArea,
    BsRichText,
} from "../../../components/Form/FormComponents";
import { Form } from "react-bootstrap";
import { showPopup } from "../../../helpers/dispatcher";

class SuccessStory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            causeList: [],
            cause: -1,
            status: -1,
            storyContent: "",
            order: 1,
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.submitStoryDetails = this.submitStoryDetails.bind(this);
        this.reactQuillRef = React.createRef();
        this.files = [];
        this.previews = [];
    }

    componentDidMount() {
        //let newState = {};
        CampaignService.getCauses().then((resp) => {
            this.setState({ causeList: resp.data });
        });
    }

    submitStoryDetails() {
        const rules = {
            title: "required|string|min:20",
            storyDesc: "required",
            storyContent: "required",
            cause: "required|numeric|min:1",
            status: "required|numeric|min:0",
            order: "required|numeric",
            storyImage: "required",
            bannerImage: "required",
        };

        const messages = {
            "required.title": "Story title is required",
            "max.title": "Story title should be minimum of 20 characters ",
            "required.storyDesc": "Story description is required",
            "required.storyContent": "Story content is required",
            "required.cause": "Select a Cause",
            "numeric.cause": "Select a Cause",
            "min.cause": "Select a Cause",
            "required.status": "Select a Status",
            "numeric.status": "Select a Status",
            "min.status": "Select a Status",
            "required.order": "Specify an order",
            "numeric.order": "Order should be numeric",
            "required.storyImage": "Upload a story image",
            "required.bannerImage": "Upload a banner image",
        };

        let postData = {
            ...this.state,
            storyContent: this.reactQuillRef.current.getEditorContents(),
        };

        let validator = new Validator(postData, rules, messages);

        if (validator.fails()) {
            if (this.state.order < 0) {
                this.setState({
                    errorMessage: "",
                    errors: {
                        order: ["Order cannot be less than zero"],
                    },
                });
                return;
            }
            this.setState({
                errorMessage: "",
                errors: validator.errors.all(),
            });

            return;
        } else {
            this.setState({ errors: "" });
            postData = {
                source:'materials',
                title: this.state.title,
                storyDesc: this.state.storyDesc,
                cause: this.state.cause,
                status: this.state.status,
                order: this.state.order,
                bannerImage: this.files["bannerImage"].name,
                cardImage: this.files["storyImage"].name,
                storyContent: this.reactQuillRef.current.getEditorContents(),
            };
            CampaignService.addSuccessStory(postData).then((resp) => {
                if (resp.status) {
                    showPopup({
                        title: "Uploaing Files",
                        content: <LoaderSpinner />,
                        buttons: [],
                    });

                    let promise1 = new Promise((resolve, reject) => {
                        var xhr = new XMLHttpRequest();
                        xhr.open(
                            "PUT",
                            resp.data.bannerImage.data.postUrl,
                            true
                        );
                        xhr.setRequestHeader(
                            "Content-Type",
                            this.files["bannerImage"].type
                        );
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                return resolve(
                                    resp.data.bannerImage.data.getUrl
                                );
                            } else {
                                reject(null);
                            }
                        };
                        xhr.send(this.files["bannerImage"]);
                    });

                    let promise2 = new Promise((resolve, reject) => {
                        var xhr = new XMLHttpRequest();
                        xhr.open("PUT", resp.data.cardImage.data.postUrl, true);
                        xhr.setRequestHeader(
                            "Content-Type",
                            this.files["storyImage"].type
                        );
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                return resolve(resp.data.cardImage.data.getUrl);
                            } else {
                                reject(null);
                            }
                        };
                        xhr.send(this.files["storyImage"]);
                    });

                    return Promise.all([promise1, promise2]).then((values) => {
                        showPopup({
                            title: "Success story added succesfully",
                            content: "",
                            onClose: () => {
                                history.push(
                                    "/material-manager/success-stories-lists"
                                );
                            },
                        });
                    });
                }
            });
        }

        console.log("story details:", this.state);
    }

    onInputChange(event) {
        this.setState({
            errorMessage: "",
            [event.target.name]: event.target.value,
        });
    }

    onFileChange(event) {
        console.log(event);
        this.setState({
            errorMessage: "",
            [event.target.name]: event.target.value,
        });
        this.files[event.target.name] = event.target.files[0];

        var reader = new FileReader();
        var url = reader.readAsDataURL(event.target.files[0]);

        reader.onloadend = function (e) {
            this.previews[event.target.name] = [reader.result];
            this.setState({ msg: new Date() });
        }.bind(this);
    }

    getErrorMessage(target) {
        if (this.state.errors && this.state.errors[target]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[target]}
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div id="successStoryId">
                <div className="form-title">Add/Edit a success story </div>
                <div className="col-md-12" style={{ fontWeight: "bold" }}>
                    <BsInput
                        required
                        label="Title"
                        label2="Maximum 50 Characters"
                        value={this.state.title}
                        placeholder="Enter title for the success story"
                        name="title"
                        onChange={this.onInputChange}
                        errors={this.state.errors}
                    />
                </div>
                <div
                    className="col-md-12"
                    style={{ fontWeight: "bold", marginTop: "25px" }}
                >
                    <BsArea
                        required
                        label="Short description of the success story"
                        placeholder="Enter a short description of the success story "
                        maxLength={300}
                        value={this.state.storyDesc}
                        name="storyDesc"
                        onChange={this.onInputChange}
                        errors={this.state.errors}
                        rows="5"
                    />
                </div>
                <div className="col-md-12">
                    <BsRichText
                        S3RequestUrl={"/backend/user-stories/get-s3-url"}
                        required
                        label="Content of the success story"
                        name="storyContent"
                        rows="5"
                        quillRef={this.reactQuillRef}
                    />
                </div>
                <div className="row">
                    <div className="col-md-4" style={{ fontWeight: "bold" }}>
                        <Form.Group>
                            <Form.Label>
                                Cause <span className="required">*</span>
                            </Form.Label>
                            <Form.Control
                                as="select"
                                name="cause"
                                value={this.state.cause}
                                defaultValue={this.state.cause}
                                onChange={this.onInputChange}
                            >
                                <option value="-1">Select Cause</option>
                                {this.state.causeList.map((cause) => {
                                    return (
                                        <option value={cause.id}>
                                            {cause.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                            {this.getErrorMessage("cause")}
                        </Form.Group>
                    </div>
                    <div className="col-md-4" style={{ fontWeight: "bold" }}>
                        <Form.Group>
                            <Form.Label>
                                Active Status{" "}
                                <span className="required">*</span>
                            </Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={this.state.status}
                                defaultValue={this.state.status}
                                onChange={this.onInputChange}
                            >
                                <option value="-1">Select Status</option>
                                <option value="0">Draft</option>
                                <option value="1">Active</option>
                                <option value="2">Inactive</option>
                            </Form.Control>
                            {this.getErrorMessage("status")}
                        </Form.Group>
                    </div>
                    {/* <div className="col-md-4" style={{ fontWeight: 'bold' }}>
						<BsInput
							required
							label="Order"
							value={this.state.order || 0}
							type="number"
							placeholder="Enter a number"
							name="order"
							onChange={this.onInputChange}
							errors={this.state.errors}
							style={{ border: '2px solid red' }}
						/>
					</div> */}
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-4" style={{ fontWeight: "bold" }}>
                        <FileUploader
                            required
                            section_title="Success Story Image"
                            section_title2=""
                            value={this.state.storyImage}
                            onChange={this.onFileChange}
                            validators={{ types: ["image/*"] }}
                            name="storyImage"
                            title="ADD PICTURE"
                            desc="(Accepted file format - .jpg , .png & Tiff Max size 3mb)"
                        />
                        {this.getErrorMessage("storyImage")}
                    </div>

                    <div className="col-md-4" style={{ fontWeight: "bold" }}>
                        <FileUploader
                            required
                            section_title="Success Story Banner"
                            section_title2=""
                            validators={{ types: ["image/*"] }}
                            value={this.state.bannerImage}
                            onChange={this.onFileChange}
                            name="bannerImage"
                            title="ADD BANNER IMAGE"
                            desc="(Accepted file format - .jpg Max size 10mb)"
                        />
                        {this.getErrorMessage("bannerImage")}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        {(() => {
                            if (this.state.storyImage) {
                                return (
                                    <img
                                        src={
                                            this.previews["storyImage"] ||
                                            "/images/profile-ph.png"
                                        }
                                        style={{
                                            padding: "10px",
                                            width: "100%",
                                            borderRadius: "15px",
                                        }}
                                    />
                                );
                            }
                        })()}
                    </div>

                    <div className="col-md-4">
                        {(() => {
                            if (this.state.bannerImage) {
                                return (
                                    <img
                                        src={
                                            this.previews["bannerImage"] ||
                                            "/images/profile-ph.png"
                                        }
                                        style={{
                                            padding: "10px",
                                            width: "100%",
                                            borderRadius: "15px",
                                        }}
                                    />
                                );
                            }
                        })()}
                    </div>
                </div>

                <div className="buttons-section">
                    <div className="cancel-button">CANCEL</div>
                    <div
                        className="btn-primary"
                        onClick={this.submitStoryDetails}
                    >
                        UPDATE
                    </div>
                </div>
            </div>
        );
    }
}

export default SuccessStory;

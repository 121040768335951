import { useEffect, useState } from "react";
import "./index.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import CampaignService from "../../../../services/CampaignService";
import moment from "moment";
import Pagination from "./components/Pagination";
import DateInput from "./components/DateInput";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const DonationTable = ({ donationData }) => (
    <div className="donation-list">
        <table>
            <thead>
                <tr>
                    <th>Donated On</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Amount Donated</th>
                    <th>Payment Mode</th>
                    <th>PG Transaction</th>
                </tr>
            </thead>
            <tbody>
                {donationData &&
                    donationData.map((donation) => (
                        <tr key={donation.id}>
                            <td>
                                {donation.completionTime
                                    ? moment(donation.completionTime).format(
                                          "DD MMM YYYY"
                                      )
                                    : "N/A"}
                            </td>
                            <td>{donation.donorInfo.name}</td>
                            <td>{donation.donorInfo.email || "N/A"}</td>
                            <td>{donation.donorInfo.mobile}</td>
                            <td>{donation.amount}</td>
                            <td>
                                {
                                    donation.miscInfo.transactionDetails
                                        .paymentMode
                                }
                            </td>
                            <td>{donation.paymentReference || "N/A"}</td>
                        </tr>
                    ))}
            </tbody>
        </table>
    </div>
);

export default function DonationReport() {
    const [donationData, setDonationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [noResults, setNoResults] = useState(false);

    const fetchDonationData = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const res = await CampaignService.fetchData(
                `/backend/material-donations/report?searchTerm=${searchTerm}&from=${fromDate}&to=${toDate}&page=${page}&limit=${itemsPerPage}`
            );

            setDonationData(res.data?.materialDonations);
            setTotalPages(res.data?.pagination?.totalPages);
            setCurrentPage(page);
            setNoResults(res.data?.materialDonations?.length === 0);
        } catch (error) {
            console.log("error occurred while fetching", error);
            setError("Failed to fetch data");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDonationData();
    }, [searchTerm, fromDate, toDate, currentPage, itemsPerPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchDonationData(pageNumber);
    };

    const handleDownload = () => {
        if (!donationData.length) return;

        const worksheet = XLSX.utils.json_to_sheet(
            donationData.map((donation) => ({
                "Donated On": donation.completionTime
                    ? moment(donation.completionTime).format("DD MMM YYYY")
                    : "N/A",
                "Full Name": donation.donorInfo.name,
                Email: donation.donorInfo.email || "N/A",
                Mobile: donation.donorInfo.mobile,
                "Amount Donated": donation.amount,
                "Payment Mode":
                    donation.miscInfo.transactionDetails.paymentMode,
                "PG Transaction": donation.paymentReference || "N/A",
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Donations");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const file = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(file, "Donation_Report.xlsx");
    };

    const handleReset = () => {
        setSearchTerm("");
        setFromDate("");
        setToDate("");
        fetchDonationData(); // Fetch all data with cleared filters
    };

    return (
        <div id="materials-manager-donation-report">
            <div className="heading">
                <h1>Donor details</h1>
            </div>
            <div className="search-date">
                <div className="back-btn">
                    <FaArrowLeftLong style={{ marginTop: "4px" }} />
                    <p>Back</p>
                </div>
                <div className="filter-section">
                    <div>
                        <input
                            type="text"
                            className="searchbar"
                            placeholder="Search Donation Name/Mobile"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="date-filters">
                        <DateInput
                            placeholder="From Date"
                            value={fromDate}
                            onChange={(date) =>
                                setFromDate(
                                    date
                                        ? moment(date).format("YYYY-MM-DD")
                                        : ""
                                )
                            }
                        />
                        <DateInput
                            placeholder="To Date"
                            value={toDate}
                            onChange={(date) =>
                                setToDate(
                                    date
                                        ? moment(date).format("YYYY-MM-DD")
                                        : ""
                                )
                            }
                        />
                    </div>
                </div>
            </div>

            {loading && (
                <div className="loader">
                    <p>Loading...</p>
                </div>
            )}

            {error && (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            )}

            {!loading && !error && donationData.length === 0 && !noResults && (
                <div className="no-records">
                    <p>No records found</p>
                </div>
            )}

            {!loading && noResults && (
                <div className="no-results">
                    <p>
                        No results are found.{" "}
                        <p
                            onClick={handleReset}
                            style={{
                                color: "blue",
                                cursor: "pointer",
                            }}
                        >
                            Click here to Reset search/filter
                        </p>
                    </p>
                </div>
            )}

            {donationData && donationData.length > 0 && (
                <DonationTable donationData={donationData} />
            )}

            <div className="bottom-section">
                <div className="bottom-actions">
                    <div className="action-btns">
                        <button onClick={handleReset}>CANCEL</button>
                        <button onClick={handleDownload}>DOWNLOAD</button>
                    </div>
                </div>
                {!loading && !error && donationData.length > 0 && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        itemsPerPage={itemsPerPage}
                        onItemsPerPageChange={(val) => setItemsPerPage(val)}
                    />
                )}
            </div>
        </div>
    );
}

import React, { Component } from "react";
import "./style.css";
import history from "../../../history";

class HrBlock extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="hrBlockId">
                <div
                    className="back"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <i
                        className="fas fa-arrow-left"
                        style={{ marginRight: "5px" }}
                    />
                    Back
                </div>

                <div className="block-title">{this.props.title}</div>

                {this.props.children}
            </div>
        );
    }
}

export default HrBlock;

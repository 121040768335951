import React, { Component } from "react";
import "./materialRequestsDetails.css";
import "./pickUpRequestDetails.css";
import HeadingCompnonet from "./HeadingComponent";
import { BsArea } from "../../../components/Form/FormComponents";
import history from "../../../history";
import { toast } from "react-toastify";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
import moment from "moment";
const MaterialService = require("../../../services/MaterialService");

class MaterialRequestsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materialRequest: null,
            loaded: false,
            volunteerList: [],
            selectedVolunteer: "",
        };
    }

    componentDidMount() {
        this.fetchData();
        this.volunteerLists(); 
    }

    fetchData = () => {
        MaterialService.fetchData(
            "/v2/manager/material-requests/detail/" + this.props.match.params.id
        ).then((res) => {
            if (res.status) {
                this.setState({
                    materialRequest: res.data,
                    loaded: true,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };

    volunteerLists = async () => {
        try {
            const res = await MaterialService.fetchData(
                `/v2/manager/volunteer/active-list`
            );
            // console.log("Volunteers Response:", res);
            if (res.status) {
                this.setState({
                    volunteerList: res.data,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            toast.error("Failed to fetch volunteers", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    handleVolunteerChange = (event) => {
        this.setState({ selectedVolunteer: event.target.value });
    };

    render() {
        if (!this.state.loaded) return <LoaderSpinner />;

        return (
            <div id="pickUpRequests" className="main-container">
                <HeadingCompnonet mainTitle="Material requests details" />
                <div className="mian-pick-upcontainer">
                    <div className="mian-heading">Requester details</div>
                    <div className="details-container">
                        <div className="details-text">Name</div>
                        <div className="details-Name">
                            {this.state.materialRequest.miscInfo.primary_name}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="details-text">Org name</div>
                        <div className="details-Name">
                            {this.state.materialRequest.miscInfo.org_name}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="details-text">Email</div>
                        <div className="details-Name">
                            {this.state.materialRequest.miscInfo.email}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="details-text">Mobile</div>
                        <div className="details-Name">
                            {this.state.materialRequest.miscInfo.mobile_no}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="details-text">Category & Quantity</div>
                        <div className="details-Name">
                            {this.state.materialRequest.miscInfo.materials.map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        className="details-name-container"
                                    >
                                        <div className="details-name-container-material-name">
                                            {item.description}
                                        </div>
                                        <div className="details-name-container-material-counts">
                                            {item.quantity}
                                        </div>
                                        <div className="banner-edit-button">
                                            <i className="fas fa-pencil-alt"></i>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="details-text">Requested date</div>
                        <div className="details-Name">
                            {moment(
                                this.state.materialRequest.createdAt
                            ).format("DD MMM YYYY")}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="details-text">Pick up location</div>
                        <div className="details-Name">
                            {this.state.materialRequest.locality.formatted}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="details-text">
                            Requester Uploaded images
                        </div>
                    </div>
                    <div className="donar-images-container">
                        <img
                            src={
                                "//" +
                                this.state.materialRequest.image.storageURL
                            }
                            className="donar-images"
                            alt="Requester uploaded"
                        />
                    </div>
                    <div className="details-container">
                        <div className="details-text">Assign volunteer</div>
                        <select
                            className="donar-selection"
                            value={this.state.selectedVolunteer}
                            onChange={this.handleVolunteerChange}
                        >
                            <option>Select the volunteer</option>
                            {this.state.volunteerList.map((volunteer) => (
                                <option key={volunteer.id} value={volunteer.id}>
                                    {volunteer.fullName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="details-container">
                        <div className="details-text">Status</div>
                        <select className="donar-selection-status">
                            <option>Requested</option>
                            <option>Pickup scheduled</option>
                            <option>Pickup Complete</option>
                            <option>Received by Wishwa</option>
                            <option>Received by NGO</option>
                            <option>Closed</option>
                            <option>No Service</option>
                            <option>Donor Not Available</option>
                        </select>
                    </div>
                    <div className="col-md-12 no-padding">
                        <BsArea
                            required
                            label="Note to the Volunteer"
                            label2="Maximum 200 Characters"
                            value={this.state.beneficiary_desc}
                            className="col-md-6 no-padding"
                            name="beneficiary_desc"
                            onChange={this.onInputchange}
                            errors={this.props.errors}
                            rows="5"
                            maxLength={200}
                            placeholder="Note to the volunteer"
                        />
                    </div>
                    <div className="banners-buttons">
                        <div className="save-button" onClick={() => {}}>
                            Update
                        </div>
                        <div
                            className="cancel-button"
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaterialRequestsDetails;

import moment from "moment";
import { Component } from "react";
import { toast } from "react-toastify";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
import "./partnersLists.css";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

const MaterialService = require("../../../services/MaterialService");

class PartnersLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partnersLists: [],
            filteredPartnersLists: [], // Add this to manage filtered list
            selectedStatus: "all", // State for status filtering
        };
    }

    componentDidMount = () => {
        this.fetchData();
    };

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/partner/list").then((res) => {
            if (res.status) {
                this.setState({
                    partnersLists: res.data,
                    filteredPartnersLists: res.data, // Initially set filtered to full list
                    downloadKey: res.meta.downloadKey,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };

    filterData = () => {
        const { partnersLists, selectedStatus } = this.state;

        const filteredPartnersLists =
            selectedStatus === "all"
                ? partnersLists
                : partnersLists.filter(
                      (partner) => partner.status === Number(selectedStatus)
                  );

        this.setState({ filteredPartnersLists });
    };

    onStatusFilterChange = (event) => {
        this.setState({ selectedStatus: event.target.value }, this.filterData);
    };

    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/partner/status-update", {
            id: id,
            status: status,
        }).then((res) => {
            callback();
        });
    };

    handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(
            this.state.filteredPartnersLists.map((request) => ({
                RequestedOn: moment(request.createdAt).format("DD MMM YYYY"),
                FullName: request.fullName || "",
                Email: request.email || "",
                Relationship: request.relationship || "",
                Mobile: request.phone || "",
                Status: request.status == "1" ? "Active" : "Inactive",
            }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Material Requests");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "material_requests.xlsx"
        );
    };

    render() {
        console.log(this.state);
        return (
            <div className="main-container" id="partnersLists">
                <div>
                    <HeadingCompnonet
                        mainTitle="Our Partner"
                        title="ADD Partner"
                        path="/material-manager/add-partner"
                    />

                    <div className="main-partner-list-container">
                        <div className="main-partner-lists-heading">
                            List of our partners
                        </div>
                        <div className="heading-container">
                            <div className="grid-header">Created on</div>
                            <div className="grid-header">Full Name</div>
                            <div className="grid-header">Relationship</div>
                            <div className="grid-header emailItem">Email</div>
                            <div className="grid-header">Mobile</div>
                            <div className="grid-header">
                                <select
                                    value={this.state.selectedStatus}
                                    onChange={this.onStatusFilterChange}
                                    style={{
                                        border: 0,
                                        background: "white",
                                        fontWeight: 600,
                                        color: "#1E1053",
                                    }}
                                >
                                    <option value="all">Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                            <div
                                className="grid-header"
                                style={{ textAlign: "center" }}
                            >
                                Edit
                            </div>
                        </div>
                        <div className="drop-off-details-container">
                            {this.state.filteredPartnersLists.length > 0 ? (
                                this.state.filteredPartnersLists.map((list) => (
                                    <div
                                        className="partners-details-contents"
                                        key={list.id}
                                    >
                                        <div className="grid-item">
                                            {moment(list.createdDate).format(
                                                "DD-MMM-YYYY"
                                            )}
                                        </div>
                                        <div className="grid-item">
                                            {list.fullName}
                                        </div>
                                        <div className="grid-item">
                                            {list.relationship || "unavailable"}
                                        </div>
                                        <div className="grid-item emailItem">
                                            {list.email}
                                        </div>
                                        <div className="grid-item">
                                            {list.phone}
                                        </div>
                                        <select
                                            className="grid-item select-dropdown"
                                            name="status"
                                            value={list.status}
                                            onChange={(event) =>
                                                this.updateStatus(
                                                    list.id,
                                                    event.target.value,
                                                    this.fetchData
                                                )
                                            }
                                        >
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                            <option value="2">Requested</option>
                                        </select>
                                        <div className="grid-item banner-edit-button">
                                            <i
                                                className="fas fa-pencil-alt"
                                                onClick={() =>
                                                    history.push(
                                                        `/material-manager/add-partner/${list.id}`
                                                    )
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="alert alert-warning no-data">
                                    No Data Found
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="banners-buttons">
                        <div
                            className="cancel-button"
                            style={{ cursor: "pointer" }}
                        >
                            cancel
                        </div>
                        <div
                            className="save-button"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.handleDownload();
                            }}
                        >
                            download
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PartnersLists;

import React, { Component } from "react";
// import { act } from "react-dom/test-utils";
import { connect } from "react-redux";

import * as actions from "../../store/actions";
import "./signin.css";
import axios from "axios";
import { toast } from "react-toastify";
import MobileOTPScreen from "./MobileOTPScreen";
import history from "../../history";

class SignInWindow extends Component {
    constructor() {
        super();
        this.state = {
            form_state: "signin",
            showPassword: false,
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.onEmailLogin = this.onEmailLogin.bind(this);
        this.onOTPRequest = this.onOTPRequest.bind(this);
        this.onForgotPassword = this.onForgotPassword.bind(this);
        this.emailBox = React.createRef();
    }

    onForgotPassword(event) {
        var self = this;
        if (this.state.email_phone === "" || this.state.email_phone == null) {
            this.setState({ errorMessage: "Enter a valid email address" });
        } else {
            axios({
                method: "post",
                url: "/user/forgot-password",
                data: {
                    emailId: self.state.email_phone,
                },
            }).then((data) => {
                let resp = data.data;
                if (data.status === 200) {
                    if (!resp.status) {
                        this.setState({ errorMessage: resp.message });
                        return;
                    } else {
                        this.setState(
                            {
                                successMessage:
                                    "Check your email for reset instructions",
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ successMessage: null });
                                }, 5000);
                            }
                        );
                    }
                } else {
                    this.setState({ errorMessage: "Invalid Email" });
                    return;
                }
            });
            //this.setState({ form_state: 'forgot_password' });
        }
    }

    onOTPRequest(event) {
        let self = this;
        // if (isNaN(self.state.email_phone)) {
        // 	this.setState({ errorMessage: 'Invalid mobile number' });
        // 	return;
        // }
        axios({
            method: "post",
            url: "/user/signin-OTP-request",
            data: {
                mobile_no: self.state.email_phone,
            },
        }).then((data) => {
            let resp = data.data;
            if (data.status === 200) {
                if (!resp.status) {
                    this.setState({ errorMessage: resp.message });
                    return;
                }

                this.setState({ form_state: "mobile_otp", errorMessage: "" });
            } else {
                this.setState({ errorMessage: "Invalid Mobile number" });
                return;
            }
        });
    }

    onEmailLogin(event) {
        let self = this;

        axios({
            method: "post",
            url: "/user/login",
            data: {
                username: self.state.email_phone,
                password: self.state.password,
            },
        }).then((data) => {
            let resp = data.data;
            if (data.status === 200) {
                if (!resp.status) {
                    this.setState({ errorMessage: resp.message });
                    return;
                }
                this.props.authenicateUser(
                    resp.data.accessToken,
                    resp.data.fullname,
                    resp.data
                );
                if (localStorage.getItem("redirectPath")) {
                    let redirectPath = localStorage.getItem("redirectPath");
                    localStorage.removeItem("redirectPath");
                    history.push(redirectPath);
                }
                //redirect user to his dashboard
                if (resp.data.role === "manager") {
                    if (resp.data.dropmenu) {
                        if (resp.data.dropmenu.length > 0) {
                            history.push(resp.data.dropmenu[0].target);
                        }
                    } else {
                        history.push("/user");
                    }
                }
                toast.success("Logged in", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                this.setState({ errorMessage: "Invalid Username / Password" });
                return;
            }
        });
    }

    onInputchange(event) {
        this.setState({
            errorMessage: "",
            [event.target.name]: event.target.value,
        });
    }

    render() {
        let errMessage = null;
        if (this.state.errorMessage) {
            errMessage = (
                <div className="alert alert-danger">
                    {this.state.errorMessage}
                </div>
            );
        }
        if (this.state.successMessage) {
            errMessage = (
                <div className="alert alert-success">
                    {this.state.successMessage}
                </div>
            );
        }

        let popup_content = (
            <div
                className="popup-form-container"
                tabIndex="0"
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        this.props.hideLoginWindow();
                    }
                }}
            >
                <div className="title">
                    <h5>Sign In</h5>
                </div>
                <div className="sign-in-form">
                    <div className="form-group">
                        <label for="exampleInputEmail1">
                            Email / Phone Number
                        </label>
                        <input
                            onKeyUp={(ev) => {
                                if (ev.nativeEvent.keyCode === 13) {
                                    this.onEmailLogin();
                                }
                            }}
                            onChange={this.onInputchange}
                            name="email_phone"
                            value={this.state.email_phone}
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email or Mobile"
                            autoFocus
                        />
                        <div style={{ padding: "5px", color: "#007bff" }}>
                            <i class="fas fa-info-circle"></i>&nbsp; Enter
                            mobile number for OTP login
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="password-section">
                            <label for="exampleInputEmail1">Password</label>
                            <input
                                onKeyUp={(ev) => {
                                    if (ev.nativeEvent.keyCode === 13) {
                                        this.onEmailLogin();
                                    }
                                }}
                                onChange={this.onInputchange}
                                name="password"
                                value={this.state.password}
                                type={
                                    this.state.showPassword
                                        ? "text"
                                        : "password"
                                }
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter password"
                            />
                            <i
                                className={
                                    "toggle-button fas fa-" +
                                    (this.state.showPassword
                                        ? "eye-slash"
                                        : "eye")
                                }
                                onClick={() => {
                                    this.setState({
                                        showPassword: !this.state.showPassword,
                                    });
                                }}
                            />
                        </div>
                        <small
                            id="emailHelp"
                            className="form-text text-muted"
                            onClick={this.onForgotPassword}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            Forgot password?
                        </small>
                    </div>
                    {(() => {
                        if (this.props.noSocialUser)
                            return (
                                <div className="alert alert-danger">
                                    User doesnt exist, signup with your email
                                </div>
                            );
                    })()}
                    {errMessage}
                    <div className="cta_buttons">
                        <button
                            className="form-control signinbutton"
                            onClick={this.onEmailLogin}
                        >
                            SIGN IN
                        </button>
                        <button
                            className="form-control otpButton"
                            onClick={this.onOTPRequest}
                        >
                            REQUEST OTP
                        </button>
                    </div>
                </div>
                <div className="orsignin">or</div>
                <div className="orsigninsocial-wrap">
                    <div
                        className="orsigninsocial"
                        style={{ justifyContent: "center" }}
                    >
                        <a
                            href={`${axios.defaults.baseURL}/user/social-login/google`}
                        >
                            <img
                                src="/images/google_login_button.png"
                                alt="google-btn"
                            />
                        </a>
                        {/* <a href="">
						<img src="/images/fb_login.png" />
					</a>
					<a href="">
						<img src="/images/linkedin.png" />
					</a> */}
                    </div>
                </div>

                <div className="signup-hint">
                    Dont have an account?{" "}
                    <span onClick={this.props.showRegister}>SIGN UP</span>
                </div>
            </div>
        );

        switch (this.state.form_state) {
            case "mobile_otp":
                popup_content = (
                    <MobileOTPScreen
                        mode="login"
                        mobile_no={this.state.email_phone}
                    />
                );
                break;
            case "forgot_password":
                popup_content = (
                    <MobileOTPScreen
                        mode="forgot_password"
                        email={this.state.email_phone}
                    />
                );
                break;
        }

        let content = (
            <div
                className="popUpBox fadeIn"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="left">
                    <img src="/images/logo_v.png" alt="wishwa logo" />
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                    <div className="circle3"></div>
                </div>
                <div className="right">
                    {popup_content}
                    <div className="signinfooter">
                        By continuing you agree to our{" "}
                        <a href="/pages/terms-and-conditions" target="_blank">
                            Terms of Service
                        </a>{" "}
                        &amp;{" "}
                        <a href="/pages/privacy-policy" target="_blank">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        );
        return (
            <div className="overlay" onClick={this.props.hideLoginWindow}>
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        noSocialUser: state.root.noSocialUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authenicateUser: (token, name = "", user = {}) =>
            dispatch({
                type: actions.AUTHENTICATED,
                accessToken: token,
                username: name,
                user: user,
            }),
        hideLoginWindow: () => dispatch({ type: actions.HIDELOGIN }),
        showRegister: () => dispatch({ type: actions.SHOWREGISTER }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInWindow);

import React, { Component, createRef } from "react";
import "./pickUpRequestDetails.css";
import HeadingCompnonet from "./HeadingComponent";
import Form from "react-bootstrap/Form";
import {
    BsArea,
    BsInput,
    FileUploader,
    SelectComponent,
} from "../../../components/Form/FormComponents";
import history from "../../../history";
import { toast } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import Validator from "validatorjs";
import moment from "moment";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
import LocationInput from "../../../components/Generic/LocationInput";
import { showPopup } from "../../../helpers/dispatcher";
const MaterialService = require("../../../services/MaterialService");
class DropOffRequestsDetails extends Component {
    constructor(props) {
        super(props);
        this.updateRef = React.createRef();
        this.state = {
            loader: false,
            update: false,
            loader: false,
            popupStatus: [],
        };
    }
    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
    };
    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    };
    update = async () => {
        let submit = await this.updateRef.current.updateData();
        await MaterialService.postData(
            `/v2/manager/donate-material/assign-volunteer`,
            submit
        ).then((res) => {
            if (res.status) {
                this.fetchData();
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    componentWillMount = () => {
        this.fetchData();
    };
    updateData = async () => {
        await MaterialService.postData(
            `/v2/manager/donate-material/update-donation`,
            {
                id: this.props.match.params.id,
                materials: this.state.details.materials.map((item) => {
                    return {
                        ...item,
                        category: item.category.id,
                    };
                }),
            }
        ).then((res) => {
            if (res.status) {
                toast.success("Saved the quantity change", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    save = async () => {
        let submit = await this.updateRef.current.updateData();
        await MaterialService.postData(
            `/v2/manager/donate-material/complete-donation`,
            submit
        ).then((res) => {
            if (res.status) {
                history.push(`/material-manager/donation-requests-list`);
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    fetchData = async () => {
        await MaterialService.fetchData(
            `/v2/manager/donate-material/get/${this.props.match.params.id}`
        ).then((res) => {
            if (res.status) {
                this.setState({
                    details: res.data,
                    loader: true,
                });
                console.log("res", this.state.details);
            } else {
                console.log("reseror", res);
            }
        });
    };

    render() {
        if (!this.state.loader) {
            return <LoaderSpinner />;
        }
        return (
            <div id="pickUpRequests" className="main-container">
                <HeadingCompnonet
                    mainTitle={
                        (this.state.details.miscInfo.type = 1
                            ? "Drop off Request details"
                            : "Pick up Request details")
                    }
                    backPath={null}
                />
                <div className="mian-pick-upcontainer  margin-bottom">
                    <div className="mian-heading">Donor details</div>
                    <div className="details-container margin-bottom">
                        <div className="details-text">Donor name</div>
                        {this.state.details.miscInfo.name}
                    </div>
                    <div className="details-container  margin-bottom">
                        <div className="details-text">Email</div>
                        <div className="details-Name">
                            {this.state.details.miscInfo.email}
                        </div>
                    </div>
                    <div className="details-container margin-bottom">
                        <div className="details-text">Mobile</div>
                        <div className="details-Name">
                            {this.state.details.miscInfo.mobile_no}
                        </div>
                    </div>
                    <div className="details-container margin-bottom">
                        <div className="details-text">Category & Quantity</div>
                        <div className="details-Name">
                            {this.state.details.materials.map((data, idx) => {
                                return (
                                    <div className="details-name-container">
                                        <div className="details-name-container-material-name">
                                            {data.category.name}
                                        </div>
                                        <div className="details-name-container-material-counts">
                                            {data.quantity}
                                        </div>
                                        <div
                                            className="banner-edit-button"
                                            onClick={() => {
                                                showPopup({
                                                    title: "Edit Quantity",
                                                    content: (
                                                        <QuantityEditor
                                                            onClose={(
                                                                newValue
                                                            ) => {
                                                                let materialsNew =
                                                                    this.state
                                                                        .details
                                                                        .materials;
                                                                materialsNew[
                                                                    idx
                                                                ].quantity =
                                                                    newValue;

                                                                this.setState(
                                                                    {
                                                                        details:
                                                                            {
                                                                                ...this
                                                                                    .state
                                                                                    .details,
                                                                                materials:
                                                                                    materialsNew,
                                                                            },
                                                                    },
                                                                    () => {
                                                                        this.updateData();
                                                                    }
                                                                );
                                                            }}
                                                            quantity={
                                                                data.quantity
                                                            }
                                                        />
                                                    ),
                                                });
                                            }}
                                        >
                                            <i class="fas fa-pencil-alt"></i>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="details-container margin-bottom">
                        <div className="details-text">Requested date</div>
                        <div className="details-Name">
                            {moment(this.state.details.createdAt).format(
                                "DD MMM YYYY"
                            )}
                        </div>
                    </div>
                    <div className="details-container margin-bottom">
                        <div className="details-text">Pick up location</div>
                        <div className="details-Name">
                            {this.state.details.locality.locality || " "}
                            {this.state.details.locality.city}&nbsp;,
                            {this.state.details.locality.state}
                            <div>
                                Copy Location text :
                                <CopyToClipboardButton
                                    textToCopy={`${
                                        this.state.details.locality.locality ||
                                        " "
                                    }, ${this.state.details.locality.city},
                            ${this.state.details.locality.state}`}
                                />
                            </div>
                            <div>
                                Copy Location Google Url :
                                <CopyToClipboardButton
                                    textToCopy={createGoogleMapsUrl(
                                        this.state.details.locality.latlong
                                            .location.lat,
                                        this.state.details.locality.latlong
                                            .location.lng
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.details.volunteer ? (
                        <>
                            <div className="details-container margin-bottom">
                                <div className="details-text">
                                    Volunteer Name
                                </div>
                                <div className="details-Name">
                                    {this.state.details.volunteer.name}
                                </div>
                            </div>
                            <div className="details-container margin-bottom">
                                <div className="details-text">
                                    Assigned Date
                                </div>
                                <div className="details-Name">
                                    {moment(
                                        this.state.details.volunteer
                                            .assignedDate
                                    ).format("DD MMM YYYY")}
                                </div>
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    <div className="details-container">
                        <div className="details-text">
                            Donor Uploaded images
                        </div>
                    </div>
                    <div className="donar-images-container">
                        {this.state.details.materialImages.map((images) => {
                            return (
                                <img
                                    src={images.storageURL}
                                    className="donar-images"
                                />
                            );
                        })}
                    </div>
                    <>
                        {this.state.details.volunteer ? (
                            <UpdatedComponent
                                ref={this.updateRef}
                                id={this.props.match.params.id}
                            />
                        ) : (
                            <UpdateComponent
                                ref={this.updateRef}
                                id={this.props.match.params.id}
                                volId={this.state.details.id}
                            />
                        )}
                    </>
                    <>
                        {!this.state.details.volunteer ? (
                            <div className="banners-buttons">
                                <div
                                    className="save-button"
                                    onClick={() => {
                                        this.update();
                                    }}
                                >
                                    Update
                                </div>
                                <div
                                    className="cancel-button"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    Cancel
                                </div>
                            </div>
                        ) : (
                            <div className="banners-buttons">
                                <div
                                    className="save-button"
                                    onClick={() => {
                                        this.save();
                                    }}
                                >
                                    Edit & save
                                </div>
                                <div
                                    className="cancel-button"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    Cancel
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </div>
        );
    }
}

export default DropOffRequestsDetails;

class QuantityEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: props.quantity,
        };

        //onClose
    }
    render() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <BsInput
                    required
                    label="Quantity"
                    placeholder="Enter quantity"
                    name="quantity"
                    onChange={(ev) => {
                        this.setState({ quantity: ev.target.value });
                    }}
                    value={this.state.quantity}
                />
                <i
                    className="fa fa-save"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => {
                        this.props.onClose(this.state.quantity);
                    }}
                ></i>
            </div>
        );
    }
}

class UpdateComponent extends Component {
    constructor(props) {
        super(props);
    }
    state = { volunteerList: [] };
    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
    };
    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    };
    updateData = async () => {
        await this.setState({ errors: {} });
        const rules = {
            selectVolunteer: "required",
            status: "required",
            volunteerNote: "required",
        };
        const message = {
            "required.status": "Select status",
            "required.selectVolunteer": "Select volunteer",
            "required.volunteerNote": "Enter something to volunteer",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        if (validator.passes()) {
            let submit = {
                id: this.props.id,
                status: this.state.status,
                volId: this.state.selectVolunteer,
                note: this.state.volunteerNote,
            };
            return submit;
        }
        this.setState({ errorMessage: " ", errors: null });
    };
    componentWillMount = () => {
        this.volunteerLists();
    };
    volunteerLists = async () => {
        await MaterialService.fetchData(
            `/v2/manager/volunteer/active-list`
        ).then((res) => {
            if (res.status) {
                this.setState({
                    volunteerList: res.data,
                    id: res.data.id,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    render() {
        return (
            <div>
                <div className="details-container align-center">
                    <div className="details-text">Assign volunteer</div>
                    <div className="col-md-4 padding">
                        <SelectComponent
                            name="selectVolunteer"
                            errors={this.state.errors}
                            onChange={this.onInputChange}
                            value={this.state.selectVolunteer}
                            className="col-md-4 padding"
                        >
                            <option value={""}>Select volunteer</option>
                            {this.state.volunteerList.map((name) => {
                                console.log(name);
                                return (
                                    <option value={name.id}>
                                        {name.fullName}
                                    </option>
                                );
                            })}
                        </SelectComponent>
                    </div>
                </div>
                <div className="details-container align-center">
                    <div className="details-text">Status</div>
                    <div className="col-md-4 padding details-Name">
                        <SelectComponent
                            name="status"
                            errors={this.state.errors}
                            onChange={this.onInputChange}
                            value={this.state.status}
                        >
                            <option value="0">Requested</option>
                            <option value="1">Pickup scheduled</option>
                            <option value="2">Pickup Complete</option>
                            <option value="3">Received by Wishwa</option>
                            <option value="4">Received by NGO</option>
                            <option value="6">Donor Not Available</option>
                            <option value="7">No Service</option>
                            <option value="5">Closed</option>
                        </SelectComponent>
                    </div>
                </div>
                <div className="col-md-12 padding">
                    <BsArea
                        required
                        label="Note to the Volunteer"
                        label2="Maximum 200 Characters"
                        value={this.state.volunteerNote}
                        className="col-md-7 padding"
                        name="volunteerNote"
                        onChange={this.onInputChange}
                        errors={this.state.errors}
                        rows="5"
                        maxLength={200}
                    />
                </div>
            </div>
        );
    }
}

class UpdatedComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: [{}, {}, {}],
            eventType: "",
            pickUpTime: new Date(),
            dropOffTime: new Date(),
            mediasList: [],
        };
    }

    componentWillMount = () => {
        this.timeList();
    };
    onfileChange = (event) => {
        let media = this.state.mediasList || [];
        let files = event.target.files;

        if ([...media, ...files].length < 7) {
            for (let i = 0; i < event.target.files.length; i++) {
                media.push(event.target.files[i]);
            }

            this.setState({ mediasList: media });
        } else {
            toast.error("Something Went Wrong", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log("events", value);
        if (event.target.type === "file") {
            this.setState({ [name]: event.target.files[0] });
        } else {
            this.setState({ [name]: value });
        }
    };

    onDateChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };
    deleteImage = (idx) => {
        let medias = this.state.mediasList;
        medias.splice(idx, 1);
        this.setState({ mediasList: medias });
    };
    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
        return null;
    };
    updateData = async () => {
        await this.setState({ errors: {} });
        const rules = {
            pickUpTime: "required",
            dropOffLocation: "required",
            donationStatus: "required",

            dropOffTime: "required",
        };
        if (this.state.mediasList.length == 0) {
            rules["media"] = "required";
        }
        const message = {
            "required.pickUpTime": "Select pickup time",
            "required.dropOffLocation": "select drop off location",
            "required.dropOffTime": "Select dropOff time",
            "required.donationStatus": "Select donation status ",
            "required.media": "Select images  ",
        };
        let validation = new Validator(this.state, rules, message);
        if (validation.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validation.errors.all(),
                },
            });
            return null;
        }

        await this.uploadMedia();
        let submit = {
            pickup_date: moment(this.state.pickUpTime).unix(),
            drop_location: this.state.dropOffLocation,
            donation_status: this.state.donationStatus,
            images: this.state.mediasList.map((ids) => {
                return ids.mediaId;
            }),
            drop_time: moment(this.state.dropOffTime).unix(),
            id: this.props.id,
        };
        if (validation.passes()) {
            return submit;
        }
    };
    timeList = async () => {
        let idx = 0;
        let initialTime = moment().startOf("day");
        let timeArray = [initialTime.format("h:mm A")];

        for (let i = 1; i < 48; i++) {
            timeArray.push(initialTime.add(30, "m").format("h:mm A"));
            if (timeArray[idx] == "11:00 pm") {
                break;
            }
        }
        await this.setState({ timeList: timeArray, eventType: "1" });
    };
    uploadMedia = async () => {
        const file = this.state.mediasList.map((media) => {
            return {
                name: media.name,
                type: media.type,
                size: media.size,
            };
        });
        let uploadUrls = await MaterialService.postData("/v2/get-upload-urls", {
            files: file,
        });

        if (uploadUrls.status) {
            let media = this.state.mediasList;
            for (let i = 0; i < media.length; i++) {
                let uploadUrl = uploadUrls.data.find((item) => {
                    return media[i].name == item.file.name;
                });

                let uploadImage = uploadUrl.mediaObject.data;

                if (uploadUrl) {
                    media[i].mediaId = uploadUrl.mediaObject.data.id;
                    var xhr = new XMLHttpRequest();
                    xhr.open("PUT", uploadUrl.s3Url.data.postUrl, true);
                    xhr.setRequestHeader("Content-Type", uploadImage.type);
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            return uploadUrl.s3Url.data.getUrl;
                        } else {
                        }
                    };
                    xhr.upload.onprogress = (ev) => {
                        let progressArr = this.state.progress || [];
                        let uploadProgress = (ev.loaded / ev.total) * 100;
                        progressArr[i] = {
                            name: media[i].name,
                            progress: uploadProgress,
                        };
                        if (
                            progressArr.every((item) => {
                                return item.progress >= 100;
                            })
                        ) {
                            this.setState({ uploadFiles: false });
                        }
                        this.setState({ progress: progressArr });
                    };
                    xhr.send(media[i]);
                }
            }
        }
    };
    render() {
        return (
            <div className="Updated-container">
                {/* <div className="col-md-12 padding row margin-bottom">
                    <div className="col-md-2  padding lists-text-heading">
                        Date & Time
                    </div>
                    <div className="col-md-2  padding lists-text-heading">
                        From
                    </div>
                    <div className="col-md-8  padding lists-text-heading">
                        Comment
                    </div>
                </div>
                <div className="col-md-12 padding row">
                    {this.state.lists.map((data) => {
                        return (
                            <div className="col-md-12 padding row margin-bottom">
                                {" "}
                                <div className="col-md-2  padding lists-text">
                                    25Dec2022<br></br> 12 : 30 PM
                                </div>
                                <div className="col-md-2  padding lists-text">
                                    Wishwa
                                </div>
                                <div className="col-md-8  padding lists-text">
                                    Collect the materials from the pick up/drop
                                    off location
                                </div>
                            </div>
                        );
                    })}
                </div> */}
                <div className="mian-heading">
                    Volunteer updated below details
                </div>
                <div className="details-container align-center">
                    <div className="details-text capitalise">
                        Pickup date and time
                    </div>
                    <DateTimePicker
                        onChange={(value) => {
                            // console.log(moment(value).unix());
                            this.onDateChange("pickUpTime", value);
                        }}
                        value={this.state.pickUpTime}
                    />
                </div>
                <div className="details-container margin-bottom align-center margin-top">
                    <div className="details-text capitalise">
                        Drop off location
                    </div>
                    <div className="col-md-6 padding">
                        <LocationInput
                            defaultValue={this.state.dropOffLocation || {}}
                            type={["geocode"]}
                            onLocationSelected={(dropOffLocation) => {
                                this.setState({
                                    dropOffLocation: dropOffLocation,
                                });
                            }}
                            name="dropOffLocation"
                            errors={this.state.errors}
                            className="col-md-12 padding"
                            onChange={this.onInputChange}
                        />
                        {this.getErrorMessage("locality")}
                    </div>
                </div>
                <div className="details-container align-center">
                    <div className="details-text capitalise">
                        drop off date and time
                    </div>
                    <DateTimePicker
                        onChange={(value) => {
                            // console.log(moment(value).unix());
                            this.onDateChange("dropOffTime", value);
                        }}
                        value={this.state.dropOffTime}
                    />
                </div>
                <div className="details-container align-center">
                    <div className="details-text capitalise">
                        Donation Status
                    </div>
                    <div className="col-md-4 padding details-Name">
                        <SelectComponent
                            name="donationStatus"
                            errors={this.state.errors}
                            onChange={this.onInputChange}
                            value={this.state.donationStatus}
                        >
                            <option value="0">Requested</option>
                            <option value="1">Pickup scheduled</option>
                            <option value="2">Pickup Complete</option>
                            <option value="3">Received by Wishwa</option>
                            <option value="4">Received by NGO</option>
                            <option value="6">Donor Not Available</option>
                            <option value="7">No Service</option>
                            <option value="5">Closed</option>
                        </SelectComponent>
                    </div>
                </div>
                {(() => {
                    if (this.state.mediasList.length > 0) {
                        return (
                            <div className="donar-images-container">
                                {this.state.mediasList.map((image, idx) => {
                                    return (
                                        <div className="image-item">
                                            <img
                                                className="donar-images-map"
                                                src={URL.createObjectURL(image)}
                                            />

                                            <i
                                                className="fas fa-times remove"
                                                onClick={() => {
                                                    this.deleteImage(idx);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                })()}
                <div className="donar-images-container">
                    <FileUploader
                        section_title=""
                        section_title2=""
                        value={this.state.mediasList[0]}
                        onChange={this.onfileChange}
                        name="media"
                        title="ADD IMAGE"
                        desc="MAX -6 images (Accepted file format - .jpg & .pdf. Max size 500KB)"
                        multiple={true}
                    />
                    {this.getErrorMessage("image")}
                </div>
            </div>
        );
    }
}

const CopyToClipboardButton = ({ textToCopy }) => {
    let color = "#000";
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            color = "green";
            console.log("Text copied to clipboard");
            // You might want to show some user feedback here as well.
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    return (
        <i
            class="fa fa-copy"
            style={{ color: color, display: "inline", cursor: "pointer" }}
            aria-hidden="true"
            onClick={handleCopy}
        ></i>
    );
};

function createGoogleMapsUrl(latitude, longitude) {
    return `https://www.google.com/maps?q=${latitude},${longitude}`;
}

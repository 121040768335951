import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import history from '../../../history';
import './header.css';
import { Link } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "../../Generic/Popups/Popup";
import { closePopup } from "../../../helpers/dispatcher";
import ModalWindow from '../../Generic/Popups/Modal';
import Alert from '../../Generic/Alert';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { showLogout: false };
	}
	componentDidUpdate() {
		if (!this.props.authenticated) {
			this.props.showLoginWindow();
			history.push('/');
		}
	}
	showModal() {
		if (this.props.showModal) {
			return <ModalWindow>{this.props.showModal.content || null}</ModalWindow>;
		}
		return null;
	}
	showPopup() {
		if (this.props.showPopup) {
			return (
				<Popup
					showWindow={true}
					showCloseButton={false}
					title={this.props.showPopup.title || 'Alert'}
					buttonContainerClass="right"
					buttons={
						this.props.showPopup.buttons || [
							{
								class: 'btn btn-primary-inverse',
								text: 'Close',
								onClick: (ev) => {
									if (this.props.showPopup.onClose) {
										this.props.showPopup.onClose();
									}
									closePopup();
								},
							},
						]
					}
				>
					{this.props.showPopup.content || null}
				</Popup>
			);
		}
	}
	showAlert() {
		if (this.props.showAlert) {
			return (
				<Alert
					title={this.props.showAlert.title || 'Title'}
					type={this.props.showAlert.type || ''}
					description={this.props.showAlert.description}
					autoClose={this.props.showAlert.autoClose}
					onClick={this.props.showAlert.onClick ? this.props.showAlert.onClick : () => {}}
				/>
			);
		}
		return null;
	}

	render() {
		let signin_block = (
			<p id="login_anchor" onClick={this.props.showLoginWindow}>
				SIGN IN / UP
			</p>
		);
		if (this.props.authenticated) {
			signin_block = (
				<p id="login_anchor" onClick={this.props.doLogout}>
					Logout
				</p>
			);
		}
		let homeurl = '/';
		let dropmenu = localStorage.getItem('dropmenu');
		if (dropmenu) {
			dropmenu = JSON.parse(dropmenu);
			if (dropmenu && dropmenu.length > 0) {
				homeurl = dropmenu[0].target;
			}
		} else {
			dropmenu = [];
		}

		let logoutButton = (
			<div
				className="fadeIn user-dropdown"
				onPointerLeave={() => {
					this.setState({ showLogout: false });
				}}
			>
				<ul>
					{dropmenu.map((item, idx) => {
						return (
							<li key={`menuItem_${idx}`}>
								<Link to={item.target}>{item.name}</Link>
							</li>
						);
					})}
					<li onClick={this.props.doLogout}>
						<a href="#">Logout</a>
					</li>
				</ul>
				<div className="border-bot"></div>
			</div>
		);
		return (
            <header id="backend-header">
                {this.showPopup()}
                {this.showModal()}
                {this.showAlert()}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                <div className="container">
                    <div className="row flex-horizontal" id="header-section">
                        <div id="logo">
                            <Link to={homeurl}>
                                <img
                                    src={`/images/${
                                        process.env.REACT_APP_ENV === "testing"
                                            ? "wishwa-logo-grey.png"
                                            : "wishwa-logo.png"
                                    }`}
                                    alt=""
                                    className="img img-fluid"
                                />
                            </Link>
                        </div>
                        <div
                            className="profile-block"
                            style={{ position: "relative" }}
                            onClick={() => {
                                this.setState({
                                    showLogout: !this.state.showLogout,
                                });
                            }}
                        >
                            {this.state.showLogout ? logoutButton : null}
                            <img
                                src="/images/profile-ph.png"
                                alt="profile-image"
                            />
                            <span>{localStorage.getItem("username")}</span>
                            <i className="fas fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
            </header>
        );
	}
}
const mapStateToProps = (state) => {
	return {
		authenticated: state.root.authenticated,
		showLogin: state.root.showLogin,
		showRegister: state.root.showRegister,
		showPopup: state.root.showPopup,
		showModal: state.root.showModal,
		showAlert: state.root.showAlert,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		doAuthenticate: () => dispatch({ type: actions.AUTHENTICATED }),
		doLogout: () => dispatch({ type: actions.LOGOUT }),
		showLoginWindow: () => dispatch({ type: actions.SHOWLOGIN }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React, { Suspense } from "react";
import { Footer } from "../components";
import Header from "../components/Header/Header";
import BackendHeader from "../components/Backend/Header/Header";

const UserLayout = ({ hideMenu, children }) => {
    return (
        <Suspense
            fallback={() => {
                <div></div>;
            }}
        >
            <div>
                {hideMenu ? <BackendHeader /> : <Header />}
                <div>{children}</div>
                <Footer />
            </div>
        </Suspense>
    );
};

export default UserLayout;

import React, { useEffect, useState } from "react";

import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
    <div style={{ color: "darkgreen" }}>
        <i
            className="fas fa-map-marker"
            style={{
                color: "linear-gradient(90deg, #f19400 2.95%, #e5643e 49.24%, #da3976 97.5%)",
            }}
        ></i>
        {text}
    </div>
);

export default function SimpleMap(props) {
    const { lat, lng } = props;
    const startData = {
        center: {
            lat: lat || 10.99835602,
            lng: lng || 77.01502627,
        },
        zoom: 18,
    };
    const [val, setVal] = useState(startData);
    const [marker, setMarker] = useState(null);

    useEffect(() => {
        console.log("ppppp", lat, lng);
        setVal({
            ...val,
            center: {
                lat: lat,
                lng: lng,
            },
        });
    }, [lat, lng]);

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
                key={`${val.center.lng}${val.center.lat}`}
                bootstrapURLKeys={{
                    key: "AIzaSyAMlAmqdISKf-dDjD1WHMjNRdzzPwxKcS4",
                }}
                defaultCenter={val.center}
                defaultZoom={val.zoom}
                onClick={({ x, y, lat, lng, event }) => {
                    props.onClickChange(lat, lng);
                    setMarker({ lat: lat, lng: lng });
                }}
            >
                {marker ? (
                    <AnyReactComponent
                        lat={marker.lat}
                        lng={marker.lng}
                        text="My Location"
                    />
                ) : null}
            </GoogleMapReact>
        </div>
    );
}
